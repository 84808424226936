import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { initialPortfolioHistoryState } from './helpers';
import { RequestStatus } from '#/types/enums';
import { PortfolioHistoryItem, PortfolioHistoryState } from './types';

const portfolioHistorySlice = createSlice({
  name: 'portfolioHistory',
  initialState: initialPortfolioHistoryState,
  reducers: {
    updatePortfolio24h(state, action: PayloadAction<Array<PortfolioHistoryItem>>) {
      state.portfolio24h = action.payload;
      state.portfolio24hRequestStatus = RequestStatus.Success;
    },
    updatePortfolio24RequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.portfolio24hRequestStatus = action.payload;
    },
    updatePortfolioHistory(state, action: PayloadAction<Array<PortfolioHistoryItem>>) {
      state.portfolioHistory = action.payload;
      state.portfolioHistoryRequestStatus = RequestStatus.Success;
    },
    updatePortfolioHistoryRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.portfolioHistoryRequestStatus = action.payload;
    },
  },
});

export const {
  updatePortfolioHistory,
  updatePortfolioHistoryRequestStatus,
  updatePortfolio24h,
  updatePortfolio24RequestStatus,
} = portfolioHistorySlice.actions;

export default portfolioHistorySlice.reducer;

const selectPortfolioHistoryState = (state: Store): PortfolioHistoryState => state.home.portfolioHistory;

export const selectPortfolioHistory: (state: Store) => Array<PortfolioHistoryItem> = createDraftSafeSelector(
  selectPortfolioHistoryState,
  (portfolioState: PortfolioHistoryState) => portfolioState.portfolioHistory || [],
);

export const selectPortfolio24h: (state: Store) => Array<PortfolioHistoryItem> = createDraftSafeSelector(
  selectPortfolioHistoryState,
  (portfolioState: PortfolioHistoryState) => portfolioState.portfolio24h || [],
);

export const selectPortfolioHistoryRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPortfolioHistoryState,
  (portfolioState: PortfolioHistoryState) => portfolioState.portfolioHistoryRequestStatus,
);

export const selectPortfolio24RequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPortfolioHistoryState,
  (portfolioState: PortfolioHistoryState) => portfolioState.portfolio24hRequestStatus,
);

export const selectPortfolioChangePercentage: (state: Store) => number = createDraftSafeSelector(
  selectPortfolio24h,
  (portfolioArray) => {
    if (!portfolioArray.length) {
      return 0;
    }
    
    const last = portfolioArray[portfolioArray.length - 1].balanceInBaseCurrency;
    const first = portfolioArray[0].balanceInBaseCurrency;

    const portfolioChange = ((last - first) / first) * 100;
    
    return isNaN(portfolioChange) ? 0 : portfolioChange;
  }
);

export enum BankIdType {
  Kyc = 'kyc',
  Withdraw = 'withdraw',
  Deposit = 'deposit',
}

export enum BankIdSet {
  IsBankId = 'is_bank_id',
  Timer = 'bank_id_timer',
  Method = 'bank_id_method',
  Product = 'bank_id_product',
  Tab_kyc = 'bank_id_tab_kyc',
  Tab_withdraw = 'bank_id_tab_withdraw',
  PaymentRoute = 'bank_id_payment_route',
}

export enum BankIdStatuses {
  Success ='success',
  Reject ='reject',
}

export enum SearchParams {
  Type = 'type',
  UserId = 'client_user_id',
  Domain = 'domain',
  SessionId = 'sessionId',
  InitialTheme = 'initial_theme',
  Language = 'language',
}

export enum WithdrawInfo {
  WithdrawSuccess= 'withdrawSuccess',
  WithdrawReject = 'withdrawReject',
}

export const keyPrevAuthType = 'prevAuthType';

export enum AuthOptions {
  Vako = 'QA Engineer Login',
  Cognito = 'Cognito',
}

export interface AuthConfig {
  type: AuthOptions | null,
  cognitoAuth: boolean,
  vako: boolean,
}

export const debugCognitoActive = true;

export const config: AuthConfig = {
  type: localStorage.getItem(keyPrevAuthType) as AuthOptions || AuthOptions.Cognito, // Chosen option
  cognitoAuth: true,
  vako: false,
}

export default config;

import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import {
  initialKycState,
  IKycState,
  VerificationModalType,
  IKycInfoFields,
} from './helpers';
import { KycProviders, KycProvidersEnabled, KycProvidersSettings, KycWidgetsUrl, UserAccountTypes, UserKycStatus } from './types';
import { IKycProvidersGetResponse, selectUserState, User, UserKycDoc } from '#reducers/user/user';
import { RequestStatus } from '#/types/enums';

const kycSlice = createSlice({
  name: 'kyc',
  initialState: initialKycState,
  reducers: {
    updateKycData(state, action: PayloadAction<IKycInfoFields>) {
      state.kycData = action.payload;
    },
    updateKycDoc(state, action: PayloadAction<UserKycDoc>) {
      state.kycDoc = action.payload;
    },
    updateAccountType(state, action: PayloadAction<UserAccountTypes | string>) {
      state.accountType = action.payload;
    },
    updateKycProviders(state, action: PayloadAction<KycProvidersSettings>) {
      state.kycProviders = action.payload;
    },
    updateKycEnabled(state, action: PayloadAction<KycProvidersEnabled>) {
      state.kycProvidersEnabled = action.payload;
    },
    updateKycWidgets(state, action: PayloadAction<KycWidgetsUrl>) {
      state.kycWidgets = action.payload;
    },
    updateKycWidgetStatus(state, action: PayloadAction<RequestStatus>) {
      state.statusKycPreference = action.payload;
    },
    updateKycSessionId(state, action: PayloadAction<string>) {
      state.kycSessionId = action.payload;
    },
    updateManualKycRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.submitManualKycRequest = action.payload;
    },
    updateVerificationModalType(state, action: PayloadAction<VerificationModalType>) {
      state.verificationModalType = action.payload;
    },
    updateIsSeveralOptionVerify(state, action: PayloadAction<boolean>) {
      state.isSeveralOptionVerify = action.payload;
    },
  },
});

export const {
  updateKycData,
  updateKycDoc,
  updateAccountType,
  updateKycProviders,
  updateKycEnabled,
  updateKycWidgets,
  updateKycSessionId,
  updateVerificationModalType,
  updateManualKycRequestStatus,
  updateKycWidgetStatus,
  updateIsSeveralOptionVerify,
} = kycSlice.actions;

export default kycSlice.reducer;

const selectKycState = (state: Store): IKycState => state.kyc;

export const selectKycData: (state: Store) => IKycInfoFields | null = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycData,
);

export const selectKycDocData: (state: Store) => UserKycDoc | null = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycDoc,
);

export const selectKycProviders: (state: Store) => KycProvidersSettings | null = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycProviders,
);

export const selectStatusKycProvider: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.statusKycPreference,
);

export const selectAccountType: (state: Store) => UserAccountTypes | string = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.accountType,
);

export const selectKycProviderWidgetsUrl: (state: Store) => KycWidgetsUrl = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycWidgets,
);

export const selectKycProvidersEnabled: (state: Store) => KycProvidersEnabled | null = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycProvidersEnabled,
);

export const selectKycSession: (state: Store) => string = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.kycSessionId,
);

export const selectManualKycRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.submitManualKycRequest,
);

export const selectVerificationModalType: (state: Store) => VerificationModalType = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.verificationModalType,
);

export const selectKycStatus: (state: Store) => UserKycStatus | null = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.userKycInfo.kyc_status,
);

export const selectKycStatusRequired: (state: Store) => boolean = createDraftSafeSelector(
  selectUserState,
  (userState: User) => [
    UserKycStatus.Incomplete,
    UserKycStatus.Rejected,
    null,
    UserKycStatus.NotStarted
  ].includes(userState.userInfo.userKycInfo.kyc_status),
);

export const selectKycLevel: (state: Store) => string = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.userKycInfo.kyc_level || '0',
);

export const selectKycMessage: (state: Store) => string = createDraftSafeSelector(
  selectUserState,
  (userState: User) => userState.userInfo.userKycInfo.kyc_message || '',
);

export const selectIsSeveralOptionVerify: (state: Store) => boolean = createDraftSafeSelector(
  selectKycState,
  (settingsState: IKycState) => settingsState.isSeveralOptionVerify,
);

import { BalancesState } from './types';
import { IsActive } from '#/types';
import { RequestStatus } from '#/types/enums';
import { CryptoFiat, IBalance, IBalanceExtended } from '../interfaces';
import { UserMarketCurrencies } from '#reducers/user/user';

export const initialStateBalances: BalancesState = {
  balances: {},
  balancesRequestStatus: RequestStatus.None,
}

export const getBalanceUsd = (balance: IBalance) => (balance?.free_balance_USD || balance?.free_balance_USDT) || 0;

export const formatBalance = (amount: number | string, decimals: number): string => {
  return Number(amount).toFixed(decimals);
}

export const formatBalanceWithCurrency = (amount: number | string, currency: UserMarketCurrencies, decimals: number): string => {
  return currency === UserMarketCurrencies.USD ? `$${formatBalance(amount, decimals)}` : `${formatBalance(amount, decimals)} ${currency}`;
}

export const getBalanceQuote = (balance: IBalance, marketCurrency: UserMarketCurrencies): number => {
  return marketCurrency === UserMarketCurrencies.USD
  ? getBalanceUsd(balance)
  : balance?.[`free_balance_${marketCurrency}`] || 0
}

export const prepareBalances = (array: Array<IBalance>, marketCurrency: UserMarketCurrencies): Array<IBalanceExtended> => {
  const inUsd = marketCurrency === UserMarketCurrencies.USD;
  const defaultMarketPrecision = inUsd ? 2 : 5;
  const marketPrecision = array.find((_) => _.currency_id === marketCurrency)?.currency?.precision || undefined;
  const allValue = array.reduce((acc, _) => acc += getBalanceQuote(_, marketCurrency) || 0, 0);

  return array.map((_: IBalance) => {
    const balanceInQuote = getBalanceQuote(_, marketCurrency);
    const priceInQuote = !!Number(_.free_balance) ? balanceInQuote / _.free_balance : 0;
    const balanceInQuoteTotal = balanceInQuote + priceInQuote * _.exposed_balance || 0; // TODO maybe get from back

    if (_.currency?.is_active !== IsActive.On && _.total_balance === 0) { // TODO inactive ignored with zero balance
      return undefined as unknown as IBalanceExtended;
    }

    return {
      ..._,
      isActive: _.currency?.is_active === IsActive.On,
      balanceInQuote,
      priceInQuote,
      priceInUsd: getBalanceUsd(_) / _.free_balance,
      balanceInQuoteDecimals: marketPrecision || defaultMarketPrecision,
      balanceInQuoteTotal,
      isFiat: _.currency.type === CryptoFiat.Fiat,
      percentage: !!Number(balanceInQuoteTotal) && allValue ? (balanceInQuoteTotal / allValue) * 100 : 0,
    };
  }).filter((_) => !!_).sort((a, b) => b.balanceInQuote - a.balanceInQuote);
}

export const prepareBalanceDefault = (balance: IBalanceExtended) => {
  return {
    free_balance: balance?.free_balance || 0,
    exposed_balance: balance?.exposed_balance || 0,
    total_balance: balance?.total_balance || 0,
    percentage: balance?.percentage || 0,
    free_balance_BTC: balance?.free_balance_BTC || 0,
    free_balance_ETH: balance?.free_balance_ETH || 0,
    free_balance_USD: balance?.free_balance_USD || balance?.free_balance_USDT || 0,
    free_balance_USDT: balance?.free_balance_USDT || 0,
    balanceInQuote: balance?.balanceInQuote || 0,
    balanceInQuoteTotal: balance?.balanceInQuoteTotal || 0,
    balanceInQuoteDecimals: balance?.balanceInQuoteDecimals || 2,
    priceInQuote: balance?.priceInQuote || 0,
    priceInUsd: !!Number(balance?.priceInUsd) ? balance?.priceInUsd : 0,
    isFiat: balance?.isFiat || false,
    isActive: balance?.currency.is_active === IsActive.On,
    currency: balance?.currency || { precision: 5, type: CryptoFiat.Crypto, is_active: IsActive.Off, payment_routes: [] },
  }
}

import BigNumber from 'bignumber.js';
import { InstrumentPrice, ISelectedInstrument, VaultBalance, VaultProduct } from '#/types';
import configVaults from '#/config/vaults';

export const DEFAULT_PRODUCT = configVaults?.vaultCurrencyDefault || 'USD';
export const STABLE_COINS = ['USDT', 'USDC'];

const getMidPrice = (instrumentPrice: InstrumentPrice | number) => {
  if (!instrumentPrice) {
    return 0;
  }
  if (typeof instrumentPrice === 'number') {
    return instrumentPrice;
  }
  return instrumentPrice.ask && instrumentPrice.bid ? (instrumentPrice.ask + instrumentPrice.bid) / 2 : instrumentPrice.ask || instrumentPrice.bid;
}


const getPrice = (_price: ISelectedInstrument | number) => _price
  ? typeof _price === 'number' ? _price : _price?.price
  : 0;

const accountCurrencyPrice = (prices: { [currencyName: string]: ISelectedInstrument | number }, base: string) => {
  return getPrice(prices[`${base}${DEFAULT_PRODUCT}`])
    || getPrice(prices[`${base}${STABLE_COINS[0]}`])
    || getPrice(prices[`${base}${STABLE_COINS[1]}`])
    || 0;
};

export const getPriceForProduct = (product: VaultProduct, prices: { [currencyName: string]: ISelectedInstrument | number }, accountCurrency?: string): number => {
  const instrumentPrice = accountCurrencyPrice(prices, product.assetId);

  if (accountCurrency === product.assetId) {
    return 0
  }

  if (accountCurrency && accountCurrency !== DEFAULT_PRODUCT) {
    if (getPrice(prices[`${product.assetId}${accountCurrency}`])) {
      return +getPrice(prices[`${product.assetId}${accountCurrency}`])
    }
    return prices ? getMidPrice(Number(instrumentPrice) / Number(accountCurrencyPrice(prices, accountCurrency))) : 0;
  }
 
  return prices ? getMidPrice(instrumentPrice) : 0;
};

export const getTicker = (
  instruments: { [asset: string]: ISelectedInstrument },
  base: string,
  quote: string = DEFAULT_PRODUCT,
) => {
  return instruments[`${base}${quote}`] || instruments[`${quote}${base}`];
}

export const countBalance = (
  instruments: { [asset: string]: ISelectedInstrument },
  balance: BigNumber = new BigNumber(0),
  base: string = '',
  quote: string = '',
): BigNumber => {
  const tickerBaseQuote = getTicker(instruments, base, quote);
  if (!BigNumber.isBigNumber(balance)) {
    balance = new BigNumber(balance);
  }
  if (base === quote) { // return current balance
    return balance;
  }
  if (!tickerBaseQuote) { // not found base/quote - count by USD
    const priceBaseUsd = getMidPrice(instruments[`${base}${DEFAULT_PRODUCT}`]?.price) || 1;
    const priceQuoteUsd = getMidPrice(instruments[`${quote}${DEFAULT_PRODUCT}`]?.price) || 1;

    return (balance.multipliedBy(new BigNumber(priceBaseUsd)))
      .dividedBy(new BigNumber(priceQuoteUsd));
  }

  return instruments[`${base}${quote}`]
    ? balance.multipliedBy(new BigNumber(getMidPrice(tickerBaseQuote.price)))
    : balance.dividedBy(new BigNumber(getMidPrice(tickerBaseQuote.price)));
};

/**
 * @param balances
 * @param tickers
 * @param quote
 */
export const countAllBalances = (
  balances: Array<VaultBalance>,
  instruments: { [asset: string]: ISelectedInstrument },
  quote: string = DEFAULT_PRODUCT,
): number => {
  const forBalanceIn = quote.toUpperCase();
  let totalBalance = new BigNumber(0);
  balances.forEach((balance: VaultBalance) => {
    totalBalance = totalBalance.plus(countBalance(instruments, balance.balance, balance.assetId, forBalanceIn));
  });

  return totalBalance.toNumber();
};

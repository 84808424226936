import BigNumber from 'bignumber.js';
import { VaultProduct, VaultBalance, VaultAccountClass, VaultAccountType } from '#/types';

export const makeDefaultBalance = (product: VaultProduct): VaultBalance => ({
  accountingClass: VaultAccountClass.Asset,
  accountType: VaultAccountType.Interest,
  assetId: product.assetId,
  balance: new BigNumber(0),
  createdAt: new Date(),
  description: product.description || '',
  id: '',
  productId: product.id,
  sequenceId: '',
  updatedAt: new Date(),
  userId: '', // TODO
  companyId: '', // TODO
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import { ISelectedInstrument, InstrumentPriceBar } from '../interfaces';
import { throttle, arrayToObject, prepareChartBars, handleErrorThunk } from '#/util'
import TradeService from '#/api/trade/TradeService';
import { PriceBarsPayload } from '#/api/trade/trade-qql';
import { Bar } from '#/util/chart/datafeed-api';
import {
  instrumentsBarsRequestStatus,
  instrumentsRequestStatus,
  updateInstruments,
  updateSelectedInstrument,
  updateSelectedInstrumentPriceBars,
} from './instruments';
import { RequestStatus, Periodicity } from '#/types/enums';
import { IsActive, InstrumentPrice } from '#/types';
import {
  createInstrumentPrice,
  instrumentIdKey,
  mergeObjectsByProperty,
} from './helpers';
import { batch } from 'react-redux';

const DEFAULT_PRICE_BAR: InstrumentPriceBar = {
  instrument_id: '',
  high: 0,
  low: 0,
  ts_iso: '',
  open: 0,
  close: 0,
};

export const getInstruments = createAsyncThunk(
  'trade/getInstruments',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    dispatch(instrumentsRequestStatus(RequestStatus.Pending));
    try {
      const { instruments }: { instruments: Array<ISelectedInstrument> } = await (extra.tradeService as TradeService).getInstruments();

      const availableInstruments = instruments.map((_) => ({
        ..._,
        price: createInstrumentPrice(_.recent_price_bar, _.instrument_id, _.price),
      })).filter((_) => _.is_active === IsActive.On);
      dispatch(updateInstruments(arrayToObject(availableInstruments, instrumentIdKey)));
    } catch (error) {
      dispatch(instrumentsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get instruments failed', dispatch);
    }
  }, 500),
) as unknown as () => AnyAction;

export const getInstrumentBaseQuotePriceBars = createAsyncThunk(
  'trade/getInstrumentBaseQuote',
  throttle(async (selectedInstrument: ISelectedInstrument, { dispatch, extra }: any) => {
    // TODO add request status if need
    try {
      if (!!selectedInstrument.instrument_id) {
        const { instruments }: { instruments: Array<ISelectedInstrument> } = await (extra.tradeService as TradeService).getInstrumentBaseQuotePriceBars(selectedInstrument.instrument_id);
        dispatch(updateSelectedInstrument({...selectedInstrument, ...instruments[0]}));
      }
    } catch (error) {
      handleErrorThunk(error, 'Get instrument data failed', dispatch);
    }
  }, 1000),
) as unknown as (selectedInstrument: ISelectedInstrument) => AnyAction;

// ---- all instruments 24h bars for mini charts
export const getInstrumentsPriceBars = createAsyncThunk(
  'trade/getInstrumentsPriceBars',
  throttle(async (instruments: ISelectedInstrument[], { dispatch, extra }: any) => {
    dispatch(instrumentsBarsRequestStatus(RequestStatus.Pending));

    try {
      //---- load bars and set instruments
      const bars = await (extra.tradeService as TradeService).getInstrumentsPriceBars();
      const preparedInstrumentsData = mergeObjectsByProperty(instruments, bars.instruments, instrumentIdKey);

      const availableInstrumentsWithBars = preparedInstrumentsData
        .filter(({ is_active }) => is_active === IsActive.On)
        .map((_) => ({
          ..._,
          price: createInstrumentPrice(
            _.price_bars?.length ? _.price_bars[0] : DEFAULT_PRICE_BAR,
            _.instrument_id,
            _.price,
          ),
        }));

      batch(() => {
        dispatch(updateInstruments(arrayToObject(availableInstrumentsWithBars, instrumentIdKey)));
        dispatch(instrumentsBarsRequestStatus(RequestStatus.Success));
      });
    } catch (error) {
      dispatch(instrumentsBarsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get instruments failed', dispatch);
    }
  }, 5000),
) as unknown as (instruments: ISelectedInstrument[]) => AnyAction;

export interface LoadInstrumentBars {
  callback: (bars: Array<Bar>, config?: any) => void,
  params: PriceBarsPayload,
  resolution: string,
  onErrorCallback: Function,
}
export const getInstrumentPriceBars = createAsyncThunk(
  'trade/getInstrumentsPriceBars',
  async (payload: LoadInstrumentBars , { dispatch, extra }: any) => {
    try {
      const { instrument_price_bars }: { instrument_price_bars: Array<InstrumentPriceBar>; } = await (extra.tradeService as TradeService).getPriceBars(payload.params);
      dispatch(updateSelectedInstrumentPriceBars(instrument_price_bars));
      const preparedBars = prepareChartBars(instrument_price_bars, payload.resolution).reverse();
      payload.callback && payload.callback(preparedBars, { noData: !instrument_price_bars.length });
    } catch (error) {
      payload.onErrorCallback && payload.onErrorCallback(error);
      handleErrorThunk(error, 'Get instrument price bars failed', dispatch);
    }
  },
) as unknown as (payload: LoadInstrumentBars) => void;

interface SubscribeInstrumentPriceBar {
  instrument: string | Array<string>,
  periodicity: Periodicity,
  callbacks: Array<(payload: Bar) => void>,
  unsubscribe?: boolean
}
export const subscribeInstrumentPriceBar = createAsyncThunk(
  'trade/subscribeInstrumentPriceBar',
  async ({ instrument = [], periodicity, unsubscribe = false, callbacks = [] }: SubscribeInstrumentPriceBar, { dispatch, extra }: any) => {
    try {
      (extra.tradeService as TradeService).subscribeInstrumentPriceBar({ instrument, periodicity, unsubscribe }, {
        next: ({ data: { instrument_price_bar }}: { data: { instrument_price_bar: InstrumentPriceBar }}) => {
          callbacks.forEach((_) => _(prepareChartBars([instrument_price_bar], periodicity)[0]));
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Subscribe instrument price bars failed', dispatch);
        },
        complete: () => {},
      });
    } catch (error) {
      handleErrorThunk(error, 'Subscribe instrument price bars failed', dispatch);
    }
  }
) as unknown as (params: SubscribeInstrumentPriceBar) => AnyAction;

interface SubscribeInstrumentPriceMarket {
  unsubscribe?: boolean
}

export const subscribeInstrumentPriceMarket = createAsyncThunk(
  'trade/subscribeInstrumentPriceBar',
  async ({ unsubscribe = false,}: SubscribeInstrumentPriceMarket, { dispatch, extra }: any) => {
    try {

      (extra.tradeService as TradeService).subscribeInstrumentPrice({ unsubscribe }, {
        next: ({ data: { instrument_price } }:{ data: { instrument_price: InstrumentPrice }  }) => {
          window._marketPrices = { ...window._marketPrices, [instrument_price.instrument_id]: instrument_price }
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Subscribe instrument price failed', dispatch);
        },
        complete: () => { },
      });

    } catch (error) {
      handleErrorThunk(error, 'Subscribe instrument price failed', dispatch);
    }
  }
) as unknown as (params: SubscribeInstrumentPriceMarket) => AnyAction;

import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { initialWithdrawState } from './helpers';
import { IDepositFiatFundingMethod, IWithdrawCryptoResult, IWithdrawFiatResult, VerificationTypes } from '#/types/interfaces';
import { RequestStatus } from '#/types/enums';
import { IWithdrawState } from './types';
import { FiatPaymentMethodsToRender } from '#reducers/wallets/wallets';

const withdrawSlice = createSlice({
  name: 'withdrawSlice',
  initialState: initialWithdrawState,
  reducers: {
    setWithdrawCrypto(state, action: PayloadAction<IWithdrawCryptoResult | null>) {
      state.withdrawCrypto = action.payload;
    },
    setWithdrawFiat(state, action: PayloadAction<IWithdrawFiatResult | null>) {
      state.withdrawFiat = action.payload;
    },
    setWithdrawStatus(state, action: PayloadAction<RequestStatus>) {
      state.withdrawRequest = action.payload;
    },
    setWithdrawFiatPaymentMethod(state, action: PayloadAction<IDepositFiatFundingMethod | null>) {
      state.withdrawFiat = null;
      state.withdrawFiatPaymentMethod = action.payload;
    },
    setWithdrawFiatPaymentRoute(state, action: PayloadAction<FiatPaymentMethodsToRender | null>) {
      state.withdrawFiatPaymentRoute = action.payload;
    },
  },
});

export const {
  setWithdrawCrypto,
  setWithdrawFiat,
  setWithdrawStatus,
  setWithdrawFiatPaymentMethod,
  setWithdrawFiatPaymentRoute,
} = withdrawSlice.actions;

export default withdrawSlice.reducer;

const selectwithdrawState = (state: Store): IWithdrawState => state.wallets.withdraw;

export const selectWithdrawCrypto: (state: Store) => IWithdrawCryptoResult | null = createDraftSafeSelector(
  selectwithdrawState,
  (withdrawState: IWithdrawState) => withdrawState.withdrawCrypto,
);

export const selectWithdrawFiat: (state: Store) => IWithdrawFiatResult | null = createDraftSafeSelector(
  selectwithdrawState,
  (withdrawState: IWithdrawState) => withdrawState.withdrawFiat,
);

export const selectWithdrawStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectwithdrawState,
  (withdrawState: IWithdrawState) => withdrawState.withdrawRequest,
);

export const selectWithdrawFiatPaymentMethod: (state: Store) => IDepositFiatFundingMethod | null = createDraftSafeSelector(
  selectwithdrawState,
  (withdrawState: IWithdrawState) => withdrawState.withdrawFiatPaymentMethod,
);

export const selectWithdrawFiatPaymentRoute: (state: Store) => FiatPaymentMethodsToRender | null = createDraftSafeSelector(
  selectwithdrawState,
  (withdrawState: IWithdrawState) => withdrawState.withdrawFiatPaymentRoute,
);

export const selectWithdrawFiatVerificationRequired: (state: Store) => boolean = createDraftSafeSelector(
  selectwithdrawState,
  ({ withdrawFiatPaymentRoute }: IWithdrawState) => !!withdrawFiatPaymentRoute && withdrawFiatPaymentRoute?.verification_type !== VerificationTypes.None,
);

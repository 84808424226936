/**
 * @name toDashCase
 * @description Replace all spaces and slash with dush
 *
 * @param {String} str
 * @returns {String}
 */

const toDashCase = (str: string) => str.toLowerCase().replace(/[ /]/g, '-');

export default toDashCase;

import { Nav } from '#/config/navigation';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../../defaultState';
import config, { Config } from '#/config/config';
import { Themes } from '#/types/enums';
import { AuthConfig, AuthOptions } from '#/config/auth';
import { Languages } from '#/config/language/langConfig';
import { SsoSettingsItem } from '#reducers/settings';

const initialState: Config = config;

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig(state: Config, action: PayloadAction<Config>) {
      state = {
        ...state,
        ...action.payload,
      }
    },
    updateAuthType(state: Config, action: PayloadAction<AuthOptions>) {
      state.auth.type = action.payload;
    },
    updateSiteTheme(state: Config, action: PayloadAction<Themes>) {
      state.theme = action.payload;
      localStorage.setItem(Themes.LocalStorageThemeItem, action.payload);
    },
    setIsMobile(state: Config) {
      state.isMobile = window.innerWidth <= 1250;
    },
    setLanguage(state: Config, action: PayloadAction<Languages>) {
      state.currentLanguage = action.payload;
    },
    updateSsoSettings(state, action: PayloadAction<SsoSettingsItem>) {
      state.ssoSettings = action.payload;
    },
  },
});

export const {
  updateConfig,
  setIsMobile,
  setLanguage,
  updateSiteTheme,
  updateAuthType,
  updateSsoSettings,
} = configSlice.actions;
export default configSlice.reducer;

export const selectConfig = (state: Store): Config => state.config;
export const selectConfigAuth = (state: Store): AuthConfig => state.config.auth;
export const selectConfigAuthType = (state: Store): AuthOptions | null => state.config.auth.type;
export const selectUseNotification = (state: Store): boolean => state.config.configEnv.useNotifications;
export const selectTemplateConfig = (state: Store) => selectConfig(state).template;
export const selectTemplateRoutesConfig = (state: Store) => selectConfig(state).template.routes;
export const selectCurrentLanguage = (state: Store): Languages => selectConfig(state).currentLanguage;
export const selectIsMobile = (state: Store): boolean => selectConfig(state).isMobile;
export const selectSiteName = (state: Store): string => selectConfig(state).configEnv.siteName;
export const selectSiteTheme = (state: Store): Themes => selectConfig(state).theme;
export const selectNavigation = (state: Store): { [navType: string]: Array<Nav> } => selectConfig(state).navigation;
export const selectSideDesktopNavigation = (state: Store): Array<Nav> => selectConfig(state).navigation.sideDesktopNavigation;
export const selectSideDesktopBottomNavigation = (state: Store): Array<Nav> => selectConfig(state).navigation.sideDesktopBottomNavigation;
export const selectSideMobileNavigation = (state: Store): Array<Nav> => selectConfig(state).navigation.sideMobileNavigation;
export const selectConfigInstrumentChart = (state: Store) => selectConfig(state).chartConfig;
export const selectConfigRedirectAfterLogin = (state: Store): string => selectConfig(state).redirectAfterLogin;
export const selectConfigHeaderNavigation = (state: Store): Array<Nav> => selectConfig(state).navigation.headerNavigation;
export const selectSsoSettings = (state: Store): SsoSettingsItem => selectConfig(state).ssoSettings;

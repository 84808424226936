import { convertExponentialToDecimal } from './exponentialToDecimal';

/**
 *
 * @param {Number} value
 * @param {Number} precision
 * @param {Boolean} useDecimalLength
 *
 * @returns {Number}
 */
export const floor = (value: number | string, precision = 4, useDecimalLength = false) => {
  precision = Number(convertExponentialToDecimal(precision));
  if (useDecimalLength && precision && precision.toString().indexOf('.') !== -1) {
    const decimalValue = precision.toString().split('.');
    precision = (decimalValue[1] || decimalValue[0]).length;
  }

  return Math.floor(Number(value) * Math.pow(10, precision)) / Math.pow(10, precision);
};

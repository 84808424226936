export const arrayToObject = <T>(arr: Array<T>, fieldNameAsKey: string): { [optionName: string]: T } => {
  const obj = {};
  arr.forEach((_: T) => {
    if (!_[fieldNameAsKey]) {
      throw new Error(`${fieldNameAsKey} no found in`, _);
    }
    obj[_[fieldNameAsKey]] = _;
  });
  return obj;
}

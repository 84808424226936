export enum NovaSettingsPages {
  Profile = 'profile',
  Verification = 'verification',
  Security = 'security',
  PaymentMethods = 'payment-methods',
  AddressBook = 'address-book',
  ApiKeys = 'api-keys',
  Notifications = 'notifications',
}

export const settingsPages = [
  NovaSettingsPages.Profile,
  NovaSettingsPages.Verification,
  NovaSettingsPages.Security,
  NovaSettingsPages.AddressBook,
  NovaSettingsPages.PaymentMethods,
  NovaSettingsPages.ApiKeys,
  NovaSettingsPages.Notifications,
];

import { combineReducers } from '@reduxjs/toolkit';
import configReducer from './config/config';
import depositReducer from './wallets/deposit/deposit';
import withdrawReducer from './wallets/withdraw/withdraw';
import paymentsReducer from './wallets/payments/payments';
import walletsReducer from './wallets/wallets/wallets';
import depositWithdrawModalReducer from './wallets/deposit-withdraw-modal/deposit-withdraw-modal';
import addressBookReducer from './wallets/address-book/address-book';
import orderbookReducer from './trade/orderbook/orderbook';
import userReducer from './user/user/user';
import instrumentsReducer from './trade/instruments/instruments';
import orderReducer from './trade/order/order';
import balancesReducer from './trade/balances/balances';
import paymentLimitsReducer from './trade/paymentLimits/paymentLimits';
import ordersReducer from './trade/orders/orders';
import settingsReducer from './settings/settings';
import kycReducer from './kyc/kyc';
import vaultsReducer from './vaults/vaults';
import conversionsReducer from './trade/conversions/conversions';
import transactionsReducer from './trade/transactions/transactions';
import articlesReducer from './home/articles/articles';
import portfolioHistoryReducer from './home/portfolio-history/portfolio-history';
import accountActivityReducer from './home/account-activity/account-activity';
import paymentFeesReducer from './trade/paymentFees/paymentFees';

import currencySlice from './cryptoPay/store/slice/currency/slice';
import feeSlice from './cryptoPay/store/slice/fee/slice';
import webhookSlice from './cryptoPay/store/slice/webhooks/slice';
import paymentSlice from './cryptoPay/store/slice/payment/slice';
import userSlice from './cryptoPay/store/slice/user/slice';

export default combineReducers({
  config: configReducer,
  user: userReducer,
  settings: settingsReducer,
  kyc: kycReducer,
  vaults: vaultsReducer,
  home: combineReducers({
    portfolioHistory: portfolioHistoryReducer,
    articles: articlesReducer,
    accountActivity: accountActivityReducer,
  }),
  wallets: combineReducers({
    deposit: depositReducer,
    withdraw: withdrawReducer,
    payments: paymentsReducer,
    wallets: walletsReducer,
    depositWithdrawModal: depositWithdrawModalReducer,
    addressBook: addressBookReducer,
  }),
  trade: combineReducers({
    orders: ordersReducer,
    orderbook: orderbookReducer,
    instruments: instrumentsReducer,
    order: orderReducer,
    balances: balancesReducer,
    conversions: conversionsReducer,
    transactions: transactionsReducer,
    paymentLimits: paymentLimitsReducer,
    paymentFees: paymentFeesReducer,
  }),
  cryptoPay: combineReducers({
    currency: currencySlice,
    fee: feeSlice,
    webhook: webhookSlice,
    payment: paymentSlice,
    user: userSlice,
  }),
});

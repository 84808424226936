import { ISelectedInstrument } from '../interfaces';
import { InstrumentPrice, InstrumentPriceBar } from '../interfaces';
import { InstrumentsState } from './types';
import { RequestStatus } from '#/types/enums';

const savedInstrumentStorageKey = 'instrument';
export const instrumentIdKey = 'instrument_id';

export const getSavedInstrumentStorage = () => {
  return localStorage.getItem(savedInstrumentStorageKey) || 'BTCUSD';
}

export const saveInstrumentStorage = (instrumentId: string) => {
  localStorage.setItem(savedInstrumentStorageKey, instrumentId);
}

export const defaultPriceBar: InstrumentPriceBar = {
  instrument_id: '',
  high: 0,
  low: 0,
  open: 0,
  close: 0,
  ts_iso: '',
}

export const defaultPrice: InstrumentPrice = {
  ask: 0,
  bid: 0,
  price_24h_change: 0,
  instrument_id: '',
  ts_iso: '',
}

export const initialStateInstruments: InstrumentsState = {
  selectedInstrument: {
    instrument_id: '',
    base_currency_id: '',
    quote_currency_id: '',
    price_bars: [],
    price: defaultPrice,
    recent_price_bar: defaultPriceBar,
  } as unknown as ISelectedInstrument,
  selectedInstrumentPriceBars: [],
  conversionExchangeCurrency: '',
  instruments: {},
  instrumentsRequestStatus: RequestStatus.None,
  instrumentsBarsRequestStatus: RequestStatus.None,
}

export const createInstrumentPrice = (priceBar: InstrumentPriceBar, instrumentId: string, instrumentPrice: InstrumentPrice = defaultPrice): InstrumentPrice => ({
  ask: instrumentPrice?.ask || priceBar?.high || priceBar?.low || 0,
  bid: instrumentPrice?.bid || priceBar?.low || priceBar?.high || 0,
  price_24h_change: instrumentPrice?.price_24h_change || 0,
  instrument_id: instrumentId,
  ts_iso: instrumentPrice?.ts_iso || priceBar?.ts_iso || '',
});

// TODO move to utils perhaps
export const mergeObjectsByProperty = <T>(arr1: T[], arr2: T[], property: keyof T): T[] => {
  const mergedMap: { [key: string]: T } = {};

  const mergeArray = (arr: T[]) => {
    for (const obj of arr) {
      const key = String(obj[property]);
      if (!mergedMap[key]) {
        mergedMap[key] = { ...obj };
      } else {
        Object.assign(mergedMap[key], obj);
      }
    }
  };

  mergeArray(arr1);
  mergeArray(arr2);

  return Object.values(mergedMap);
}

/**
 * @name toCamelCase
 *
 * @description Convert string to camel case format
 *
 * @param {String} str
 * @returns {String}
 */

const toCamelCase = (str: string, force: boolean = true) => {
  if (!force && !str.match(/[\s_-]/g) && !str[0].match(/^[A-Z]/)) {
    return str;
  }
  return str
    .split(/[_-\s]+/)
    .map(
      (word, i) => (i === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
    )
    .join('');
};

export default toCamelCase;

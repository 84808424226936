import {
  ThunkDispatch,
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI
} from '@reduxjs/toolkit';

import { Store } from './defaultState';
import logoutEffect from '#reducers/listenerEffects/logoutEffect';
import { refreshTokenListenerEffect } from './reducers/listenerEffects/refreshTokenListenerEffect';

const listener = createListenerMiddleware();

listener.startListening({
  predicate: (action: AnyAction, store: any, prevState: any) => true,
  // @ts-ignore
  effect: async (action: AnyAction, listenerApi: ListenerEffectAPI<Store, ThunkDispatch<Store, unknown, AnyAction>>) => {

    logoutEffect(action, listenerApi);

    refreshTokenListenerEffect(action, listenerApi);
  },
})

export default listener;

import useUser from '#/hooks/user/useUser';
import { selectFavoriteInstrumentsIds } from '#reducers/user/user';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

interface Result {
  favorites: Array<string>,
  isFavorite: boolean,
  updateFavorite: (id: string) => void,
  isAuth: boolean,
}

export const useIsFavorite = ({ instrument }: { instrument?: string }): Result => {
  const user = useUser();
  const favoriteIds = useSelector(selectFavoriteInstrumentsIds);
  const isFavorite = instrument ? favoriteIds.includes(instrument) : false;

  const updateFavorite = (instrumentId: string) => {

    user.saveUser({
      ignorePending: true,
      ignoreNotification: false,
      customNotification: isFavorite 
        ? _t('{instrument} deleted from favorite instruments', 'INSTRUMENTS.DELETED_FAVORITE_WITH', {instrument: instrumentId}) 
        : _t('Favorite instruments updated with {instrument}', 'INSTRUMENTS.UPDATED_FAVORITE_WITH', {instrument: instrumentId}),
      favorite_instruments: isFavorite
        ? favoriteIds.filter((_) => _ !== instrumentId)
        : [...favoriteIds, instrumentId],
    });
  }

  return {
    favorites: favoriteIds,
    isAuth: user.isAuth,
    isFavorite,
    updateFavorite: useCallback(updateFavorite, [favoriteIds.length, isFavorite]),
  };
};

import React from 'react';

const CloseIcon = () => (
  <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.12695 1.0708L10.4152 10.3591" className="close-btn-path" strokeWidth="1.64963" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.12695 10.3594L10.4152 1.07112" className="close-btn-path" strokeWidth="1.64963" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CloseIcon;

import capitalize from './capitalize';

/**
 * @name toCapsAndSpaces
 *
 * @description Convert first letter of each words to uppercase and replace separators with spaces
 *
 * @param {String} str
 * @param {String} separator
 *
 * @returns {String}
 */

const toCapsAndSpaces = (str: string, separator: string = '_') => {
  const sep = new RegExp(separator, 'g');
  str = str.replace(sep, ' ');

  return capitalize(str);
};

export default toCapsAndSpaces;

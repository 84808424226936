import { NotificationTrigger, IsActive, NotificationsOptions } from '#/types';
import { FavoriteAddressCrypto, FavoriteFiatDestination, TradePager, PaymentProperties } from '#reducers/trade/interfaces';
import { UserLanguages, UserMarketCurrencies } from '#reducers/user/user'

export interface LoginPayload {
  username: string,
}

export interface UpdateMfaPayload {
  status: IsActive,
  secret?: string,
  token: string,
}

export interface UpdateUserProfile {
  username?: string,
  email?: string,
  language?: UserLanguages,
  timezone?: string,
  primary_market_currency?: UserMarketCurrencies,
  favorite_instruments?: Array<string>,
  favorite_addresses_crypto?: Array<FavoriteAddressCrypto>,
  favorite_fiat_destinations?: Array<FavoriteFiatDestination> & PaymentProperties,
  notifications_options?: NotificationsOptions | {},

  ignorePending?: boolean, // TODO only local, not for request
  ignoreNotification?: boolean, // TODO only local, not for request
  updateBalance?: boolean, // TODO only local, not for request
  customNotification?: string,// only local
  default_timezone?: IsActive,
}

export interface IPaymentLimit {
  currency_id: string,
  deposit_enabled: IsActive,
  deposit_min_amount: number,
  deposit_daily_limit: number,
  deposit_weekly_limit: number,
  deposit_monthly_limit: number,
  withdrawal_enabled: IsActive,
  withdrawal_min_amount: number,
  withdrawal_daily_limit: number,
  withdrawal_weekly_limit: number,
  withdrawal_monthly_limit: number,
}

export interface UploadDocument {
  documentType: DocumentTypes,
  file: File,
}

export interface DeleteDocument {
  documentType: DocumentTypes,
}

export enum DocumentTypes {
  ProfilePicture = 'profile_picture',
}

export interface ILoginResult {
  jwt: string,
  expires_at: number,
}

export interface ILoginResponse {
  trader_demo_signin: ILoginResult,
}

export interface ISecretCodeResponse {
  secret: string,
  uri: string,
  qr: string,
}

export interface GetAccountActivityPayload {
  pager?: TradePager,
  event_name?: NotificationTrigger[],
}

export interface ChangePasswordPayload {
  oldPassword: string,
  newPassword: string,
}

export interface IAntiPhishingCodePayload {
  anti_phishing_code: string,
  token: string,
}

export interface IGetUserProfilePayload {
  twoFaCode?: string,
}

export interface IGetUserPaymentFeesPayload {
  fee_group_id?: string,
}

export interface IUserPaymentFees {
  currency_id: string,
  deposit_flat_fee: number,
  deposit_progressive_fee: number,
  fee_group_id: string,
  fiat_transfer_type: string,
}

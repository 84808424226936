import { sendRequest } from './request';
import { ICreatePaymentRequest } from './interface';
import qs from 'qs';

import { IPrepareFilterData } from '#/nova/components/views/crypto-pay/transactionsTab/types';

export const payment = {
  getPayments: (filterData?: IPrepareFilterData | undefined) => {
    const queryParams = qs.stringify(filterData, { skipNulls: true });

    return sendRequest(`v1/payment${queryParams ? `?${queryParams}` : ''}`, 'GET');
  },

  getPaymentAddresses: (paymentId: string) => sendRequest(`v1/payment/${paymentId}/addresses`, 'GET'),

  createPayment: (body: ICreatePaymentRequest) => sendRequest(`v1/payment`, 'POST', body),
};

import React, { useEffect } from 'react';
import { Themes } from '#/types/enums';
import './theme-light/theme-light.scss';
import './theme-dark/theme-dark.scss';
import './common.scss';

interface Props {
  theme: Themes,
  children: any,
}
const NovaThemeProvider = (props: Props) => {
  const { theme = Themes.Light, children } = props;

  useEffect(() => {
    document.body.classList.value = `theme-${theme}`;
  }, [theme]);

  return (
    <>
      <div className={`nova-theme-${theme}`}>
        {children}
      </div>
    </>
  );
};

export default NovaThemeProvider;

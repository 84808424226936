import langConfig, { LangConfig, Languages } from "./language/langConfig";
import auth, { AuthConfig } from '#/config/auth';
import { Themes } from '#/types/enums';
import poeditorConfig, { PoeditorConfig } from "./poeditor/poeditor";
import { nova, ITemplate } from "./templates";
import configEnv, { PermanentConfigEnv } from './config-env';
import chartConfig from './chart/instrument-chart';
import kycConfig, { IKycConfig } from './kyc';
import * as navigation from './navigation';
import getCurrentLang from '#/util/translation/getCurrentLang';
import { SsoSettingsItem } from '#reducers/settings';
import cryptoPayConfig, { ICryptoPayConfig } from "./crypto-pay/cryptoPayConfig";
import { Paths } from "./templates/nova/nova";

export interface Config {
    configEnv: PermanentConfigEnv,
    auth: AuthConfig,
    langConfig: LangConfig,
    currentLanguage: Languages,
    poeditorConfig: PoeditorConfig,
    template: ITemplate,
    isMobile: boolean,
    kyc: IKycConfig,
    navigation: {
        sideDesktopBottomNavigation: Array<navigation.Nav>,
        sideDesktopNavigation: Array<navigation.Nav>,
        sideMobileNavigation: Array<navigation.Nav>,
        headerNavigation: Array<navigation.Nav>,
    },
    ssoSettings: SsoSettingsItem,
    redirectAfterLogin: string,
    chartConfig: any,
    dateFormat: string,
    timeFormat: string,
    theme: Themes,
    cryptoPayConfig: ICryptoPayConfig,
}

const config: Config = {
    configEnv,
    auth,
    langConfig,
    currentLanguage: getCurrentLang(),
    ssoSettings: {
        client_id: '',
        domain: '',
        type: '',
        pool_id: '',
    },
    poeditorConfig,
    kyc: kycConfig,
    template: nova,
    chartConfig,
    isMobile: window.innerWidth <= 1250,
    redirectAfterLogin: Paths.Exchange,
    navigation,
    dateFormat: 'MMMM dd, yyyy', // e.g. 'MM', 'DD', 'YY', 'YYYY', SEE moment.js FOR MORE OPTIONS
    timeFormat: '12hr', // '24hr' or '12hr'
    theme: localStorage.getItem(Themes.LocalStorageThemeItem) as Themes || nova.defaultSiteTheme,
    cryptoPayConfig,
}

export default config

import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import TradeService from '#/api/trade/TradeService';
import { setPaymentsStatus, setPayments, setPaymentsNextPageExists } from './payments';
import { RequestStatus } from '#/types/enums';
import { debounce, handleErrorThunk, throttle } from '#/util';
import { IPaymentResult } from '#/types/interfaces';
import { CreatePaymentSession, EstimatePaymentFeePayload, GetPaymentsPayload } from '#/api/trade/dto';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';
import { batch } from 'react-redux';

export const getPayments = createAsyncThunk(
  'wallets/getPayments',
  debounce(1000, async (paymentsPaylod: GetPaymentsPayload, { dispatch, extra }: any) => {
    try {
      const { payments }: { payments: Array<IPaymentResult> } = await (extra.tradeService as TradeService).getPayments(preparePagerDifferencePlus1(paymentsPaylod));

      batch(() => {
        const pagerPayments = prepareResultPagerDifference(payments, paymentsPaylod.pager?.limit);
        dispatch(setPayments(pagerPayments));
        dispatch(setPaymentsNextPageExists(payments.length > pagerPayments.length));
      });
    } catch (error) {
      dispatch(setPaymentsStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get payments history failed', dispatch);
    }
  }),
) as unknown as (paymentsPaylod: GetPaymentsPayload) => AnyAction;

interface EstimatePaymentFeeThunkParams {
  callback: (n: number, err?: string) => void,
  params: EstimatePaymentFeePayload,
}
export const estimatePaymentFee = createAsyncThunk(
  'wallets/estimatePaymentFee',
  debounce(300, async (params: EstimatePaymentFeeThunkParams, { dispatch, extra }: any) => {
    try {
      const { estimate_payment_fee } = await (extra.tradeService as TradeService).estimatePaymentFee(params.params);
      params.callback(estimate_payment_fee);
    } catch (error) {
      params.callback(0, "Can't be counted.");
      handleErrorThunk(error, 'Estimate payment fee failed!', dispatch);
    }
  }),
) as unknown as (params: EstimatePaymentFeeThunkParams) => AnyAction;

interface CreatePaymentSessionThunkParams {
  params: CreatePaymentSession,
  callback: (id: string) => void,
}
export const createPaymentSession = createAsyncThunk(
  'wallets/createPaymentSession',
  throttle(async (params: CreatePaymentSessionThunkParams, { dispatch, extra }: any) => {
    try {
      const { create_payment_session } = await (extra.tradeService as TradeService).createPaymentSession(params.params);
      params.callback(create_payment_session);
    } catch (error) {
      handleErrorThunk(error, 'Creating payment session failed!', dispatch);
    }
  }, 500),
) as unknown as (params: CreatePaymentSessionThunkParams) => AnyAction;

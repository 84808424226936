import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { notificationApiSupported } from '#/hooks/helpers/useNotificationsApi';
import SuccessIcon from '#images/icons/toast-icons/success.svg';
import ErrorIcon from '#images/icons/toast-icons/error.svg';
import InfoIcon from '#images/icons/toast-icons/info.svg';
import ToastMessageContent from './novaToastMessageContent';
import CloseBtn from './novaToastCloseBtn';
import defaultIconNotificationApi from '#images/logos/favicon.png';
import './nova-toast.scss';

export enum DefaultToastOptions {
  Success = 'success',
  Error = 'error',
  Info = 'info',
}

export type ToastDetailsElement = { [key: string]: string | number | undefined };

interface INovaToast {
  success: (message: string, details?: ToastDetailsElement, toastOptions?: ToastOptions) => void;
  error: (message: string, details?: ToastDetailsElement, toastOptions?: ToastOptions, className?: string) => void;
  info: (message: string, details?: ToastDetailsElement, toastOptions?: ToastOptions, className?: string) => void;
  notificationApi: (message: string, defaultType: DefaultToastOptions, details?: ToastDetailsElement, toastOptions?: ToastOptions) => void;
}

export const novaToast: INovaToast = {
  notificationApi: function (message, defaultType, details, toastOptions) {
    if (notificationApiSupported() && Notification.permission === 'granted') {
      const preparedDetails = details ? Object.entries(details).reduce((acc, _) => _[0] && _[1] ? acc += `${_[0]}: ${_[1]}\n` : acc, '') : undefined;
      try {
        new Notification(message, {
          body: preparedDetails,
          icon: defaultIconNotificationApi,
          vibrate: 500,
        });
      } catch {}
    }
    this[defaultType](message, details, toastOptions);
  },
  info: (message, details, toastOptions, cssClassName) => {
    const className = `nova-toast ${details ? 'has-extra-text' : ''} ${cssClassName}`;

    toast.success(
      <ToastMessageContent
        message={message}
        details={details}
      />,
      {
        className,
        icon: <img alt="ok" src={InfoIcon} />,
        closeButton: CloseBtn,
        ...(toastOptions),
      },
    );
  },
  success: (message, details, toastOptions) => {
    const className = `nova-toast ${details ? 'has-extra-text' : ''}`;

    toast.success(
      <ToastMessageContent
        message={message}
        details={details}
      />,
      {
        className,
        icon: <img alt="ok" src={SuccessIcon} />,
        closeButton: CloseBtn,
        ...(toastOptions),
      },
    );
  },
  error: (message, details, toastOptions, cssClassName) => {
    const className = `nova-toast ${details ? 'has-extra-text' : ''} ${cssClassName}`;

    toast.error(
      <ToastMessageContent
        message={message}
        details={details}
      />,
      {
        className,
        icon: <img alt='fail' src={ErrorIcon} />,
        closeButton: CloseBtn,
        ...(toastOptions),
      },
    );
  },
};

import { GraphQLClient } from 'graphql-request'
import authHelper from '#reducers/user/user/auth-helper';
import { RequestStatus } from '#/types/enums';

export interface IGqlRequest {
  query: string,
  variables?: Record<string, unknown> | null,
};

export default class GqlClient {
  public authorization: string;
  public client: GraphQLClient;

  constructor(url: string, headers?: any) {
    this.authorization = headers?.authorization || '';
    this.client = new GraphQLClient(url, { headers });
    this._setAuthorization();
  }

  private _setAuthorization() {
    const { accessToken, authStatus } = authHelper.getAuthFromStorage();
    if (authStatus === RequestStatus.Success && accessToken) {
      this.setToken(accessToken);
    }
  }

  public setToken(token: string | undefined) {
    this.authorization = token || '';
    this.setHeaders({ authorization: this.authorization } as unknown as Headers);
  }

  public setHeaders(_headers?: Headers) {
    this.client.setHeaders(_headers);
  }

  public async request(apiRequest: IGqlRequest): Promise<any> {
    try {
      return await this.client.request(apiRequest.query, apiRequest.variables);
    } catch (error) {
      const parsedError = JSON.parse(JSON.stringify(error));
      throw (parsedError.response);
    }
  }
}

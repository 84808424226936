export const formatNumberToExchange = (_value: number | string, decimals: number) => {
  let value = _value;
  if (value === '.' || value === ',' || value === '/' || value === 'ю' || value === 'б') {
    value = '0.';
  }

  const strValue = String(value).replaceAll(',', '.').replaceAll('/', '.').replaceAll('ю', '.').replaceAll('б', '.');
  const decimalsValue = strValue.split('.');
  let result = decimalsValue[0];

  // if (decimalsValue[0].length > 8) {
  //   result = result.substring(0, 8);
  // }

  if (decimalsValue[1]) {
    decimalsValue[1].length > decimals
      ? result = `${result}.${decimalsValue[1].substring(0, decimals)}`
      : result = `${result}.${decimalsValue[1]}`
  } else if (strValue.includes('.')) {
    result += '.';
  }

  if (result[0] === '0' && result[1] && result[1] !== '.') {
    result = result.substring(1);
  }

  return result;
}
// One minute in milliseconds
import { Periodicity } from '#reducers/trade/enums';
const minute: number = 60000;

const getTimeframe = (time: string | Periodicity = '1D') => {
  // Calculate 1 Day in milliseconds
  const dayInMilliseconds = 24 * 60 * minute;

  switch (time) {
    case '1D': {
      return dayInMilliseconds;
    }
    case '1W': {
      return dayInMilliseconds * 7;
    }
    case '1M': {
      return dayInMilliseconds * 30; // FIXME different days
    }
    case Periodicity.Minute5: {
      return 1000 * 60 * 5;
    }
    case Periodicity.Minute15: {
      return 1000 * 60 * 15;
    }
    case Periodicity.Minute30: {
      return 1000 * 60 * 30;
    }
    case Periodicity.Hour: {
      return 1000 * 60 * 60;
    }
    case Periodicity.Hour4: {
      return 1000 * 60 * 60 * 4;
    }
    case Periodicity.Hour8: {
      return 1000 * 60 * 60 * 8;
    }
    case Periodicity.Minute: {
      return 1000 * 60;
    }
    default: {
      return (!!Number(time) ? Number(time) : 15) * minute;
    }
  }
};

export default getTimeframe;

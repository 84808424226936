import React from 'react';
import { ToastDetailsElement } from './novaToast';
import { toSpaces } from '#/util';

const ToastMessageContent = (props: {
  message: string,
  details?: ToastDetailsElement,
}) => {
  const { message, details } = props;

  const renderDetails = () => Object.entries(details || {}).map((detail) => detail[0] ? (
    <div className='nova-toast-details' key={`${detail[0]}${detail[1]}`}>
      { detail[0] !== 'noTitle' && !!detail[0] ? <span className='nova-toast-detail-info-label'>{ toSpaces(detail[0]) }</span> : null }
      {
        detail[1] ? (
          <span className={`nova-toast-detail-info-value nova-toast-detail-info-${String(detail[0]).toLowerCase()}`}>
            { toSpaces(String(detail[1])) }
          </span>
        ) : null
      }
    </div>
  ) : null);

  return (
    <div className='toast-message-content'>
      <h4 className='nova-toast-message'>{message}</h4>
      <>
        { renderDetails() || null }
      </>
    </div>
    );
};

export default ToastMessageContent;

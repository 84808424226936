import { IWebhookState } from './interface';
import { RequestStatus } from '#/types/enums';

export const initialWebhookState: IWebhookState = {
  webhooks: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  addWebhook: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  deleteWebhookById: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateWebhookById: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },
};

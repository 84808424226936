import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsAuth,
  subscribeUserEvents,
  unsubscribeUserEvents
} from '#reducers/user/user';
import useClearWalletsState from './wallets/useClearWalletsState';

const useUserSubscriptions = () => {
  const dispatch = useDispatch();
  useClearWalletsState();
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    isAuth ? dispatch(subscribeUserEvents()) : dispatch(unsubscribeUserEvents());

    return () => { dispatch(unsubscribeUserEvents()) }
  }, [isAuth]);
}

export default useUserSubscriptions;

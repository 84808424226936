interface Payload {
  amount: number,
  balance: number,
}

export function getPercentageOfBalance({ amount, balance }: Payload) {
  const balanceNumber = Number(balance);
  if (balanceNumber <= 0) {
    return 0;
  }

  return (Number(amount) * 100) / balanceNumber;
}

export function getValueFromBalancePercentage({ amount, balance }: Payload) {
  const balanceNumber = Number(balance);
  if (balanceNumber <= 0) {
    return 0;
  }

  return (Number(amount) / 100) * balanceNumber;
}

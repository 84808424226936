import { useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { RequestStatus } from '#/types/enums';
import {
  selectAccountType,
  selectKycLevel,
  selectKycMessage,
  selectKycStatus,
  selectKycStatusRequired,
  getKycSessionId,
  selectKycSession,
  getKycWidgetProvider,
  selectStatusKycProvider,
  selectKycProviders,
  selectKycProviderWidgetsUrl,
  selectKycProvidersEnabled,
  KycType,
} from '#reducers/kyc';
import { getUserProfile, selectAuthStatus,} from '#reducers/user/user';
import useUser from './useUser';

const useKyc = () => {
  const dispatch = useDispatch();
  const { userStatus: kycStatusRequest } = useUser();
  const accountType = useSelector(selectAccountType);
  const statusKycProvider = useSelector(selectStatusKycProvider);
  const providers = useSelector(selectKycProviders);
  const kycProvidersEnabled = useSelector(selectKycProvidersEnabled)
  
  const statusKycProviderWidget = useSelector(selectStatusKycProvider);
  const kycStatus = useSelector(selectKycStatus);
  const kycLevel = useSelector(selectKycLevel);
  const sessionId = useSelector(selectKycSession);
  const kycMessage = useSelector(selectKycMessage);
  const widgetsUrl = useSelector(selectKycProviderWidgetsUrl);
  const kycRequired = useSelector(selectKycStatusRequired) && (kycProvidersEnabled?.corporate || kycProvidersEnabled?.individual) ;

  const authStatus = useSelector(selectAuthStatus);
  const isAuth = authStatus === RequestStatus.Success;

  useEffect(() => {
    const isStautusNoSuccess = statusKycProviderWidget !== RequestStatus.Success;
    if (isStautusNoSuccess && isAuth) {
      dispatch(getKycWidgetProvider());
    }
  }, [isAuth])

  const getKyc = () => {
    dispatch(getUserProfile());
  }

  const getKycSession = () => {
    dispatch(getKycSessionId(accountType as KycType));
  };

  return {
    accountType,
    providers,
    widgetsUrl,
    kycProvidersEnabled,
    statusKycProviderWidget,
    kycStatus,
    statusKycProvider,
    kycLevel,
    sessionId,
    kycMessage,
    kycStatusRequest,
    kycRequired,
    getKyc: useCallback(getKyc, []),
    getKycSession,
  }
}

export default useKyc;

import { sendRequest } from './request';
import {
  ICreateFeeGroupRequest,
  IUpdateFeeGroupRequest,
  IAddSubUserToFeeGroupRequest,
  IUpdateSubUserFeeGroupRequest,
  IDeleteSubUserFromFeeGroupRequest,
} from './interface';

export const fee = {
  getPaymentFeesForAllPayments: () => sendRequest(`v1/fee/payment-fees`, 'GET'),
  getPaymentFeesForPayment: (paymentId: string) => sendRequest(`v1/fee/payment-fees/${paymentId}`, 'GET'),
  getFeeGroupsForUser: () => sendRequest(`v1/fee/groups`, 'GET'),
  getFeeGroupByName: (groupName: string) => sendRequest(`v1/fee/group/${groupName}`, 'GET'),

  createFeeGroup: (body: ICreateFeeGroupRequest) => sendRequest(`v1/fee/group`, 'POST', body),
  updateFeeGroup: (body: IUpdateFeeGroupRequest) => sendRequest(`v1/fee/group`, 'PUT', body),
  deleteFeeGroupByName: (groupName: string) => sendRequest(`v1/fee/group/${groupName}`, 'DELETE'),

  addSubUserToFeeGroup: (body: IAddSubUserToFeeGroupRequest) => sendRequest(`v1/fee/group/add-user`, 'POST', body),
  updateSubUserFeeGroup: (body: IUpdateSubUserFeeGroupRequest) => sendRequest(`v1/fee/group/change-user`, 'PUT', body),
  deleteSubUserFromFeeGroup: (body: IDeleteSubUserFromFeeGroupRequest) =>
    sendRequest(`v1/fee/group/remove-user`, 'DELETE', body),
};

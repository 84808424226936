import { IFeeState } from './interface';
import { RequestStatus } from '#/types/enums';

export const initialFeeState: IFeeState = {
  paymentFeesForAllPayments: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  paymentFeesForPayment: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  feeGroupsForUser: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  feeGroupByName: {
    item: null,
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  createFeeGroup: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateFeeGroup: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  deleteFeeGroupByName: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  addSubUserToFeeGroup: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateSubUserFeeGroup: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  deleteSubUserFromFeeGroup: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },
};

import React, { FC } from 'react';
import { CloseButtonProps } from 'react-toastify';
import CloseIcon from '#/nova/components/other/toast/novaToastCloseIcon';

const CloseButton: FC<CloseButtonProps> = ({ closeToast }) => (
  <button
    className="Toastify__close-button"
    type="button"
    aria-label="close"
    onClick={closeToast}
  >
    <CloseIcon />
  </button>
);

export default CloseButton;

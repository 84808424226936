import * as gql from 'gql-query-builder';

import {
  GetPaymentsPayload,
  EstimatePaymentFeePayload,
  CreatePaymentSession,
} from './../dto';
import {
  paymentFields,
} from './../fields';

export * from './../dto';

export enum ConversionOperations {
  EstimatePaymentFee = 'estimate_payment_fee',
  CreatePaymentSession = 'create_payment_session',
  Payments = 'payments',
}

export default {

  getPayments: (params: GetPaymentsPayload) => {
    return gql.query({
      operation: ConversionOperations.Payments,
      variables: {
        type: { value: params.type, required: false, type: 'PaymentType' },
        currency_id: { value: params.currency_id, required: false, type: 'String' },
        dateRange: { value: params.dateRange, required: false, type: 'DateRangeInput' },
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        approval_status: { value: params.approval_status, required: false, type: '[PaymentApprovalStatus!]' },
        status: { value: params.status, required: false, type: '[PaymentStatus!]' },
      },
      fields: paymentFields,
    });
  },

  estimatePaymentFee: (params: EstimatePaymentFeePayload) => {
    return gql.mutation({
      operation: ConversionOperations.EstimatePaymentFee,
      variables: {
        user_id: { value: params.user_id, required: false, type: 'String' },
        payment_route_id: { value: params.payment_route_id, required: true, type: 'String' },
        type: { value: params.type, required: true, type: 'PaymentType' },
        amount: { value: params.amount, required: true, type: 'Float' },
        crypto_network_fee_preference: { value: params.crypto_network_fee_preference, required: false, type: 'CryptoNetworkFeePreference' },
      },
      fields: [],
    });
  },

  createPaymentSession: (params: CreatePaymentSession) => {
    return gql.mutation({
      operation: ConversionOperations.CreatePaymentSession,
      variables: {
        payment_route_id: { value: params.payment_route_id, required: true, type: 'String' },
        payment_type: { value: params.payment_type, required: true, type: 'PaymentType' },
      },
      fields: [],
    });
  },
}

/**
 * @name camelCaseToSpaces
 *
 * @description Convert camel case to spaces
 *
 * @param {String} str
 * @returns {String}
 */

const camelCaseToSpaces = (str: string) => str
  .replace(/_/g, ' ')
  .replace(/([A-Z])/g, ' $1')
  .trim()
  .replace(/\b[a-z]/g, _ => _.toUpperCase());

export default camelCaseToSpaces;

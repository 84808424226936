import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { OrderbookState, Orderbook, OrderPrices } from './types';
import { initialStateOrderbook } from './helpers';

const orderbookSlice = createSlice({
  name: 'orderbook',
  initialState: initialStateOrderbook,
  reducers: {
    replaceOrderbook(state, action: PayloadAction<Orderbook>) {
      state.orderbook = action.payload;
    },
    updateOrderPrices(state, action: PayloadAction<OrderPrices>) {
      state.orderPrices = action.payload;
    },
  },
});

export const { replaceOrderbook, updateOrderPrices } = orderbookSlice.actions;

export default orderbookSlice.reducer;

export const selectOrderbookState = (state: Store): OrderbookState => state.trade.orderbook;

export const selectOrderbook: (state: Store) => Orderbook = createDraftSafeSelector(
  selectOrderbookState,
  (orderState: OrderbookState) => orderState.orderbook,
);

export const selectOrderPrices: (state: Store) => OrderPrices = createDraftSafeSelector(
  selectOrderbookState,
  (orderState: OrderbookState) => orderState.orderPrices,
);

import React from 'react';
import { Link } from 'react-router-dom';
import companyLogo from '#images/logos/company-logo-alt.png';
import companyLogoDark from '#images/logos/company-logo.png';
import './logo.scss';

const Logo = ({ isDark = false, isLoading = false }: { isDark?: boolean, isLoading?: boolean }) => {
  const renderLogo = () => (
    <img src={!isDark ? companyLogo : companyLogoDark} alt="logo" />
  );

  return (
    <div className="logo-container">
      {isLoading
        ? renderLogo()
        : <Link to="/">
            { renderLogo() }
          </Link>
      }
    </div>
  )
};

export default Logo;

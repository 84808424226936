/**
 * @param {String} str
 * @param {String} caseChange // OPTIONS: "lowercase" or "uppercase"
 *
 * @returns {String}
 */
import { Cases } from '#/types/enums';

const toSnakeCase = (str: string, caseChange = '') => {
  str = str.replace(/ /g, '_');

  if (caseChange === Cases.Lowercase) {
    str = str.toLowerCase();
  } else if (caseChange === Cases.Uppercase) {
    str = str.toUpperCase();
  }

  return str;
};

export default toSnakeCase;

import { PriceBarsPayload } from '#/api/trade/trade-qql';
import { UseInstrumentPriceBarParams } from '#/hooks/trade/instruments/useInstrumentPriceBar';
import {  LoadInstrumentBars } from '#reducers/trade/instruments';
import { ISelectedInstrument } from '#reducers/trade/interfaces';
import { HistoryCallback, IDatafeedChartApiExternal, ResolveCallback, LibrarySymbolInfo, PeriodParams, ResolutionString, SearchSymbolsCallback, SubscribeBarsCallback } from './datafeed-api';
import { getResolution } from './getResolution';

export type ResolutionInnerChart = string | number;

export default (loadHistoryFunction: (params: LoadInstrumentBars) => void, loadCurrentBars: (payload: UseInstrumentPriceBarParams) => void, selectedInstrument: ISelectedInstrument): IDatafeedChartApiExternal => {
  const supportedResolutions = ['1', '5', '15', '30', 'D'];
  const config = {
    supported_resolutions: supportedResolutions,
  };
  const pricescaleFormatted = Number(1+'0'.repeat(selectedInstrument.price_decimals));

  return {
    onReady: (cb: Function) => {
      setTimeout(() => cb(config), 0);
    },
    resolveSymbol: (symbolName: string, onSymbolResolvedCallback: ResolveCallback, onError: Function) => {
      if (!symbolName) {
        onError('cannot resolve symbol')
      }
      const symbol_stub = {
        name: symbolName,
        full_name: symbolName,
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        minmov: 1,
        pricescale: pricescaleFormatted,
        has_intraday: true,
        intraday_multipliers: supportedResolutions,
        supported_resolution: supportedResolutions,
        volume_precision: 8,
        exchange: '',
        listed_exchange: '',
        format: 'price',
        data_status: 'streaming',
      };
      setTimeout(() => {
        // @ts-ignore
        onSymbolResolvedCallback(symbol_stub as LibrarySymbolInfo);
      }, 0);
    },
    searchSymbols: (userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback) => {},
    getBars: (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: Function) => {
      const { from, to } = periodParams;
      const params: PriceBarsPayload = {
        instrument_id: selectedInstrument.instrument_id.split('/').join(''),
        periodicity: getResolution(resolution),
        limit: 400,
        date_range: {
          time_from: new Date(from * 1000).toISOString(),
          time_to: new Date(to * 1000).toISOString(),
        },
      }
      loadHistoryFunction({ params, resolution, callback: onResult, onErrorCallback: onError });
    },
    subscribeBars: (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void) => {
      loadCurrentBars({
        periodicity: getResolution(resolution),
        instrument: selectedInstrument.instrument_id.split('/').join(''),
        callbacks: [onTick],
      });
    },
    unsubscribeBars: (listenerGuid: string) => {},
    // As per the TradingView docs, calculateHistoryDepth has been removed from TV api.
    // Docs suggest using countBack from periodParams if this is something we need
    // calculateHistoryDepth: (resolution: ResolutionInnerChart) => (resolution < 60 ? { resolutionBack: 'D', intervalBack: '1' } : undefined),
  };
};

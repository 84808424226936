import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { ISelectedInstrument, InstrumentPrice, InstrumentPriceBar } from '../interfaces';
import { RequestStatus } from '#/types/enums';
import { getSavedInstrumentStorage, saveInstrumentStorage, initialStateInstruments } from './helpers';
import { Instruments, InstrumentsState } from './types';
import { selectUserDisabledInstruments } from '../paymentLimits';

const instrumentsSlice = createSlice({
  name: 'instruments',
  initialState: initialStateInstruments,
  reducers: {
    instrumentsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.instrumentsRequestStatus = action.payload;
    },
    instrumentsBarsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.instrumentsBarsRequestStatus = action.payload;
    },
    updateSelectedInstrumentPriceBars(state, action: PayloadAction<Array<InstrumentPriceBar>>) {
      state.selectedInstrumentPriceBars = action.payload
    },
    updateSelectedInstrumentPriceBar(state, action: PayloadAction<InstrumentPriceBar>) {
      state.selectedInstrumentPriceBars.push(action.payload)
    },
    updateConversionExchangeCurrency(state, action: PayloadAction<string>) {
      state.conversionExchangeCurrency = action.payload;
    },
    updateSelectedInstrument(state, action: PayloadAction<ISelectedInstrument>) {
      state.selectedInstrument = action.payload;
      state.selectedInstrumentPriceBars = [action.payload?.recent_price_bar];
      saveInstrumentStorage(action.payload?.instrument_id);
    },
    updateInstruments(state, action: PayloadAction<Instruments>) {
      state.instruments = action.payload;
      state.instrumentsRequestStatus = RequestStatus.Success;

      if (!state.selectedInstrument || !Object.values(state.selectedInstrument).length || !state.selectedInstrument.instrument_id) {
        const arrayInstruments = Object.values(action.payload);
        state.selectedInstrument = arrayInstruments.find((_) => _.instrument_id === getSavedInstrumentStorage()) || arrayInstruments[0];
      }
    },
  },
});

export const {
  updateSelectedInstrument,
  updateInstruments,
  updateSelectedInstrumentPriceBar,
  updateSelectedInstrumentPriceBars,
  instrumentsRequestStatus,
  instrumentsBarsRequestStatus,
  updateConversionExchangeCurrency,
} = instrumentsSlice.actions;

export default instrumentsSlice.reducer;

export const selectInstrumentsState = (state: Store): InstrumentsState => state.trade.instruments;

export const selectAllowedInstruments: (store: Store) => Instruments = createDraftSafeSelector(
  selectInstrumentsState,
  selectUserDisabledInstruments,
  (instrumentsState: InstrumentsState, disabledInstruments: Array<string>) => [...Object.values(instrumentsState.instruments)].reduce((acc, _) => {
    if (!disabledInstruments.includes(_.instrument_id)) {
      acc[_.instrument_id] = _;
    }
    return acc;
  }, {}),
);

export const selectInstruments: (store: Store) => Instruments = createDraftSafeSelector(
  selectAllowedInstruments,
  (instruments: Instruments) => instruments,
);

export const selectInstrumentsArray: (store: Store) => Array<ISelectedInstrument> = createDraftSafeSelector(
  selectAllowedInstruments,
  (instruments: Instruments) => Object.values(instruments),
);

export const selectSelectedInstrument: (store: Store) => ISelectedInstrument = createDraftSafeSelector(
  selectInstrumentsState,
  (instrumentsState: InstrumentsState) => instrumentsState.selectedInstrument || { price: {}, } as ISelectedInstrument,
);

export const selectSelectedInstrumentCurrencyPrecision: (store: Store) => (currency: string) => number = createDraftSafeSelector(
  selectSelectedInstrument,
  (instrument: ISelectedInstrument) => (currency: string) => instrument.base_currency_id === currency
  ? instrument.base_currency?.precision || 8
  : instrument.quote_currency?.precision || 8,
);

export const selectConversionExchangeCurrency: (store: Store) => string = createDraftSafeSelector(
  selectInstrumentsState,
  (instrumentsState: InstrumentsState) => instrumentsState.conversionExchangeCurrency,
);

export const selectSelectedInstrumentPrice: (store: Store) => InstrumentPrice = createDraftSafeSelector(
  selectSelectedInstrument,
  (instrumentsState: ISelectedInstrument) => instrumentsState.price,
)
export const selectSelectedInstrumentPriceBar: (store: Store) => InstrumentPriceBar[] = createDraftSafeSelector(
  selectInstrumentsState,
  (instrumentsState: InstrumentsState) => instrumentsState.selectedInstrumentPriceBars,
);

export const selectInstrumentsStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectInstrumentsState,
  (instrumentsState: InstrumentsState): RequestStatus => instrumentsState.instrumentsRequestStatus,
);
export const selectInstrumentsBarsStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectInstrumentsState,
  (instrumentsState: InstrumentsState): RequestStatus => instrumentsState.instrumentsBarsRequestStatus,
);

export const selectInstrumentsPrices: (store: Store) => { [instrument: string]: InstrumentPrice } = createDraftSafeSelector(
  selectInstrumentsArray,
  (instrumentsArray: Array<ISelectedInstrument>) => instrumentsArray
    .reduce((acc, _: ISelectedInstrument) => acc[_.instrument_id] = _.recent_price_bar, {} as any),
);

export const selectInstrumentPriceDecimals: (store: Store) => (currency: string) => number | undefined = createDraftSafeSelector(
  selectAllowedInstruments,
  (instruments: Instruments) => (currency: string) => instruments[currency]?.price_decimals,
);

import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js'

import { Store } from '#/store/defaultState';
import {
  VaultProductWithBalance,
  VaultAccountType,
  RequestStatus,
  BigNumberable,
  VaultDepositWithdrawResponse,
  VaultPendingTransaction,
  VaultHistory,
  VaultBalance,
  VaultProduct,
  ISelectedInstrument,
} from '#/types';
import { toPositiveBigNumber, countAllBalances, toCamelCase } from '#/util';
import { selectInstruments } from '#reducers/trade/instruments';
import { selectUserMarketCurrency } from '#reducers/user/user';
import { makeDefaultBalance } from './helper';

export interface VaultsState {
  balances: Array<VaultBalance>,
  products: Array<VaultProduct>,
  history: Array<VaultHistory>,
  prices: Array<ISelectedInstrument>,
  pendingTransactions: Array<VaultPendingTransaction>,
  userBalanceInUsd: number,
  requestBalancesStatus: RequestStatus,
  requestProductsStatus: RequestStatus,
  requestHistoryStatus: RequestStatus,
  requestPendingTransactions: RequestStatus,
  depositStatus: VaultDepositWithdrawResponse,
  withdrawStatus: VaultDepositWithdrawResponse,
  depositRequestStatus: RequestStatus,
  withdrawRequestStatus: RequestStatus,
  lendingHistoryNextPageExists: boolean
}

export const intitialVaultsState: VaultsState = {
  balances: [],
  products: [],
  history: [],
  prices: [],
  userBalanceInUsd: 0,
  pendingTransactions: [],
  depositStatus: {} as VaultDepositWithdrawResponse,
  withdrawStatus: {} as VaultDepositWithdrawResponse,
  requestPendingTransactions: RequestStatus.None,
  requestBalancesStatus: RequestStatus.None,
  requestProductsStatus: RequestStatus.None,
  requestHistoryStatus: RequestStatus.None,
  depositRequestStatus: RequestStatus.None,
  withdrawRequestStatus: RequestStatus.None,
  lendingHistoryNextPageExists: false,
};

const vaultsSlice = createSlice({
  name: 'vaults',
  initialState: intitialVaultsState,
  reducers: {
    setLendingUserBalance(state, action: PayloadAction<number>) {
      state.userBalanceInUsd = action.payload;
    },
    setLendingHistory(state, action: PayloadAction<Array<VaultHistory>>) {
      state.history = action.payload;
      state.requestHistoryStatus = RequestStatus.Success;
    },
    setLendingProducts(state, action: PayloadAction<Array<VaultProduct>>) {
      state.products = action.payload;
      state.requestProductsStatus = RequestStatus.Success;
    },
    setLendingBalances(state, action: PayloadAction<Array<VaultBalance>>) {
      state.balances = action.payload;
      state.requestBalancesStatus = RequestStatus.Success;
    },
    setLendingPendingTransactions(state, action: PayloadAction<Array<VaultPendingTransaction>>) {
      state.pendingTransactions = action.payload;
      state.requestPendingTransactions = RequestStatus.Success;
    },
    setLendingDeposit(state, action: PayloadAction<VaultDepositWithdrawResponse>) {
      state.depositStatus = action.payload;
      state.depositRequestStatus = RequestStatus.Success;
    },
    setLendingWithdraw(state, action: PayloadAction<VaultDepositWithdrawResponse>) {
      state.withdrawStatus = action.payload;
      state.withdrawRequestStatus = RequestStatus.Success;
    },
    setLendingPrices(state, action: PayloadAction<Array<ISelectedInstrument>>) { // TODO not sure it's needed
      state.prices = action.payload;
    },
    setLendingStatusHistory(state, action: PayloadAction<RequestStatus>) {
      state.requestHistoryStatus = action.payload;
    },
    setLendingStatusProducts(state, action: PayloadAction<RequestStatus>) {
      state.requestProductsStatus = action.payload;
    },
    setLendingStatusBalances(state, action: PayloadAction<RequestStatus>) {
      state.requestBalancesStatus = action.payload;
    },
    setLendingStatusDeposit(state, action: PayloadAction<RequestStatus>) {
      state.depositRequestStatus = action.payload;
    },
    setLendingStatusWithdraw(state, action: PayloadAction<RequestStatus>) {
      state.withdrawRequestStatus = action.payload;
    },
    setLendingStatusPendingTransactions(state, action: PayloadAction<RequestStatus>) {
      state.requestPendingTransactions = action.payload;
    },
    updateLendingHistoryNextPageExist(state, action: PayloadAction<boolean>) {
      state.lendingHistoryNextPageExists = action.payload;
    },
  },
});

export const {
  setLendingUserBalance,
  setLendingHistory,
  setLendingProducts,
  setLendingBalances,
  setLendingPendingTransactions,
  setLendingDeposit,
  setLendingWithdraw,
  setLendingPrices,
  setLendingStatusHistory,
  setLendingStatusProducts,
  setLendingStatusBalances,
  setLendingStatusDeposit,
  setLendingStatusWithdraw,
  setLendingStatusPendingTransactions,
  updateLendingHistoryNextPageExist,
} = vaultsSlice.actions;

export default vaultsSlice.reducer;

export const selectVaultsState = (state: Store): VaultsState => state.vaults;

export const selectVaultProductsAll: (store: Store) => Array<VaultProduct> = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.products,
);

export const selectVaultBalances: (store: Store) => Array<VaultBalance> = createDraftSafeSelector(
  selectVaultsState,
  selectVaultProductsAll,
  (vaultsState: VaultsState, products: Array<VaultProduct>) => (vaultsState.balances || [])
    .filter((balance: VaultBalance) => {
      return products.find((product: VaultProduct) => product.id === balance.productId) && toCamelCase(balance.accountType).toUpperCase() === toCamelCase(VaultAccountType.Principal).toUpperCase()
    }).map((balance) => ({
      ...balance,
      balance: BigNumber.isBigNumber(balance.balance) ? balance.balance : new BigNumber(balance.balance),
    })),
);

export const selectVaultProducts: (store: Store) => Array<VaultProductWithBalance> = createDraftSafeSelector(
  selectVaultsState,
  selectVaultBalances,
  (vaultsState: VaultsState, balances) => vaultsState.products
    .map((product) => {
      const balance: VaultBalance | undefined = (balances || []).find((balance: VaultBalance) => balance.productId === product.id);
      return {
        ...product,
        balance: balance || makeDefaultBalance(product),
      }
    })
);

export const selectVaultBalancesNotZero: (store: Store) => Array<VaultBalance> = createDraftSafeSelector(
  selectVaultBalances,
  (vaultsState: Array<VaultBalance>) => vaultsState.filter((_) => (_ && BigNumber.isBigNumber(_.balance) ? _.balance.toNumber() > 0 : Number(_.balance) > 0)),
);

export const selectVaultProductsWithBalance: (store: Store) => Array<VaultProduct> = createDraftSafeSelector(
  selectVaultProducts,
  selectVaultBalances,
  (products: Array<VaultProduct>, balances: Array<VaultBalance>) => (products || []).filter((product: VaultProduct) => {
    const balance = balances.find((_balance: VaultBalance) => _balance.productId === product.id);

    return balance && BigNumber.isBigNumber(balance?.balance) ? balance.balance.toNumber() > 0 : Number(balance?.balance || 0) > 0;
  }),
);

export const selectVaultHistory: (store: Store) => Array<VaultHistory> = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.history,
);

export const selectVaultPendingTransactions: (store: Store) => Array<VaultPendingTransaction> = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.pendingTransactions,
);

export const selectVaultDepositStatus: (store: Store) => VaultDepositWithdrawResponse = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.depositStatus,
);

export const selectVaultWithdrawStatus: (store: Store) => VaultDepositWithdrawResponse = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.withdrawStatus,
);

export const selectVaultDepositRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.depositRequestStatus,
);

export const selectVaultWithdrawRequestStatus: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.withdrawRequestStatus,
);

export const selectVaultRequestStatuses: (store: Store) => { [name: string]: RequestStatus }  = createDraftSafeSelector(
  selectVaultsState,
  (vaultsState: VaultsState) => ({
    requestBalancesStatus: vaultsState.requestBalancesStatus,
    requestProductsStatus: vaultsState.requestProductsStatus,
    requestHistoryStatus: vaultsState.requestHistoryStatus,
    depositRequestStatus: vaultsState.depositRequestStatus,
    withdrawRequestStatus: vaultsState.withdrawRequestStatus,
  }),
);

export const selectVaultsLoading: (store: Store) => boolean = createDraftSafeSelector(
  selectVaultRequestStatuses,
  (vaultsRequests: { [name: string]: RequestStatus }) => [vaultsRequests.requestBalancesStatus, vaultsRequests.requestProductsStatus].includes(RequestStatus.Pending),
);

export const selectVaultsBalanceTotal: (store: Store) => any = createDraftSafeSelector(
  selectVaultBalances,
  selectInstruments,
  selectUserMarketCurrency,
  (balances: Array<VaultBalance>, instruments: { [name: string]: ISelectedInstrument }, marketCurrency: string) => {
    return countAllBalances(
      balances,
      instruments,
      marketCurrency,
    );
  },
)

export const selectVaultPrecision = (productId: string) => (state: Store) => {
  const defaultVaultsPrecision = 8; // TODO

  return state.vaults.products[productId] && state.vaults.products[productId].decimals > defaultVaultsPrecision
    ? state.vaults.products[productId].decimals
    : defaultVaultsPrecision;
};

export const selectVaultAmountFixedPrecision = (balance: BigNumberable, productId: string) => (state: Store) => {
  const precision = selectVaultPrecision(productId)(state);

  return toPositiveBigNumber(balance).toFixed(precision, 1);
};

export const selectLendingHistoryNextPageExists: (store: Store) => boolean = createDraftSafeSelector (
  selectVaultsState,
  (vaultsState: VaultsState) => vaultsState.lendingHistoryNextPageExists,
);

import { ICurrencyState } from './interface';
import { RequestStatus } from '#/types/enums';

export const initialCurrencyState: ICurrencyState = {
  currencies: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  currenciesById: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },
};

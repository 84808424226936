import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { SignInOpts } from '@aws-amplify/auth/lib-esm/types';
import { useSelector } from 'react-redux';
import { selectSsoSettings } from '#reducers/config';
import { SsoAuthTypes } from '#reducers/settings';
import { useAuth } from '#/hooks/auth/useAuth';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { isEmail } from '#/util';

export const useCognito = () => {
  const ssoSettings = useSelector(selectSsoSettings);
  const isCognito = ssoSettings && ssoSettings.type === SsoAuthTypes.Cognito && ssoSettings.domain;
  const [isAuthWithPassword, setIsAuthWithPassword] = useState<boolean>();
  const { isAuth } = useAuth();

  const checkAuthWithPassword = async () => {
    try {
      const session: any = await Auth.currentSession();
      setIsAuthWithPassword(!session?.idToken?.payload?.at_hash);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isAuth) {
      checkAuthWithPassword();
    }
  }, []);

  const handleSignUp = async (formData: SignInOpts) => {
    return Auth.signUp({
      ...formData,
      autoSignIn: {
        enabled: true,
      },
    });
  };

  const handleForgotPassword = async (email: string) => {
    try {
      const userData = await Auth.forgotPassword(email);
      novaToast.info(_t(
        'We Emailed You. Your code is on the way. To log in, enter the code we sent to email. It may take a minute to arrive.',
        'TOAST.FORGOT_PASSWORD_SUCCESS',
      ),
        undefined,
        undefined, 
        'transform-none');
      return userData;
    } catch (error) {
      throw error;
    }
  }

  const handleSignIn = async (formData: SignInOpts) => {
    return Auth.signIn({
      ...formData,
    })
  }

  const validateCustomSignUp = async (formData: SignInOpts) => {
    if (formData.username && !isEmail(formData.username)) {
      return {
        username: '' //custom error message can be configured (currently we use tooltips to display errors)
      }
    }
  }

  return {
    isCognito,
    handleSignUp,
    handleSignIn,
    handleForgotPassword,
    isAuthWithPassword,
    validateCustomSignUp,
  }
}

import { currency as currencyApi } from './currency';
import { fee as feeApi } from './fee';
import { payment as paymentApi } from './payment';
import { user as userApi } from './user';
import { webhook as webhookApi } from './webhook';

import { IPrepareFilterData } from '#/nova/components/views/crypto-pay/transactionsTab/types';

import {
  ICreateFeeGroupRequest,
  IUpdateFeeGroupRequest,
  IAddSubUserToFeeGroupRequest,
  IUpdateSubUserFeeGroupRequest,
  IDeleteSubUserFromFeeGroupRequest,
  ICreatePaymentRequest,
  IUploadLogoRequest,
  ICreateChildUserRequest,
  IUpdateWidgetSettingsRequest,
  IUpdateCurrencySettingsRequest,
  IUpdatePreferredCurrencyRequest,
  IUpdateCryptoConfirmationsRequest,
  IAddWebhookRequest,
} from './interface';

export default class CryptoPayApi {
  getCurrencies() {
    return currencyApi.getCurrencies();
  }

  getCurrenciesById(id: string) {
    return currencyApi.getCurrenciesById(id);
  }

  // ---

  getPaymentFeesForPayment(paymentId: string) {
    return feeApi.getPaymentFeesForPayment(paymentId);
  }

  getFeeGroupsForUser() {
    return feeApi.getFeeGroupsForUser();
  }

  getFeeGroupByName(groupName: string) {
    return feeApi.getFeeGroupByName(groupName);
  }

  getPaymentFeesForAllPayments() {
    return feeApi.getPaymentFeesForAllPayments();
  }

  createFeeGroup(body: ICreateFeeGroupRequest) {
    return feeApi.createFeeGroup(body);
  }

  updateFeeGroup(body: IUpdateFeeGroupRequest) {
    return feeApi.updateFeeGroup(body);
  }

  deleteFeeGroupByName(groupName: string) {
    return feeApi.deleteFeeGroupByName(groupName);
  }

  addSubUserToFeeGroup(body: IAddSubUserToFeeGroupRequest) {
    return feeApi.addSubUserToFeeGroup(body);
  }

  updateSubUserFeeGroup(body: IUpdateSubUserFeeGroupRequest) {
    return feeApi.updateSubUserFeeGroup(body);
  }

  deleteSubUserFromFeeGroup(body: IDeleteSubUserFromFeeGroupRequest) {
    return feeApi.deleteSubUserFromFeeGroup(body);
  }

  // ---

  getPayments(filterData?: IPrepareFilterData | undefined) {
    return paymentApi.getPayments(filterData);
  }

  getPaymentAddresses(id: string) {
    return paymentApi.getPaymentAddresses(id);
  }

  createPayment(body: ICreatePaymentRequest) {
    return paymentApi.createPayment(body);
  }

  // ---

  getSettings() {
    return userApi.getSettings();
  }

  getChildUsers() {
    return userApi.getChildUsers();
  }

  uploadLogo(body: IUploadLogoRequest) {
    return userApi.uploadLogo(body);
  }

  checkUser() {
    return userApi.checkUser();
  }

  createChildUser(body: ICreateChildUserRequest) {
    return userApi.createChildUser(body);
  }

  updateChildUser(id: string) {
    return userApi.updateChildUser(id);
  }

  updateWidgetSettings(body: IUpdateWidgetSettingsRequest) {
    return userApi.updateWidgetSettings(body);
  }

  updateCurrencySettings(body: IUpdateCurrencySettingsRequest) {
    return userApi.updateCurrencySettings(body);
  }

  updatePreferredCurrency(body: IUpdatePreferredCurrencyRequest) {
    return userApi.updatePreferredCurrency(body);
  }

  updateCryptoConfirmations(body: IUpdateCryptoConfirmationsRequest) {
    return userApi.updateCryptoConfirmations(body);
  }

  // ---

  getWebhooks() {
    return webhookApi.getWebhooks();
  }

  addWebhook(body: IAddWebhookRequest) {
    return webhookApi.addWebhook(body);
  }

  deleteWebhookById(id: string) {
    return webhookApi.deleteWebhookById(id);
  }

  updateWebhookById(id: string) {
    return webhookApi.updateWebhookById(id);
  }
}

/**
 * @name capitalize
 *
 * @description Convert first letter of each words to uppercase
 *
 * @param {String} str
 * @returns {String}
 */

const capitalize = (str: string, forceLowercase: boolean = false) => {
  let capStr = forceLowercase ? str.toLowerCase() : str;
  return capStr.replace(/\w\S*/g, match => match.charAt(0).toUpperCase() + match.slice(1));
};

export default capitalize;

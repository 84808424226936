import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initializeAmplify from '#/aws-amplify.setup';
import { subscribeCognitoAuth } from '#reducers/user/user';
import { selectSsoSettings } from '#reducers/config';
import { SsoAuthTypes } from '#reducers/settings';

export const useAmplify = () => {
  const dispatch = useDispatch();
  const ssoSettings = useSelector(selectSsoSettings);
  const isCognito = ssoSettings && ssoSettings.type === SsoAuthTypes.Cognito && ssoSettings.domain;

  useEffect(() => {
    if (isCognito) {
      initializeAmplify(ssoSettings.domain, ssoSettings.client_id, ssoSettings.pool_id);
      setTimeout(() => {
        dispatch(subscribeCognitoAuth());
      }, 200);
    };
  }, [ssoSettings]);

  return {
    isCognito,
  }
}

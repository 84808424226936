export interface IHealthCheckResponse {
  maintenance_message: string,
  maintenance_mode: boolean,
}

export interface IKycData {
  kyc_property: string,
  kyc_value: string
}

export interface CreateKycForm {
  userId: string,
  kycData?: IKycData[],
}

export interface UploadKycDocument {
  documentType: KycDocumentsTypes,
  file: File,
}

export enum KycDocumentsTypes {
  CompanyRegistration = 'company_registration',
  ClientIdFront = 'proof_of_id_front',
  ClientIdBack = 'proof_of_id_back',
  ClientAddress = 'proof_of_residence',
  MemorandumOfAssociation = 'memorandum_of_association',
}

export interface IGeoRestrictionsResponse {
  region_blacklist: string[],
  country_blacklist: Array<{ code: string; name: string | null }>,
  current_geo: { country: string },
}
import { createAsyncThunk } from '@reduxjs/toolkit';

import CryptoPayApi from '#/api/cryptoPay';
import Config from '#/config/config';

import { novaToast } from '#/nova/components/other/toast/novaToast';

const {
  cryptoPayConfig: { failRequestMessage, successRequestMessage },
} = Config;

export const getSettings = createAsyncThunk(
  'user/getSettings',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getSettings();

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Settings - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const getChildUsers = createAsyncThunk(
  'user/getChildUsers',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getChildUsers();

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Child Users - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const uploadLogo = createAsyncThunk('user/uploadLogo', async (data: any, { rejectWithValue, extra }: any) => {
  try {
    const response = await (extra.cryptoPayApi as CryptoPayApi).uploadLogo(data);

    if (!response.ok) {
      const isCurrentCode = [400].includes(response.status);
      const json = isCurrentCode ? await response.json() : {};

      novaToast.error(
        `Code: ${response.status}. Upload Logo - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
      );
      return rejectWithValue({ code: response.status, ...json });
    }

    novaToast.success(`Upload Logo - Success: ${successRequestMessage}`);
    return await response.json();
  } catch (error) {
    novaToast.error('error');
    return rejectWithValue(error);
  }
});

export const checkUser = createAsyncThunk('user/checkUser', async (_: undefined, { rejectWithValue, extra }: any) => {
  try {
    const response = await (extra.cryptoPayApi as CryptoPayApi).checkUser();

    if (!response.ok) {
      const isCurrentCode = [400].includes(response.status);
      const json = isCurrentCode ? await response.json() : {};

      novaToast.error(
        `Code: ${response.status}. Check User - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
      );
      return rejectWithValue({ code: response.status, ...json });
    }

    return await response.json();
  } catch (error) {
    novaToast.error('error');
    return rejectWithValue(error);
  }
});

export const createChildUser = createAsyncThunk(
  'user/createChildUser',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).createChildUser(data);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Create Child User - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getChildUsers());

      novaToast.success(`Create Child User - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateChildUser = createAsyncThunk(
  'user/updateChildUser',
  async (id: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateChildUser(id);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Child User - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      novaToast.success(`Updated Child User - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateWidgetSettings = createAsyncThunk(
  'user/updateWidgetSettings',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateWidgetSettings(data);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Widget Settings - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getSettings());

      novaToast.success(`Updated Widget Settings - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateCurrencySettings = createAsyncThunk(
  'user/updateCurrencySettings',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateCurrencySettings(data);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Currency Settings - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getSettings());

      novaToast.success(`Updated Currency Settings - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updatePreferredCurrency = createAsyncThunk(
  'user/updatePreferredCurrency',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updatePreferredCurrency(data.preferedCurrency);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Preferred Currency - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(updateCurrencySettings({ currency_settings: data.currencySettings }));

      novaToast.success(`Updated Preferred Currency - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateCryptoConfirmations = createAsyncThunk(
  'user/updateCryptoConfirmations',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateCryptoConfirmations(data);

      if (!response.ok) {
        const isCurrentCode = [400].includes(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Crypto Confirmations - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getSettings());

      novaToast.success(`Updated Crypto Confirmations - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

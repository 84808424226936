export const getTwoFaErrorMessages = (twoFaCode: string) => {
  const fieldName = 'Authentication Code';
  const fieldLength = 6;
  const onlyDigitsRegex = new RegExp('^[0-9]*$');

  const errors = {
    onlyDigits: _t('{field} can only contain digits', 'ERRORS.ONLY_DIGITS', { field: fieldName }),
    minLength: _t(`{field} should contain {amount} digits`, 'ERRORS.DIGITS_EXACT_LENGTH', { amount: fieldLength, field: fieldName }),
  };

  const errorMessages = [
    !onlyDigitsRegex.test(twoFaCode) ? errors.onlyDigits : '',
    twoFaCode.length !== fieldLength ? errors.minLength : ''
  ].filter(_ => !!_);

  return errorMessages;
};

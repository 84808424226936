import { RequestStatus } from '#/types/enums';
import { getTimeZoneSettings } from '#/util/date/getTimeZoneSettings';
import { TimeZoneName, TimeZoneTypes } from '#/util/date/types';
import { PortfolioHistoryState } from './types';

export const initialPortfolioHistoryState: PortfolioHistoryState = {
  portfolioHistory: [],
  portfolio24h: [],
  portfolio24hRequestStatus: RequestStatus.None,
  portfolioHistoryRequestStatus: RequestStatus.None,
}

export const getUserExtendedTimezone = () => {
  /* get user's selected IANA timezone 'Europe/Kyiv' */
  const { timeZone } = getTimeZoneSettings();

  /* get user timezone offset in format 'UTC+02:00' */
  const timeZoneOffset = new Intl.DateTimeFormat('en', {
    timeZoneName: TimeZoneTypes.LongOffset,
    timeZone
  }).formatToParts()
    .filter(el => el.type === TimeZoneName)[0].value
    .replace('GMT', 'UTC');

  return `${timeZone} ${timeZoneOffset}`
};
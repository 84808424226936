import { Networks } from '#/types';
import { validate as multiAddressValidator } from 'multicoin-address-validator';
// @ts-ignore
import currencies from 'multicoin-address-validator/src/currencies';

export const validWalletAddress = (value: string, currency: string, network?: string | undefined) => {
  const destination = '?dt';
  const addressWithoutDestination = value.split(destination)[0];
  const error = _t('Invalid wallet address', 'WRONG_WALLET_ADDRESS');
  const isCurrencySupported = !!currencies.getByNameOrSymbol(currency);
  const isNetworkSupported = !!currencies.getByNameOrSymbol(network || '');

  // @todo what to do if currency not found in lib ?
  if ((!isCurrencySupported && !isNetworkSupported) || network === Networks.Tron) {
    return {
      name: 'invalidWalletAddress',
      valid: true,
    };
  }

  const checkAddress = (currencyOrNetwork: string = ''): boolean => {
    return multiAddressValidator(addressWithoutDestination, currencyOrNetwork.toLowerCase())
      || multiAddressValidator(addressWithoutDestination, currencyOrNetwork.toLowerCase(), 'testnet')
  };

  const isAddressValidBy = {
    currency: isCurrencySupported && checkAddress(currency),
    network: isNetworkSupported && checkAddress(network),
  };
  // TODO: Find correct way to manage dev/prod environment
  return isAddressValidBy.currency || isAddressValidBy.network
    ? { valid: true }
    : { valid: false, error };
}

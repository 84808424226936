import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';
import { initialFeeState } from './initialState';

import {
  getPaymentFeesForAllPayments,
  getPaymentFeesForPayment,
  getFeeGroupsForUser,
  getFeeGroupByName,
  createFeeGroup,
  updateFeeGroup,
  deleteFeeGroupByName,
  addSubUserToFeeGroup,
  updateSubUserFeeGroup,
  deleteSubUserFromFeeGroup,
} from '#/store/reducers/cryptoPay/thunks/fee';

const feeSlice = createSlice({
  name: 'fee',
  initialState: initialFeeState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentFeesForAllPayments.pending, (state) => {
        state.paymentFeesForAllPayments.status = RequestStatus.Pending;
        state.paymentFeesForAllPayments.error.code = null;
        state.paymentFeesForAllPayments.error.message = null;
      })
      .addCase(getPaymentFeesForAllPayments.fulfilled, (state, action) => {
        state.paymentFeesForAllPayments.status = RequestStatus.Success;
        state.paymentFeesForAllPayments.items = action.payload.results;
      })
      .addCase(getPaymentFeesForAllPayments.rejected, (state, action: any) => {
        state.paymentFeesForAllPayments.status = RequestStatus.Failed;
        state.paymentFeesForAllPayments.error.code = action.payload.code;
        state.paymentFeesForAllPayments.error.message = action.payload.message;
      });

    builder
      .addCase(getPaymentFeesForPayment.pending, (state) => {
        state.paymentFeesForPayment.status = RequestStatus.Pending;
        state.paymentFeesForPayment.error.code = null;
        state.paymentFeesForPayment.error.message = null;
      })
      .addCase(getPaymentFeesForPayment.fulfilled, (state, action) => {
        state.paymentFeesForPayment.status = RequestStatus.Success;
        state.paymentFeesForPayment.items = action.payload.payment_fees;
      })
      .addCase(getPaymentFeesForPayment.rejected, (state, action: any) => {
        state.paymentFeesForPayment.status = RequestStatus.Failed;
        state.paymentFeesForPayment.error.code = action.payload.code;
        state.paymentFeesForPayment.error.message = action.payload.message;
      });

    builder
      .addCase(getFeeGroupsForUser.pending, (state) => {
        state.feeGroupsForUser.status = RequestStatus.Pending;
        state.feeGroupsForUser.error.code = null;
        state.feeGroupsForUser.error.message = null;
      })
      .addCase(getFeeGroupsForUser.fulfilled, (state, action) => {
        state.feeGroupsForUser.status = RequestStatus.Success;
        state.feeGroupsForUser.items = action.payload.fee_groups;
      })
      .addCase(getFeeGroupsForUser.rejected, (state, action: any) => {
        state.feeGroupsForUser.status = RequestStatus.Failed;
        state.feeGroupsForUser.error.code = action.payload.code;
        state.feeGroupsForUser.error.message = action.payload.message;
      });

    builder
      .addCase(getFeeGroupByName.pending, (state) => {
        state.feeGroupByName.status = RequestStatus.Pending;
        state.feeGroupByName.error.code = null;
        state.feeGroupByName.error.message = null;
      })
      .addCase(getFeeGroupByName.fulfilled, (state, action) => {
        state.feeGroupByName.status = RequestStatus.Success;
        state.feeGroupByName.item = action.payload.fee_group;
      })
      .addCase(getFeeGroupByName.rejected, (state, action: any) => {
        state.feeGroupByName.status = RequestStatus.Failed;
        state.feeGroupByName.error.code = action.payload.code;
        state.feeGroupByName.error.message = action.payload.message;
      });

    builder
      .addCase(createFeeGroup.pending, (state) => {
        state.createFeeGroup.status = RequestStatus.Pending;
        state.createFeeGroup.error.code = null;
        state.createFeeGroup.error.message = null;
      })
      .addCase(createFeeGroup.fulfilled, (state) => {
        state.createFeeGroup.status = RequestStatus.Success;
      })
      .addCase(createFeeGroup.rejected, (state, action: any) => {
        state.createFeeGroup.status = RequestStatus.Failed;
        state.createFeeGroup.error.code = action.payload.code;
        state.createFeeGroup.error.message = action.payload.message;
      });

    builder
      .addCase(updateFeeGroup.pending, (state) => {
        state.updateFeeGroup.status = RequestStatus.Pending;
        state.updateFeeGroup.error.code = null;
        state.updateFeeGroup.error.message = null;
      })
      .addCase(updateFeeGroup.fulfilled, (state) => {
        state.updateFeeGroup.status = RequestStatus.Success;
      })
      .addCase(updateFeeGroup.rejected, (state, action: any) => {
        state.updateFeeGroup.status = RequestStatus.Failed;
        state.updateFeeGroup.error.code = action.payload.code;
        state.updateFeeGroup.error.message = action.payload.message;
      });

    builder
      .addCase(deleteFeeGroupByName.pending, (state) => {
        state.deleteFeeGroupByName.status = RequestStatus.Pending;
        state.deleteFeeGroupByName.error.code = null;
        state.deleteFeeGroupByName.error.message = null;
      })
      .addCase(deleteFeeGroupByName.fulfilled, (state) => {
        state.deleteFeeGroupByName.status = RequestStatus.Success;
      })
      .addCase(deleteFeeGroupByName.rejected, (state, action: any) => {
        state.deleteFeeGroupByName.status = RequestStatus.Failed;
        state.deleteFeeGroupByName.error.code = action.payload.code;
        state.deleteFeeGroupByName.error.message = action.payload.message;
      });

    builder
      .addCase(addSubUserToFeeGroup.pending, (state) => {
        state.addSubUserToFeeGroup.status = RequestStatus.Pending;
        state.addSubUserToFeeGroup.error.code = null;
        state.addSubUserToFeeGroup.error.message = null;
      })
      .addCase(addSubUserToFeeGroup.fulfilled, (state) => {
        state.addSubUserToFeeGroup.status = RequestStatus.Success;
      })
      .addCase(addSubUserToFeeGroup.rejected, (state, action: any) => {
        state.addSubUserToFeeGroup.status = RequestStatus.Failed;
        state.addSubUserToFeeGroup.error.code = action.payload.code;
        state.addSubUserToFeeGroup.error.message = action.payload.message;
      });

    builder
      .addCase(updateSubUserFeeGroup.pending, (state) => {
        state.updateSubUserFeeGroup.status = RequestStatus.Pending;
        state.updateSubUserFeeGroup.error.code = null;
        state.updateSubUserFeeGroup.error.message = null;
      })
      .addCase(updateSubUserFeeGroup.fulfilled, (state) => {
        state.updateSubUserFeeGroup.status = RequestStatus.Success;
      })
      .addCase(updateSubUserFeeGroup.rejected, (state, action: any) => {
        state.updateSubUserFeeGroup.status = RequestStatus.Failed;
        state.updateSubUserFeeGroup.error.code = action.payload.code;
        state.updateSubUserFeeGroup.error.message = action.payload.message;
      });

    builder
      .addCase(deleteSubUserFromFeeGroup.pending, (state) => {
        state.deleteSubUserFromFeeGroup.status = RequestStatus.Pending;
        state.deleteSubUserFromFeeGroup.error.code = null;
        state.deleteSubUserFromFeeGroup.error.message = null;
      })
      .addCase(deleteSubUserFromFeeGroup.fulfilled, (state) => {
        state.deleteSubUserFromFeeGroup.status = RequestStatus.Success;
      })
      .addCase(deleteSubUserFromFeeGroup.rejected, (state, action: any) => {
        state.deleteSubUserFromFeeGroup.status = RequestStatus.Failed;
        state.deleteSubUserFromFeeGroup.error.code = action.payload.code;
        state.deleteSubUserFromFeeGroup.error.message = action.payload.message;
      });
  },
});

export default feeSlice.reducer;

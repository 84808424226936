import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setDepositWithdrawModalOpen,
  setDepositWithdrawModalActiveSide,
  setDepositWithdrawModalCurrency,
  setDepositWithdrawModalPaymentRoute,
  selectDepositWithdrawModalOpen,
  selectDepositWithdrawModalActiveSide,
  selectCurrency,
  selectPaymentRoute,
  selectIsVerificationRequired,
  setIsWithdrawConfirmShown,
  selectIsWithdrawConfirmShown,
} from '#/store/reducers/wallets/deposit-withdraw-modal';
import { DepositWithdraw } from '#/store/reducers/trade/enums';
import { PaymentRoute } from '#/store/reducers/trade/interfaces';

const useDepositWithdrawModal = () => {
  const dispatch = useDispatch();

  const isOpenModal = useSelector(selectDepositWithdrawModalOpen);
  const activeSide = useSelector(selectDepositWithdrawModalActiveSide);
  const selectedCurrency = useSelector(selectCurrency);
  const selectedPaymentRoute = useSelector(selectPaymentRoute);
  const isVerificationRequired = useSelector(selectIsVerificationRequired);
  const isWithdrawConfirmShown = useSelector(selectIsWithdrawConfirmShown);

  const onSetPaymentRoute = (paymentRoute?: PaymentRoute) => {
    dispatch(setDepositWithdrawModalPaymentRoute(paymentRoute));
  };

  const onToggleModal = (data: boolean) => {
    dispatch(setDepositWithdrawModalOpen(data));
    if (!data) {
      onSetPaymentRoute();
    }
  };

  const onSetActiveSide = (activeSide: string) => {
    dispatch(setDepositWithdrawModalActiveSide(activeSide as DepositWithdraw));
  };

  const onSetCurrency = (currency: string) => {
    dispatch(setDepositWithdrawModalCurrency(currency));
  };

  const setWithdrawConfirmShown = (isShown: boolean) => {
    dispatch(setIsWithdrawConfirmShown(isShown))
  }

  return {
    isOpenModal,
    activeSide,
    selectedCurrency,
    selectedPaymentRoute,
    isVerificationRequired,
    isWithdrawConfirmShown,
    onToggleModal: useCallback(onToggleModal, []),
    onSetActiveSide: useCallback(onSetActiveSide, []),
    onSetCurrency: useCallback(onSetCurrency, []),
    onSetPaymentRoute: useCallback(onSetPaymentRoute, []),
    setWithdrawConfirmShown: useCallback(setWithdrawConfirmShown, []),
  };
};

export default useDepositWithdrawModal;

import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';
import { initialUserState } from './initialState';

import {
  getSettings,
  getChildUsers,
  uploadLogo,
  checkUser,
  createChildUser,
  updateChildUser,
  updateWidgetSettings,
  updateCurrencySettings,
  updatePreferredCurrency,
  updateCryptoConfirmations,
} from '#/store/reducers/cryptoPay/thunks/user';

const webhookSlice = createSlice({
  name: 'webhook',
  initialState: initialUserState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.settings.status = RequestStatus.Pending;
        state.settings.error.code = null;
        state.settings.error.message = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.settings.status = RequestStatus.Success;
        state.settings.currencySettings = action.payload.currency_settings;
        state.settings.widgetSettings = action.payload.widget_settings;
        state.settings.label = action.payload.label;
      })
      .addCase(getSettings.rejected, (state, action: any) => {
        state.settings.status = RequestStatus.Failed;
        state.settings.error.code = action.payload.code;
        state.settings.error.message = action.payload.message;
      });

    builder
      .addCase(getChildUsers.pending, (state) => {
        state.childUsers.status = RequestStatus.Pending;
        state.childUsers.error.code = null;
        state.childUsers.error.message = null;
      })
      .addCase(getChildUsers.fulfilled, (state, action) => {
        state.childUsers.status = RequestStatus.Success;
        state.childUsers.items = action.payload.results;
      })
      .addCase(getChildUsers.rejected, (state, action: any) => {
        state.childUsers.status = RequestStatus.Failed;
        state.childUsers.error.code = action.payload.code;
        state.childUsers.error.message = action.payload.message;
      });

    builder
      .addCase(createChildUser.pending, (state) => {
        state.createChildUser.status = RequestStatus.Pending;
        state.createChildUser.error.code = null;
        state.createChildUser.error.message = null;
      })
      .addCase(createChildUser.fulfilled, (state) => {
        state.createChildUser.status = RequestStatus.Success;
      })
      .addCase(createChildUser.rejected, (state, action: any) => {
        state.createChildUser.status = RequestStatus.Failed;
        state.createChildUser.error.code = action.payload.code;
        state.createChildUser.error.message = action.payload.message;
      });

    builder
      .addCase(uploadLogo.pending, (state) => {
        state.uploadLogo.status = RequestStatus.Pending;
        state.uploadLogo.error.code = null;
        state.uploadLogo.error.message = null;
      })
      .addCase(uploadLogo.fulfilled, (state, action) => {
        state.uploadLogo.status = RequestStatus.Success;
        state.uploadLogo.url = action.payload.url;
      })
      .addCase(uploadLogo.rejected, (state, action: any) => {
        state.uploadLogo.status = RequestStatus.Failed;
        state.uploadLogo.error.code = action.payload.code;
        state.uploadLogo.error.message = action.payload.message;
      });

    builder
      .addCase(checkUser.pending, (state) => {
        state.checkUser.status = RequestStatus.Pending;
        state.checkUser.error.code = null;
        state.checkUser.error.message = null;
      })
      .addCase(checkUser.fulfilled, (state) => {
        state.checkUser.status = RequestStatus.Success;
      })
      .addCase(checkUser.rejected, (state, action: any) => {
        state.checkUser.status = RequestStatus.Failed;
        state.checkUser.error.code = action.payload.code;
        state.checkUser.error.message = action.payload.message;
      });

    builder
      .addCase(updateChildUser.pending, (state) => {
        state.updateChildUser.status = RequestStatus.Pending;
        state.updateChildUser.error.code = null;
        state.updateChildUser.error.message = null;
      })
      .addCase(updateChildUser.fulfilled, (state) => {
        state.updateChildUser.status = RequestStatus.Success;
      })
      .addCase(updateChildUser.rejected, (state, action: any) => {
        state.updateChildUser.status = RequestStatus.Failed;
        state.updateChildUser.error.code = action.payload.code;
        state.updateChildUser.error.message = action.payload.message;
      });

    builder
      .addCase(updateWidgetSettings.pending, (state) => {
        state.updateWidgetSettings.status = RequestStatus.Pending;
        state.updateWidgetSettings.error.code = null;
        state.updateWidgetSettings.error.message = null;
      })
      .addCase(updateWidgetSettings.fulfilled, (state) => {
        state.updateWidgetSettings.status = RequestStatus.Success;
      })
      .addCase(updateWidgetSettings.rejected, (state, action: any) => {
        state.updateWidgetSettings.status = RequestStatus.Failed;
        state.updateWidgetSettings.error.code = action.payload.code;
        state.updateWidgetSettings.error.message = action.payload.message;
      });

    builder
      .addCase(updateCurrencySettings.pending, (state) => {
        state.updateCurrencySettings.status = RequestStatus.Pending;
        state.updateCurrencySettings.error.code = null;
        state.updateCurrencySettings.error.message = null;
      })
      .addCase(updateCurrencySettings.fulfilled, (state) => {
        state.updateCurrencySettings.status = RequestStatus.Success;
      })
      .addCase(updateCurrencySettings.rejected, (state, action: any) => {
        state.updateCurrencySettings.status = RequestStatus.Failed;
        state.updateCurrencySettings.error.code = action.payload.code;
        state.updateCurrencySettings.error.message = action.payload.message;
      });

    builder
      .addCase(updatePreferredCurrency.pending, (state) => {
        state.updatePreferredCurrency.status = RequestStatus.Pending;
        state.updatePreferredCurrency.error.code = null;
        state.updatePreferredCurrency.error.message = null;
      })
      .addCase(updatePreferredCurrency.fulfilled, (state) => {
        state.updatePreferredCurrency.status = RequestStatus.Success;
      })
      .addCase(updatePreferredCurrency.rejected, (state, action: any) => {
        state.updatePreferredCurrency.status = RequestStatus.Failed;
        state.updatePreferredCurrency.error.code = action.payload.code;
        state.updatePreferredCurrency.error.message = action.payload.message;
      });

    builder
      .addCase(updateCryptoConfirmations.pending, (state) => {
        state.updateCryptoConfirmations.status = RequestStatus.Pending;
        state.updateCryptoConfirmations.error.code = null;
        state.updateCryptoConfirmations.error.message = null;
      })
      .addCase(updateCryptoConfirmations.fulfilled, (state) => {
        state.updateCryptoConfirmations.status = RequestStatus.Success;
      })
      .addCase(updateCryptoConfirmations.rejected, (state, action: any) => {
        state.updateCryptoConfirmations.status = RequestStatus.Failed;
        state.updateCryptoConfirmations.error.code = action.payload.code;
        state.updateCryptoConfirmations.error.message = action.payload.message;
      });
  },
});

export default webhookSlice.reducer;

import { Dispatch } from 'react';

import { NotificationsOptions, RequestStatus } from '#/types/enums';
import { FavoriteFiatDestination, UserEventResult, FavoriteAddressCrypto, PaymentProperties } from '#/types/interfaces';
import { DocumentTypes } from '#/api/user/dto';
// import { IsActive } from '#/types';
import { KycProviders, UserKycStatus } from '#reducers/kyc/types';
import { IKycInfoFields } from '#reducers/kyc';

export enum UserLanguages {
  English = 'english',
  Chinese = 'chinese',
}

export enum UserMarketCurrencies {
  USD = 'USD',
  BTC = 'BTC',
  ETH = 'ETH',
}

export enum IsActive {
  Off = 'off',
  On = 'on',
}

export type SubscriberUserEvent = {
  id: string,
  callback: (event: UserEventResult, dispatch: Dispatch<any>) => void
};

export interface UserEventSubscribers  { [id: string]: SubscriberUserEvent }

export interface IKycGetResponse {
  user_id?: string,
  updated_at?: string,
  kycProperties?: string,
  kyc_property?: string,
  kyc_value: string | string[],
}

export interface UserInfo {
  username: string,
  language: UserLanguages,
  timezone: string,
  email: string,
  crypto_pay: IsActive,
  primary_market_currency: UserMarketCurrencies,
  favorite_instruments: Array<string>,
  favorite_addresses_crypto: Array<FavoriteAddressCrypto>,
  favorite_fiat_destinations: Array<FavoriteFiatDestination> & PaymentProperties,
  mfa_status: IsActive,
  profile_pic_url: string | undefined, // path to image
  notifications_options: NotificationsOptions | {},
  user_id: string,
  kyc_data: IKycGetResponse[],
  userKycInfo: IKycInfoFields,
  userCounrty: string,
  default_timezone?: IsActive,
  anti_phishing_code?: string,
  fee_group_id: string,
}

export interface AuthStorageUserState {
  accessToken: string | undefined;
  tokenExpiresAt: string;
  authStatus: RequestStatus;
  authErrorMessage: string | undefined;
  refreshToken: string;
  isTokenRefreshing: boolean;
}

export interface User extends AuthStorageUserState {
  userInfo: UserInfo,
  uploadDocumentStatus: RequestStatus,
  getUserStatus: RequestStatus,
  updateUserStatus: RequestStatus,
  changePasswordStatus: RequestStatus,
  verifyTwoFaRequest: RequestStatus | 1,
  userEventSubscribers: UserEventSubscribers,
  permissions: Array<string>,
  twoFa: string,
  twoFaVerifyMessage: string,
  isInactive: boolean,
  changePasswordError: string,
  updateAntiPhishingStatus: RequestStatus,
}

export interface UploadDocumentResult {
  document_type: DocumentTypes,
  original_name: string,
  content_type: string,
  s3_location: string,
  s3_public_url: string,
  size: number,
  created_at: string,
  message?: string,
  // TODO
}

export interface UserKycDoc {
  proof_of_residence_url: string,
  proof_of_id_front_url: string,
  company_registration_url: string,
  memorandum_of_association_url: string,
}

export enum BuildKycData {
  Kyc_property = "kyc_property",
  Kyc_value = "kyc_value",
}

export enum ConversionStatus {
  New = 'new',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Rejected = 'rejected',
  Expired = 'expired',
  Completed = 'completed',
}

export interface IKycProvidersGetResponse {
  individual: {
    enabled: boolean,
    provider_url: string,
    provider: KycProviders
  },
  corporate: {
    enabled: boolean,
    provider_url: string,
    provider: KycProviders
  }
}

import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { OrderSummaryRender } from '#/nova/components/other/exchange/order-summary/order-summary-total/NovaOrderSummaryTotal';
import { initialStateOrder } from './helpers';
import { OrderState } from './types';

const orderSlice = createSlice({
  name: 'order',
  initialState: initialStateOrder,
  reducers: {
    showModalTrade(state, action: PayloadAction<boolean>) {
      state.showModalTrade = action.payload;
    },
    createConversionStatus(state, action: PayloadAction<RequestStatus>) {
      state.createConversionQuoteStatus = action.payload;
    },
    executeConversionStatus(state, action: PayloadAction<RequestStatus>) {
      state.executeConversionStatus = action.payload;
    },
    orderEstimationStatus(state, action: PayloadAction<RequestStatus>) {
      state.orderEstimationStatus = action.payload;
    },
    orderRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.orderRequestStatus = action.payload;
    },
    orderSummaryRender(state, action: PayloadAction<OrderSummaryRender>) {
      state.orderSummaryRender = action.payload;
    },
  }
});

export const {
  orderRequestStatus,
  orderSummaryRender,
  createConversionStatus,
  orderEstimationStatus,
  executeConversionStatus,
  showModalTrade,
} = orderSlice.actions;

export default orderSlice.reducer;

export const selectOrderState = (state: Store): OrderState => state.trade.order;

export const selectOrderRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.orderRequestStatus,
);

export const selectOrderEstimationRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.orderEstimationStatus,
);
export const selectCreateConversionStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.createConversionQuoteStatus,
);

export const selectExecuteConversionStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.executeConversionStatus,
);

export const selectOrderShowTradeModal: (state: Store) => boolean = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.showModalTrade,
);

export const selectOrderSummaryRender: (state: Store) => OrderSummaryRender = createDraftSafeSelector(
  selectOrderState,
  (orderState: OrderState) => orderState.orderSummaryRender,
);

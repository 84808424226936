import { BigNumber } from 'bignumber.js';
import { TradePager } from '#reducers/trade/interfaces';

export type Product = {
  id?: string,
  name?: string,
  type?: string,
  decimals?: number,
  balance?: number,
  precision?: number,
}
export enum VaultsVariableNames {
  FromDate = 'startingCreatedAt',
  ToDate = 'endingCreatedAt',
  TransactionType = 'transactionType',
  VaultType = 'productId',
  Status = 'status',
}
export enum VaultTypeTransaction {
  Withdraw = "LENDING_REDEMPTION",
  Deposit = "LENDING_ALLOCATION",
  Interest = "INTEREST",
  CreditCorrection = "CREDIT_CORRECTION",
  DebitCorrection = "DEBIT_CORRECTION",
  CreditOther = "CREDIT_OTHER",
  DebitOther = "DEBIT_OTHER",
  Fee = "FEE",
  Hold = "HOLD"
}
export enum VaultTypeTransactionToDisplay {
  Deposit = 'LendingAllocation',
  Withdraw = 'LendingRedemption',
  Interest = 'Interest',
  CreditCorrection = 'CreditCorrection',
  DebitCorrection = 'DebitCorrection',
  CreditOther ='CreditOther',
  DebitOther = 'DebitOther',
  Hold = 'Hold',
  HoldReversal ='HoldReversal',
  Fee = 'Fee',
}
export enum LendingTicketStatus {
  Open = "open",
  Blocked = "blocked",
  Processed = "processed",
  Cancelled = "cancelled"
}
export enum VaultTransactionStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Processed = "PROCESSED",
  Canceled = "CANCELLED"
}
export enum VaultAccountClass {
  Liability = "LIABILITY",
  Equity = "EQUITY",
  Asset = "ASSET "
}
export enum VaultTransactionReason {
  DailyLimit = "DAILY_LIMIT"
}
export enum VaultAccountType {
  Principal = "LENDING_PRINCIPAL",
  Interest = "LENDING_INTEREST",
  Hold = "HOLD",
  OperatorFee = "OPERATOR_FEE"
}
export enum VaultTimeUnit {
  Second = "SECOND",
  Minute = "MINUTE",
  Hour = "HOUR",
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR"
}
export enum VaultTermType {
  Fixed = "FIXED",
  Open = "OPEN"
}
export enum VaultAccrualMode {
  PerBalance = "PER_BALANCE",
  PerTransaction = "PER_TRANSACTION"
}
export enum VaultAccrualMethod {
  CompoundInterest = "COMPOUND_INTEREST",
  SimpleInterest = "SIMPLE_INTEREST"
}
export enum VaultProtocolType {
  Alkemi = "ALKEMI"
}
export enum VaultCategory {
  DeFi = "DEFI",
  Staking = "STAKING",
  YieldFarming = "YIELD_FARMING"
}
export enum VaultRateType {
  Fixed = "FIXED",
  Stable = "STABLE",
  Variable = "VARIABLE"
}
export enum VaultRateCounting {
  Basic = "BASIC",
  Tier = "TIER"
}
export type TransactionType = VaultTypeTransaction.Deposit | VaultTypeTransaction.Withdraw | VaultTypeTransaction.Interest;
export interface VaultFarmingAsset {
  id: string;
  details?: any;
  [propName: string]: any;
}
export interface VaultRateTier {
  tierFrom?: BigNumber;
  tierTo?: BigNumber;
  apy: BigNumber;
  apr?: BigNumber;
}

export interface VaultBalance {
  companyId?: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  sequenceId: string;
  id: string;
  userId: string;
  productId: string;
  assetId: string;
  accountType: VaultAccountType;
  balance: BigNumber;
  accountingClass: VaultAccountClass;
}

export interface VaultPendingTransaction {
  companyId: string;
  userId: string;
  productId: string;
  assetId: string;
  amount: string;
  comments: string;
  createdAt: Date;
  updatedAt: Date;
  id: string;
  transactionType: TransactionType;
  status: VaultTransactionStatus;
  reason: VaultTransactionReason;
}

export interface VaultHistoryQuery {
  companyId?: string;
  userId?: string;
  productId?: string;
  assetId?: string;
  transactionType?: TransactionType;
  startingCreatedAt?: Date | string;
  endingCreatedAt?: Date | string;
  pager?: TradePager,
  lendingId?: string;
  accountType?: VaultAccountType;
}

export interface VaultHistory {
  id: string;
  parentId?: string;
  sequenceId: string;
  lendingId: string;
  accountTypeId: string;
  userId: string;
  productId: string;
  assetId: string;
  amount: BigNumber;
  comments?: string;
  transactionType: TransactionType;
  companyId?: string;
  detailsId?: string;
  createdAt: Date;
  updatedAt: Date;
  status?: string;
  externalMetadata?: {
      interest?: Interest;
      termType: VaultTermType;
      termUnit?: VaultTimeUnit;
      termLength?: number;
  };
}

export interface VaultDepositWithdrawResponse {
  id: string;
  userId: string;
  companyId?: string;
  productId: string;
  assetId: string;
  amount: BigNumber;
  transactionTypeId: string;
  transactionType: TransactionType;
  comments?: string;
  createdAt: Date;
  updatedAt: Date;
  externalMetadata?: any;
}

export interface VaultDepositWithdrawPayload {
  productId: string;
  assetId: string;
  amount: BigNumber;
  userId: string;
  companyId?: string;
  comments?: string;
}

export interface VaultProduct {
  productId: string;
  id: string;
  name: string;
  description: string;
  companyId?: string;
  assetId: string;
  totalBalance: BigNumber;
  tags: string[];
  enabled: boolean;
  allocationEnabled: boolean;
  minimumAllocationAmount?: BigNumber;
  maximumAllocationAmount?: BigNumber;
  redemptionEnabled: boolean;
  minimumRedemptionAmount?: BigNumber;
  maximumRedemptionAmount?: BigNumber;
  termType: VaultTermType;
  termUnit?: VaultTimeUnit;
  termLength?: number;
  interest?: Interest;
  customData?: any;
  protocol?: VaultProtocolType;
  categories?: VaultCategory[];
  farmingAssets?: VaultFarmingAsset[];
  createdAt: Date;
  updatedAt: Date;
  dailyThresholdDelay?: string;
  dailyThresholdRedemptionLimit?: string;
  counting: VaultRateCounting;
  tiers: VaultRateTier[];
}

export enum CompletedTransactionStatus {
  Processed = 'PROCESSED',
  Canceled = 'CANCELLED',
}

export enum PendingTransactionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
}

export interface Interest {
  accrualMode: VaultAccrualMode;
  accrualMethod: VaultAccrualMethod;
  accrualIntervalUnit: VaultTimeUnit;
  accrualInterval: number;
  rateType: VaultRateType;
}

export interface VaultProductWithBalance extends Omit<VaultProduct, 'balance'> {
  balance: VaultBalance;
}

export interface Tab {
  key: string,
  label: string,
  vaultAccountHeader: string,
  useSelectorVault: boolean,
  icon?: string,
  customIcon?: any,
}

type Filter = null | string;
export interface Filters {
  [VaultsVariableNames.FromDate]: Filter | Date,
  [VaultsVariableNames.ToDate]: Filter | Date,
  [VaultsVariableNames.TransactionType]: Filter,
  [VaultsVariableNames.VaultType]: Filter,
  [VaultsVariableNames.Status]: Filter,
}

export enum VaultActionNames {
  Deposit = 'allocation',
  Withdraw = 'redemption',
}

export enum PaymentTypes {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Interest = 'interest',
  Withdrawal = 'withdrawal',
};

export interface ShiftProducts {
  [currencyName: string]: Product
}

export interface ApyAsset {
  avgApy: number, // ETH balance * interest + ETH (n) balance * interest ...
  totalBalance: number, // ETH balance + ETH (n) balance ...
  name: string,
}

export interface AllApys {
  [assetName: string]: ApyAsset,
}

export enum TabKeys {
  Vaults = 'vaults',
  History = 'history',
}

export enum Earnings {
  YearInterestRate = 'yearInterestRate',
  MonthInterestRate = 'monthInterestRate',
  WeekInterestRate = 'weekInterestRate',
  DayInterestRate = 'dayInterestRate',
}

export interface VaultEarning {
  [Earnings.YearInterestRate]: string,
  [Earnings.MonthInterestRate]: string,
  [Earnings.WeekInterestRate]: string,
  [Earnings.DayInterestRate]: string,
}

export type BigNumberable = string | number | BigNumber;

import { AuthStorageItems } from "#reducers/user/user";
import { throttle } from "#/util";
import { useEffect } from "react";

export const useUserActivityEventListener = () => {
  useEffect(() => {
    const effect = throttle(
      () => localStorage.setItem(AuthStorageItems.PrevActionTime, String(Date.now())),
      5000,
    );

    document.body.addEventListener('mousemove', effect);

    return () => {
      document.body.removeEventListener('mousemove', effect);
    }
  }, []);
}

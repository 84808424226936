import { TradePager } from '#/types';
import { useEffect, useState } from 'react';

interface Params {
  nextPageExists: boolean,
  pager: TradePager | undefined,
  array: Array<any>,
}
export const useCountNumItems = (params: Params) => {
  const {
    nextPageExists,
    array,
    pager,
  } = params;

  /** useEffect checks that there are more than one pages when going to the second page and then 
  saves that there are more than one. Made so that pagination would not be hidden when the last page
  goes to the first page
  **/
  const [isPageMoreThenOne, setIsPageMoreThenOne] = useState(false);
  useEffect(() => {
    if (pager && pager?.offset >= pager?.limit && !isPageMoreThenOne) {
      setIsPageMoreThenOne(true)
    }
  }, [array]);
  
  return pager && (array.length >= pager.limit || nextPageExists)
    ? pager.offset + pager.limit + (nextPageExists ? 1 : 0)
    : (isPageMoreThenOne && pager) ? pager.limit + 1 : array.length;
}

import { Themes } from '#/types/enums';
import { ITemplateRoute } from '../';
import { useCryptoPay, useLending } from '#/config/config-env';
import { NovaSettingsPages } from '#/nova/views/trade/settings/pages';
import { WithdrawInfo } from '#/nova/components/other/kyc/widgets/BankId/types';

const { Light, Dark } = Themes;

export enum Paths {
  Landing = '/',
  Login = '/login',
  Exchange = '/exchange',
  Dashboard = '/dashboard',
  Settings = '/settings',
  Markets = '/markets',
  QuickBuySell = '/quick-buy-sell',
  Vaults = '/vaults',
  Wallets = '/wallets',
  WithdrawSuccess = '/wallets/withdraw/success',
  WithdrawReject = '/wallets/withdraw/reject',
  ExchangeWithdrawSuccess = '/exchange/withdraw/success',
  ExchangeWithdrawReject = '/exchange/withdraw/reject',
  KycSuccess = '/settings/kyc/success',
  KycReject = '/settings/kyc/reject',
  FAQ = '/faq',
  Logout = '/logout',
  CryptoPay = '/crypto-pay',
  PrivacyPolicy = '/privacy-policy',
  RiskPolicy = '/risk-policy',
  Terms = '/terms',
  NotFound = '*',
}

const routes: Array<ITemplateRoute> = [
  { path: Paths.Landing, component: 'Landing', auth: false, },
  { path: Paths.Login, component: 'NovaLogin', auth: false, },
  { path: Paths.Exchange, component: 'NovaTradeExchange', auth: false, },
  { path: Paths.Dashboard, component: 'NovaHome', auth: true, },
  { path: Paths.Markets, component: 'NovaTradeMarkets', auth: false, },
  { path: Paths.QuickBuySell, component: 'NovaTradeQuickBuy', auth: true, },
  { path: Paths.Vaults, component: 'NovaVaults', use: useLending, auth: true, },
  { path: Paths.Wallets, component: 'NovaTradeWallets', auth: true, },
  { path: Paths.WithdrawSuccess, component: 'NovaTradeWallets', auth: true, props: { withdrawInfo: WithdrawInfo.WithdrawSuccess } },
  { path: Paths.WithdrawReject, component: 'NovaTradeWallets', auth: true, props: { withdrawInfo: WithdrawInfo.WithdrawReject }},
  { path: Paths.ExchangeWithdrawSuccess, component: 'NovaTradeWallets', auth: true, props: { withdrawInfo: WithdrawInfo.WithdrawSuccess } },
  { path: Paths.ExchangeWithdrawReject, component: 'NovaTradeWallets', auth: true, props: { withdrawInfo: WithdrawInfo.WithdrawReject }},
  { path: Paths.KycSuccess, component: 'NovaTradeSettings', auth: true, props: { defautTab: NovaSettingsPages.Verification } },
  { path: Paths.KycReject, component: 'NovaTradeSettings', auth: true, props: { defautTab: NovaSettingsPages.Verification } },
  { path: Paths.Settings, component: 'NovaTradeSettings', auth: true, },
  { path: Paths.FAQ, component: 'NovaFaq', auth: false, },
  { path: Paths.Logout, component: 'NovaLogout', auth: false, }, 
  { path: Paths.CryptoPay, component: 'NovaCryptoPay', use: useCryptoPay, auth: true, },
  { path: Paths.PrivacyPolicy, component: 'PrivacyPolicy', auth: false, },
  { path: Paths.RiskPolicy, component: 'RiskPolicy', auth: false, },
  { path: Paths.Terms, component: 'Terms', auth: false, },

  { path: Paths.NotFound, component: 'Nova404' },
  // @ts-ignore
].filter((r) => typeof r.use === 'undefined' || !!r.use);

export default {
  routes,
  paths: Paths,
  siteThemes: [Light, Dark],
  defaultSiteTheme: Light,
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  requestAction: any,
  condition: boolean,
}
export const useGetRequest = (props: Props) => {
  const {
    requestAction,
    condition,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (condition) {
      dispatch(requestAction());
    }
  }, []);
};

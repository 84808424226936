import { OrdersState } from './types';
import { RequestStatus, OrderStatus, OrdersGetTypes } from '#/types/enums';

export const inactiveOrderStatuses: Array<OrderStatus> = [OrderStatus.Expired, OrderStatus.Cancelled, OrderStatus.Rejected];
export const openOrderStatuses: Array<OrderStatus> = [OrderStatus.Pending, OrderStatus.New];
export const filledOrderStatuses: Array<OrderStatus> = [OrderStatus.Completed];

export const initialOrdersState: OrdersState = {
  orders: [],
  ordersRequestStatus: RequestStatus.None,
  nextPageOrdersExists: false,
  type: OrdersGetTypes.Open,
  childOrdersRequestStatus: RequestStatus.None,
  childOrders: [],
}

import { IUserState } from './interface';
import { RequestStatus } from '#/types/enums';

export const initialUserState: IUserState = {
  settings: {
    currencySettings: [],
    widgetSettings: '',
    label: '',
    status: RequestStatus.Pending,
    error: {
      code: null,
      message: null,
    },
  },

  childUsers: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  createChildUser: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  uploadLogo: {
    url: '',
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateChildUser: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateWidgetSettings: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  checkUser: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateCurrencySettings: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updatePreferredCurrency: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  updateCryptoConfirmations: {
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },
};

export * from '#reducers/trade/enums';
export * from '#/nova/components/enums';

export enum Themes {
  Light = 'light',
  Dark = 'dark',
  LocalStorageThemeItem = 'theme',
}

export enum Status {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  None = 'NONE',
  Verify = 'VERIFY',
  Verified = 'VERIFIED',
  Agreement = 'AGREEMENT',
  New = 'NEW',
};

export enum RequestStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  None = 'NONE',
};

export enum ToastThemes {
  Light = 'light',
  Dark = 'dark',
  Colored = 'colored',
}

export enum Networks {
  Default = 'default',
  Tron = 'tron',
}

export enum PaymentProvider {
  C14 = 'C14',
  FIREBLOCKS = 'FIREBLOCKS'
}

export enum UrlSearchParamsEnum {
  Secret = 'secret',
  Lang = 'lang',
  IsDevelopment = 'isDevelopment',
  SourceCurrencyCode = 'sourceCurrencyCode',
  ClientId = 'clientId',
  TargetAddress = 'targetAddress',
  SessionId = 'session-id',
  Theme = 'theme',
  Issuer = 'issuer',
}

export enum ERoutesType {
  DepositEenabled = 'deposit_enabled',
  WithdrawalEnabled = 'withdrawal_enabled'
}
import { configureStore } from '@reduxjs/toolkit';
import { isDevelopment } from '../config/config-env';
import reducers from '#reducers';
import preloadedState from './defaultState';
import services, { Services } from '../api';
import listener from './listenerMiddleware';
import ignoreIfNotAuth from '#reducers/listenerEffects/ignoreIfNotAuth';

const store = configureStore({
  reducer: reducers,
  devTools: isDevelopment,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        ...services,
      } as Services,
    },
    serializableCheck: false,
  }).prepend(ignoreIfNotAuth).prepend(listener.middleware)
});

export type AppDispatch = typeof store.dispatch

export default store;

import { FC } from 'react';

import useUserSubscriptions from '#/hooks/trade/useTradeData';
import { useNotificationsApi } from '#/hooks/helpers';
import useVerificationReminder from '#/hooks/user/useVerificationReminder';
import { useAmplify } from '#/hooks/auth/useAmplify';

const NovaSubscriptions: FC = () => {
  useUserSubscriptions();
  useNotificationsApi();
  useVerificationReminder();
  useAmplify();

  return null;
};

export default NovaSubscriptions;

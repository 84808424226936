import { RequestStatus } from '#/types/enums';
import { IDepositState } from './types';

export const initialDepositState: IDepositState = {
  depositCrypto: null,
  depositFiat: null,
  depositRequest: RequestStatus.None,
  depositFiatFundingMethod: null,
  depositFiatPaymentRoute: null,
}

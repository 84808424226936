import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import SettingsService from '#/api/settings/SettingsService';
import {
  updateMfaStatus,
  updateIsUsing2fa,
  updateSecretCodeUri,
  updateSecretCode,
  getMfaStatusRequest,
  setEnabledNotifications,
  updateRestrictionCheckStatus,
} from './settings';
import { throttle, handleErrorThunk } from '#/util';
import { updateSsoSettings } from '#reducers/config';
import { SsoSettingsItem } from './types';
import { RequestStatus } from '#/types/enums';
import UserService from '#/api/user/UserService';
import { UpdateMfaPayload } from '#/api/user/user-gql';
import { setUserCountry } from '#reducers/user/user';

export const restrictionsCheck = createAsyncThunk(
  'user/restrictionsCheck',
  throttle(async (_: any, { dispatch, extra }: any) => {
    dispatch(updateRestrictionCheckStatus(RequestStatus.Pending));
    try {
      await (extra.settingsService as SettingsService).restrictionsCheck();
      dispatch(updateRestrictionCheckStatus(RequestStatus.Success));
    } catch (error) {
      dispatch(updateRestrictionCheckStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Restriction Error', dispatch);
    }
  }, 1500)
) as unknown as () => AnyAction;

export const getSsoSettings = createAsyncThunk(
  'user/getSsoSettings',
  throttle(async (_: any, { dispatch, extra }: any) => {
    try {
      const ssoSettings: SsoSettingsItem = await (extra.settingsService as SettingsService).getSsoSettings();
      dispatch(updateSsoSettings(ssoSettings));
    } catch (err) {
      handleErrorThunk(String(err), 'Get sso settings failed', dispatch);
      dispatch(updateSsoSettings({
        client_id: undefined,
        domain: undefined,
        type: undefined,
        pool_id: undefined,
      }));
    }
  }, 1000)
) as unknown as () => AnyAction;

export const getEnabledNotifications = createAsyncThunk(
  'user/getEnabledNotifications',
  throttle(async (_: any, { dispatch, extra }: any) => {
    try {
      const { notification_settings } = await (extra.settingsService as SettingsService).getEnabledNotifications();
      dispatch(setEnabledNotifications(notification_settings));
    } catch (error) {
      handleErrorThunk(error, 'Fetch Notifications Settings Failed', dispatch);
    }
  }, 1500)
) as unknown as () => AnyAction;

export const getMfaStatus = createAsyncThunk(
  'user/getMfaStatus',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    batch(() => {
      dispatch(getMfaStatusRequest(RequestStatus.Pending));
      dispatch(updateSecretCode(''));
      dispatch(updateSecretCodeUri(''));
    });
    try {
      const { is_using_2fa } = await (extra.userService as UserService).getMfaStatus();
      batch(() => {
        dispatch(updateIsUsing2fa(is_using_2fa));
        dispatch(getMfaStatusRequest(RequestStatus.Success));
      })
    } catch (error) {
      dispatch(getMfaStatusRequest(RequestStatus.Failed));
      setTimeout(() => handleErrorThunk(error, 'Get 2fa settings failed', dispatch), 1000);
    }
  }, 1500)
) as unknown as () => AnyAction;

export const getSecretCode = createAsyncThunk(
  'user/getSecretCode',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    try {
      const { create_user_mfa_secret } = await (extra.userService as UserService).createSecretCode();
      batch(() => {
        dispatch(updateSecretCode(create_user_mfa_secret.secret));
        dispatch(updateSecretCodeUri(create_user_mfa_secret.uri));
      });
    } catch (error) {
      handleErrorThunk(error, 'Get secret code failed', dispatch);
    }
  }, 1000)
) as unknown as () => AnyAction;

export const updateMfa = createAsyncThunk(
  'user/updateMfa',
  throttle(async (settings: UpdateMfaPayload, { dispatch, extra }: any) => {
    dispatch(updateMfaStatus(RequestStatus.Pending));
    try {
      const { update_user_mfa_status } = await (extra.userService as UserService).updateMfaStatus(settings);
      batch(() => {
        dispatch(updateMfaStatus(RequestStatus.Success));
        dispatch(updateIsUsing2fa(!!update_user_mfa_status));
        dispatch(getMfaStatus()); // GET NEW SECRET
      });
      if (settings.status === "on") {
        novaToast.success(_t('2FA Enabled', 'NOVA_SETTINGS.2FA_ENABLED'));
      }
      if (settings.status === "off") {
        novaToast.success(_t('2FA Disabled', 'NOVA_SETTINGS.2FA_DISABLED'));
      }
    } catch (error) {
      dispatch(updateMfaStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Update 2FA settings failed', dispatch);
    }
  }, 1000)
) as unknown as (settings: UpdateMfaPayload) => AnyAction;


import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { initialArticlesState } from './helpers';
import { RequestStatus } from '#/types/enums';
import { Article, ArticlesState } from './types';

const articlesSlice = createSlice({
  name: 'articles',
  initialState: initialArticlesState,
  reducers: {
    updateArticles(state, action: PayloadAction<Array<Article>>) {
      state.articles = action.payload;
      state.requestStatus = RequestStatus.Success;
    },
    updateRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.requestStatus = action.payload;
    },
  },
});

export const {
  updateArticles,
  updateRequestStatus,
} = articlesSlice.actions;

export default articlesSlice.reducer;

const selectArticlesState = (state: Store): ArticlesState => state.home.articles;

export const selectArticles: (state: Store) => Array<Article> = createDraftSafeSelector(
  selectArticlesState,
  (articlesState: ArticlesState) => articlesState.articles,
);

export const selectArticlesRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectArticlesState,
  (articlesState: ArticlesState) => articlesState.requestStatus,
);

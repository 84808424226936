import { initialDepositWithdrawModalState } from './deposit-withdraw-modal/helpers';
import { initialUserPaymentsState } from './payments';
import { initialAddressBookState } from './address-book/helpers';
import { UserWalletsState } from './types';
import { initialWithdrawState } from './withdraw';
import { initialDepositState } from './deposit';
import { initialWalletsState } from './wallets';

export const initialUserWalletsState: UserWalletsState = {
  depositWithdrawModal: initialDepositWithdrawModalState,
  deposit: initialDepositState,
  withdraw: initialWithdrawState,
  payments: initialUserPaymentsState,
  addressBook: initialAddressBookState,
  wallets: initialWalletsState,
};

import { isNumber } from './isNumber';
import { Rounds, round as rountTo } from './roundTo';

export const formatNumberToLocale = (value: number, decimalPlaces = 2, round: Rounds = Rounds.Round, removeTrailingZeros: boolean = false) => {
  if (!isNumber(value)) {
    console.error('formatNumberToLocale - value parameter is not a number', value);
    value = 0;
  }
  
  const roundedValue = rountTo(value, decimalPlaces, round);
  return roundedValue.toLocaleString('en', {
    ...removeTrailingZeros ? {} : { minimumFractionDigits: decimalPlaces },
    maximumFractionDigits: decimalPlaces,
  });
};

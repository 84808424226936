import { RequestStatus } from '#/types/enums';
import { SettingsState } from './types';

export const initialSettingsState: SettingsState = {
  maintenanceMode: false,
  maintenanceMessage: '',
  mfaStatusRequest: RequestStatus.None,
  is_using_2fa: false,
  secretCode: '',
  secretCodeUri: '',
  updateStatusRequest: RequestStatus.None,
  isDevelopment: false,
  enabledNotifications: {},
  geo_restrictions: false,
  isIpBlacklisting: false,
  isGeoBlock: false,
  restrictionCheckStatusRequest: RequestStatus.None,
}

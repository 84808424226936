import { GqlClient } from '../gql';
import settingsGql, {
  CreateKycForm,
  SettingsOperations,
  UploadKycDocument, 
} from './settings-gql';
import { INotificationSettings, SsoSettingsItem } from '#reducers/settings';
import {
  CountryListResult,
  KycGetData,
  ProvinceListResult,
  UploadKycDocumentResult
} from '#reducers/kyc';
import configEnv from '#/config/config-env';

export default class SettingsService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  public async getSsoSettings(): Promise<SsoSettingsItem> {
    try {
      const response: { [SettingsOperations.GetSsoSettings]: { trader: SsoSettingsItem } } =
        await this.gqlRequestClient.request(settingsGql.getSsoSettings());

      return response[SettingsOperations.GetSsoSettings].trader;
    } catch (e) {
      throw e;
    }
  }

  public createKycForm(params: CreateKycForm): Promise<{ create_kyc_user_data: Array<KycGetData> }> {
    return this.gqlRequestClient.request(settingsGql.createKycForm(params));
  }

  public updateKycForm(params: CreateKycForm): Promise<{ update_kyc_user_data: Array<KycGetData> }> {
    return this.gqlRequestClient.request(settingsGql.updateKycForm(params));
  }

  public getEnabledNotifications(): Promise<{ notification_settings: INotificationSettings}> {
    return this.gqlRequestClient.request(settingsGql.getEnabledNotifications());
  }

  public getCountryList(): Promise<{ countries: Array<CountryListResult> }> {
    return this.gqlRequestClient.request(settingsGql.getCountryList());
  }

  public getProvinceList(countryCode: string): Promise<{ provinces: Array<ProvinceListResult> }> {
    return this.gqlRequestClient.request(settingsGql.getProvinceList(countryCode));
  }

  public async uploadKycDocument(params: UploadKycDocument): Promise<{ ok: boolean, result: UploadKycDocumentResult | undefined, error?: string }> {
    try {
      const payload = new FormData();
      payload.append('documentType', params.documentType);
      payload.append('file', params.file);

      const response = await fetch(configEnv.vakoUploads, {
        method: 'POST',
        body: payload,
        headers: {
          Authorization: this.gqlRequestClient.authorization,
        }
      });

      const result: UploadKycDocumentResult = await response.json();

      if (!result.s3_location) {
        return Promise.reject({ ok: false, error: result.message || 'Uploading failed' });
      }

      return { ok: true, result };
    } catch (e) {
      console.error(e);
      return Promise.reject({ ok: false, error: e });
    }
  }

  public restrictionsCheck(): Promise<boolean> { 
    return this.gqlRequestClient.request(settingsGql.restrictionsCheck());
  }
}

import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';
import { initialWebhookState } from './initialState';

import {
  getWebhooks,
  addWebhook,
  deleteWebhookById,
  updateWebhookById,
} from '#/store/reducers/cryptoPay/thunks/webhook';

const webhookSlice = createSlice({
  name: 'webhook',
  initialState: initialWebhookState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWebhooks.pending, (state) => {
        state.webhooks.status = RequestStatus.Pending;
        state.webhooks.error.code = null;
        state.webhooks.error.message = null;
      })
      .addCase(getWebhooks.fulfilled, (state, action) => {
        state.webhooks.status = RequestStatus.Success;
        state.webhooks.items = action.payload.web_hooks;
      })
      .addCase(getWebhooks.rejected, (state, action: any) => {
        state.webhooks.status = RequestStatus.Failed;
        state.webhooks.error.code = action.payload.code;
        state.webhooks.error.message = action.payload.message;
      });

    builder
      .addCase(addWebhook.pending, (state) => {
        state.addWebhook.status = RequestStatus.Pending;
        state.addWebhook.error.code = null;
        state.addWebhook.error.message = null;
      })
      .addCase(addWebhook.fulfilled, (state) => {
        state.addWebhook.status = RequestStatus.Success;
      })
      .addCase(addWebhook.rejected, (state, action: any) => {
        state.addWebhook.status = RequestStatus.Failed;
        state.addWebhook.error.code = action.payload.code;
        state.addWebhook.error.message = action.payload.message;
      });

    builder
      .addCase(deleteWebhookById.pending, (state) => {
        state.deleteWebhookById.status = RequestStatus.Pending;
        state.deleteWebhookById.error.code = null;
        state.deleteWebhookById.error.message = null;
      })
      .addCase(deleteWebhookById.fulfilled, (state) => {
        state.deleteWebhookById.status = RequestStatus.Success;
      })
      .addCase(deleteWebhookById.rejected, (state, action: any) => {
        state.deleteWebhookById.status = RequestStatus.Failed;
        state.deleteWebhookById.error.code = action.payload.code;
        state.deleteWebhookById.error.message = action.payload.message;
      });

    builder
      .addCase(updateWebhookById.pending, (state) => {
        state.updateWebhookById.status = RequestStatus.Pending;
        state.updateWebhookById.error.code = null;
        state.updateWebhookById.error.message = null;
      })
      .addCase(updateWebhookById.fulfilled, (state) => {
        state.updateWebhookById.status = RequestStatus.Success;
      })
      .addCase(updateWebhookById.rejected, (state, action: any) => {
        state.updateWebhookById.status = RequestStatus.Failed;
        state.updateWebhookById.error.code = action.payload.code;
        state.updateWebhookById.error.message = action.payload.message;
      });
  },
});

export default webhookSlice.reducer;

import { IPaymentState } from './interface';
import { RequestStatus } from '#/types/enums';

export const initialPaymentState: IPaymentState = {
  payments: {
    items: [],
    totalCount: 0,
    page: 0,
    size: 0,
    status: RequestStatus.Pending,
    error: {
      code: null,
      message: null,
    },
  },

  paymentAddresses: {
    items: [],
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },

  createPayment: {
    item: null,
    status: RequestStatus.None,
    error: {
      code: null,
      message: null,
    },
  },
};

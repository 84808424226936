import { initialSettingsState, SettingsState } from '#reducers/settings';
import { ITradeState, initialTradeState } from '#reducers/trade/types';
import { initialUserWalletsState } from '#reducers/wallets/helpers';
import { UserWalletsState } from '#reducers/wallets/types';
import config, { Config } from '../config/config';
import { User, initialStateUser } from './reducers/user/user';
import { initialKycState, IKycState } from '#reducers/kyc';
import { VaultsState, intitialVaultsState } from '#reducers/vaults/vaults';
import { createMockStore } from 'redux-test-utils';
import { IHomeState, initialHomeState } from '#reducers/home/types';

import { ICryptoPayState, initialCryptoPayState } from '#/store/reducers/cryptoPay/types';

export interface Store {
  config: Config;
  user: User;
  wallets: UserWalletsState;
  settings: SettingsState;
  trade: ITradeState;
  vaults: VaultsState;
  kyc: IKycState;
  home: IHomeState;
  cryptoPay: ICryptoPayState;
}

const state: Store = {
  config,
  user: initialStateUser,
  wallets: initialUserWalletsState,
  settings: initialSettingsState,
  trade: initialTradeState,
  kyc: initialKycState,
  vaults: intitialVaultsState,
  home: initialHomeState,
  cryptoPay: initialCryptoPayState,
};

const mockStore = createMockStore(state);

export type MockStore = typeof mockStore;

export default state;

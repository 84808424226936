import { AuthStorageItems } from '#reducers/user/user';
import { AnyAction, MiddlewareAPI, Dispatch } from '@reduxjs/toolkit';

const ignoreTypes = [
  'user/getProfile/pending',
  'user/subscribeUserEvents/pending',
  'trade/getOrders/pending',
  'trade/getConversions/pending',
  'trade/getBalances/pending',
  'user/getMfaStatus/pending',
  'user/getSecretCode/pending',
  'vaults/getVaultsBalances/pending',
  'vaults/getVaultsHistory/pending',
  'vaults/getVaultsProducts/pending',
  'vaults/getVaultsPendingTransactions/pending',
  'wallets/getPayments/pending',
  'user/getProfile',
  'user/subscribeUserEvents',
  'trade/getOrders',
  'trade/getConversions',
  'trade/getBalances',
  'user/getMfaStatus',
  'user/getSecretCode',
  'vaults/getVaultsBalances',
  'vaults/getVaultsHistory',
  'vaults/getVaultsProducts',
  'vaults/getVaultsPendingTransactions',
  'wallets/getPayments',
];

const ignoreIfNotAuth = ({ dispatch }: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  const { type } = action;
  const token = localStorage.getItem(AuthStorageItems.Token) || '';

  if (!ignoreTypes.includes(type) || (ignoreTypes.includes(type) && !!token.length)) {
    next(action);
  }
}

export default ignoreIfNotAuth;

import { Periodicity } from '#reducers/trade/enums';
import { UserMarketCurrencies } from '#reducers/user/user';

export const priceFields = [
  'instrument_id',
  'ask',
  'bid',
  'price_24h_change',
  'ts_iso',
];

export const priceBarFields = [
  'instrument_id',
  'high',
  'low',
  'ts_iso',
  'close',
  'open',
];

export const accountTransaction = [
  'currency_id',
  'type',
  'amount',
  'post_balance',
  'order_id',
];

export const orderEventFields = [
  'order_id',
  'type',
  'side',
  'status',
  'price',
  'stop_price',
  'quantity',
  'executed_quantity',
  'remaining_quantity',
  'quantity_mode',
  'instrument_id',
  'message',
  'updated_at',
  'created_at',
  'expires_at',
  'time_in_force',
  {
    instrument: [
      'quote_currency_id',
      'name',
      'base_currency_id',
      'quantity_decimals',
      'quote_quantity_decimals',
    ]
  },
  {
    fees: [
      'currency_id',
      'amount',
      {
        currency: [
          'precision',
        ]
      },
    ]
  }
];

export const getConversionsFields = [
  'conversion_id',
  'conversion_quote_id',
  'reference',
  'source_currency_id',
  'source_currency_amount',
  'target_currency_id',
  'target_currency_amount',
  'instrument_id',
  'fee_currency_id',
  'fee_currency_amount',
  'price',
  'status',
  'message',
  'error_message',
  'created_at_iso',
  'updated_at_iso',
]

export const conversionFields = [
  'price',
  'fee_currency_id',
  'fee_currency_amount',
  'conversion_quote_id',
  'source_currency_id',
  'target_currency_id',
  'target_currency_amount',
  'source_currency_amount',
];

export const executeConversionFields = [
  'status',
  'message',
  'created_at',
  'updated_at',
  'error_message',
  // @ts-ignore
  ...conversionFields,
];

export const getTransactionsFields = [
  'account_transaction_id',
  'serial_id',
  'parent_transaction_id',
  'client_transaction_id',
  'user_id',
  'account_id',
  'payment_id',
  'currency_id',
  'transaction_class',
  'type',
  'order_id',
  'trade_id',
  'conversion_id',
  'amount',
  'post_balance',
  'comment',
  'created_at',
  'created_at_iso',
  {
    currency: [
      'type',
      'precision',
      'currency_id',
    ],
  },
]

export const createConversionQuoteFields = [
  'expires_at',
  'expires_at_iso',
  'current_time_iso',
  {
    fees: ['currency_id', 'amount']
  },
  // @ts-ignore
  ...conversionFields,
];

export const depositCryptoFields = [
  'deposit_address_crypto_id',
  'currency_id',
  'address',
  'address_tag_type',
  'address_tag_value',
  'network',
  'created_at',
  'updated_at',
];


export const estimateOrderFields = [
  'type',
  'price',
  'quantity',
  'side',
  'quantity_mode',
  {
    instrument: ['instrument_id'],
  },
  {
    fees: ['currency_id', 'amount'],
  },
];

const networkFeeFields = [
  'fee_per_byte',
  'gas_price',
  'network_fee',
  'base_fee',
  'priority_fee',
];
export const estimateNetworkFeeFields = [
  {
    low: networkFeeFields,
  },
  {
    medium: networkFeeFields,
  },
  {
    high: networkFeeFields,
  },
];

export const subscribeOrderbookFields = [
  'instrument_id',
  {
    buy: ['quantity', 'price'],
  },
  {
    sell: ['quantity', 'price'],
  }
];

export const getBalancesFiels = [
  'currency_id',
  'total_balance',
  'exposed_balance',
  {
    currency: [
      'type',
      'precision',
      'is_active',
      'name',
      {
        payment_routes: [
          'payment_route_id',
          'currency_id',
          'psp_service_id',
          'name',
          'crypto_network',
          'crypto_address_generate_new',
          'crypto_address_tag_type',
          'fiat_iframe_deposit_url',
          'fiat_iframe_withdrawal_url',
          'fiat_transfer_type',
          'is_active',
          'extend_network_fee',
          'is_development',
          'verification_type',
          // 'fiat_deposit_properties',
          'deposit_enabled',
          'withdrawal_enabled',
        ],
      }
    ],
  },
  'free_balance',
  `free_balance_${UserMarketCurrencies.USD}: free_balance_quoted (quote_currency_id: "${UserMarketCurrencies.USD}")`,
  `free_balance_${UserMarketCurrencies.BTC}: free_balance_quoted (quote_currency_id: "${UserMarketCurrencies.BTC}")`,
  `free_balance_${UserMarketCurrencies.ETH}: free_balance_quoted (quote_currency_id: "${UserMarketCurrencies.ETH}")`,
  `free_balance_USDT: free_balance_quoted (quote_currency_id: "USDT")`,
];

export const paymentFields = [
  'payment_id',
  'currency_id',
  'amount',
  'type',
  'crypto_transaction_id',
  'crypto_address',
  'crypto_address_tag_type',
  'crypto_address_tag_value',
  'crypto_network',
  'approval_status',
  'fiat_bank_bic',
  'fiat_bank_name',
  'fiat_beneficiary_account_number',
  'fiat_beneficiary_name',
  'fiat_transfer_type',
  'fiat_bank_country',
  'fiat_bank_region',
  'fiat_bank_city',
  'fiat_bank_postal_code',
  'fiat_beneficiary_country',
  'fiat_beneficiary_region',
  'fiat_beneficiary_city',
  'fiat_beneficiary_postal_code',
  'fiat_beneficiary_address_line_1',
  'psp_service_id',
  'fiat_reference',
  'fiat_bank_address',
  'fiat_routing_number',
  'fiat_notes',
  'fiat_beneficiary_address_line_2',
  'fee_amount',
  'status',
  'message',
  'error_message',
  'created_at',
  'updated_at',
  'manual_transaction_date_iso',
  'fees_included',
  {
    payment_route: [
      'name'
    ]
  },
  {
    account_transactions: [
      'currency_id',
      'transaction_class',
      'post_balance',
      'amount',
    ]
  },
  {
    properties: [
      'name',
      'value',
    ],
  },
];

export const subscribeTradeEventsFields = [
  {
    order: orderEventFields,
  },
  {
    account_transaction: accountTransaction,
  },
  {
    payment: paymentFields,
  },
  {
    account: ['account_id', 'balance'],
  },
  {
    notification: ['title', 'body', 'class'],
  },
  {
    healthcheck: ['maintenance_message', 'maintenance_mode'],
  },
  {
    conversion: getConversionsFields,
  },
];

export const getInstrumnetsPriceBars = [
  'instrument_id',
  {
    operation: "price_bars",
    fields: priceBarFields,
    variables: {
      limit: { value: 24, required: false },
      periodicity: { value: Periodicity.Hour, required: true, type: 'InstrumentHistoryPeriodicity' },
    },
  },
];

export const instrumentBaseQuotePriceBarsFields = [
  {
    quote_currency: [
      'precision',
      'name',
    ],
  },
  {
    base_currency: [
      'precision',
      'name',
    ],
  },
  {
    operation: "price_bars",
    fields: priceBarFields,
    variables: {
      limit: { value: 24, required: false },
      periodicity: { value: Periodicity.Hour, required: true, type: 'InstrumentHistoryPeriodicity' },
    },
  },
];

export const getInstrumentsFields = [
  'name',
  'base_currency_id',
  'quote_currency_id',
  'instrument_id',
  'quote_quantity_decimals',
  'quantity_decimals',
  'price_decimals',
  'min_quantity',
  'max_quantity',
  'min_quote_quantity',
  'max_quote_quantity',
  'is_active',
  {
    quote_currency: [
      'precision',
      'name',
    ],
  },
  {
    base_currency: [
      'precision',
      'name',
    ],
  },
  {
    price: priceFields,
  },
  {
    operation: "recent_price_bar",
    fields: ['high', 'low'],
    variables: {
      periodicity: { value: Periodicity.Day, required: true, type: 'InstrumentHistoryPeriodicity' },
    }
  },
];

export const fiatDepositBankFields = [
  {
    fiat_deposit_properties : [
      'name',
      'value',
    ]
  },
]

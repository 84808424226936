export interface IRange {
  min: number;
  max: number;
  step: string;
}

export interface ICryptoPayConfig {
  failRequestMessage: string;
  successRequestMessage: string;
  range: IRange;
}

const cryptoPayConfig: ICryptoPayConfig = {
  failRequestMessage: "Your changes haven't been saved",
  successRequestMessage: 'Your changes have been saved',
  range: {
    min: 0,
    max: 5,
    step: '0.05',
  },
};

export default cryptoPayConfig;

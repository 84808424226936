export const loginFields = [
  'jwt',
  'expires_at',
];

export const getUserProfileFields = (twoFa?: string)=> [
  'username',
  'user_id',
  'email',
  'language',
  'timezone',
  'primary_market_currency',
  'profile_pic_url',
  'created_at',
  'updated_at',
  'favorite_instruments',
  'mfa_status',
  'default_timezone',
  'crypto_pay',
  'fee_group_id',
  {
    kyc_data: ['kyc_property', 'kyc_value'],
  },
  {
    favorite_addresses_crypto: ['currency_id', 'address', 'address_tag_type', 'address_tag_value', 'name', 'network', 'psp_service_id', 'network_name'],
  },
  {
    notifications_options: ['client', 'push', 'email', 'sms'],
  },
  {
    favorite_fiat_destinations: [
      'name',
      'bank_name',
      'bank_country',
      'bank_region',
      'bank_city',
      'bank_address',
      'bank_postal_code',
      'bank_bic',
      'routing_number',
      'reference',
      'notes',
      'beneficiary_name',
      'beneficiary_country',
      'beneficiary_region',
      'beneficiary_account_number',
      'beneficiary_address_line_1',
      'beneficiary_address_line_2',
      'beneficiary_city',
      'beneficiary_postal_code',
    ],
  },
  {
    operation: 'anti_phishing_code',
    variables: {
      token: { value: twoFa ? twoFa : null, required: false, type: 'String' }
    },
    fields: [],
  },
];

export const getKycTypeProvidersFields = [
  {
    individual: [
      'provider',
      'provider_url',
      'enabled',
    ],
  },
  {
    corporate: [
      'provider',
      'provider_url',
      'enabled',
    ],
  }
];

export const getKycInfoFields = [
  'user_id',
  'kyc_property',
  'kyc_value',
  'updated_at',
];


export const getKycDocFields = [
  'proof_of_residence_url',
  'proof_of_id_front_url',
  'company_registration_url',
  'memorandum_of_association_url',
];

export const getAccountActivityFields = [
  'event_name',
  'event_data',
  'created_at',
  {
    instrument:[
      'base_currency_id',
      'quote_currency_id',
    ]
  }
];

export const getUserPaymentLimits = [
  {
    limit_group: [
      {
        payment_limits: [
          'currency_id',
          'deposit_enabled',
          'deposit_min_amount',
          'deposit_daily_limit',
          'deposit_weekly_limit',
          'deposit_monthly_limit',
          'withdrawal_enabled',
          'withdrawal_min_amount',
          'withdrawal_daily_limit',
          'withdrawal_weekly_limit',
          'withdrawal_monthly_limit',
        ],
      }
    ]
  }
];

export const getPaymentFees = [
  'currency_id',
  'deposit_flat_fee',
  'deposit_progressive_fee',
  'fee_group_id',
  'fiat_transfer_type',
];

export const checkInFields = [];

import { checkIsParamInUrl, getUrlParam } from '#/util';
import { BankIdStatuses, BankIdType } from './types';

interface CreateUrlProps {
  widgetUrl?: string;
  currentHref?: string;
  lang?: string;
  theme?: string;
  kycSessionId?: string | null;
  signicatSessionId? : string | null,
  type: BankIdType;
}

export const createUrl = ({
  widgetUrl,
  currentHref,
  lang,
  theme,
  kycSessionId,
  signicatSessionId,
  type
}: CreateUrlProps): string => {
  const url = `${widgetUrl?.replace(/\/$/, '')}/`;
  const querryParam = `&lang=${lang}&theme=${theme}&type=${type}&kycSessionId=${kycSessionId}`;
  let additionalParam = `?domain=${currentHref?.replace(/\/$/, '')}/${type}`;
  const statusHref = [BankIdStatuses.Success, BankIdStatuses.Reject].find(status => currentHref?.includes(status));

  if (statusHref) {
    additionalParam = `${statusHref}?signicatSessionId=${signicatSessionId}`;
  }

  return `${url}${additionalParam}${querryParam}`;
};

export const REFRESH_MSG = 'refresh-after-bank-id';
export const INTERVAL = 900 * 1000;

export const isBankIdSuccessUrlParams = () => {
  const SESSION_ID = 'sessionId';
  const SUCCESS = 'success';

  const sessionId = getUrlParam(SESSION_ID);
  const href = window.location.href;
  const isSuccess = checkIsParamInUrl(href, SUCCESS);

  return sessionId && isSuccess
}

export const removePath = () => {
  const newUrl = '/wallets';
  window.history.pushState(null, '', newUrl);
};

import { Earnings, PendingTransactionStatus, TabKeys, CompletedTransactionStatus, PaymentTypes, VaultsVariableNames } from '#/types';
import vaultIcon from '#images/icons/Vault_logo_Switch_.svg';

const vaultCurrencyDefault = 'USD';

export default {
  hideAlkemi: false,
  defaultVaultsPrecision: 8,
  vaultCurrencyDefault,
  earnings: (_t: Function) => [
    { key: Earnings.YearInterestRate, label: _t('year', 'VAULTS.DATE_YEAR') },
    { key: Earnings.MonthInterestRate, label: _t('month', 'VAULTS.DATE_MONTH') },
    { key: Earnings.WeekInterestRate, label: _t('week', 'VAULTS.DATE_WEEK') },
    { key: Earnings.DayInterestRate, label: _t('day', 'VAULTS.DATE_DAY') },
  ],
  account: {
    balanceIn: { key: vaultCurrencyDefault, label: vaultCurrencyDefault },
    allBalancesIn: (_t: Function) => ({ key: vaultCurrencyDefault, label: _t('All vaults', 'VAULTS.ALL_VAULTS') }),
  },
  history: {
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm a',
    rowsPerPageDesktop: 10,
    rowsPerPageMobile: 6,
    defaultFilters: {
      [VaultsVariableNames.FromDate]: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30), // 30 days before
      [VaultsVariableNames.ToDate]: null,
      [VaultsVariableNames.TransactionType]: null, // use VaultTypeTransaction[Withdraw | Deposit | Interest]
      [VaultsVariableNames.VaultType]: null,
      [VaultsVariableNames.Status]: null,
    },
    pendingTransactionStatuses: [
      PendingTransactionStatus.Pending,
      PendingTransactionStatus.Approved,
      CompletedTransactionStatus.Processed,
      CompletedTransactionStatus.Canceled,
    ].map(e => e.toUpperCase()),
    filterAllOption: (_t: Function) => ({ key: null, label: _t('All', 'VAULTS.ALL') }),
  },
  vaults: {
    useExternalTermsLink: true,
    useVaultBanner: false,
    faqLink: 'https://www.nexus.trade/faq',
    termsLink: 'https://www.nexus.trade/lending-terms',
    categoriesColors: {
      defi: '#43C78D',
      staking: '#000000',
      yield_FARMING: '#000000',
    },
    farmingColors: {
      alkemi: '#4C39FD',
      alk: '#4C39FD',
      btc: '#F7931A',
      aave: 'linear-gradient(to right, #3BB1C3, #A95AA2)',
    },
    switchProtocols: {
      farm: {},
      notFarm: {
        aave: 'btc',
      },
    },
    switchWallets: {
      aave: 'btc',
    },
    /**
     * if colors are updated - update mixins.scss $currencies variable
     */
    colors: {
      alkemi: '#4C39FD',
      alk: '#4C39FD',
      btc: '#F7931A',
      eth: '#627EEA',
      weth: '#627EEA',
      dai: '#FAB62C',
      usd: '#2775CA',
      usdc: '#2775CA',
      wbtc: '#5a5564',
      gyen: '#005BAC',
      zusd: '#D62925',
      usdt: '#27A17C',
      brz: '#93D3CF',
      aave: '#3BB1C3',
      bilira: '#04134C',
      tryb: '#04134C',
      xrp: '#800080',
      xlm: '#000000',
      hbar: '#000000',
      euroc: '#0f8ff8',
    },
    colorsRgb: {
      alkemi: '76, 57, 253',
      alk: '76, 57, 253',
      btc: '247, 147, 26',
      eth: '98, 126, 234',
      weth: '98, 126, 234',
      dai: '250, 182, 44',
      usd: '39, 117, 202',
      usdc: '39, 117, 202',
      wbtc: '90, 85, 100',
      gyen: '0, 91, 172',
      zusd: '214, 41, 37',
      usdt: '39, 161, 124',
      brz: '147, 211, 207',
      bilira: '4, 19, 76',
      tryb: '4, 19, 76',
      xrp: '128, 0, 128',
      aave: '59, 177, 195',
      xlm: '0, 0, 0',
      hbar: '0, 0, 0',
      euroc: '15, 143, 248',
    },
    networks: {
        USDC: ['xlm', 'eth', 'algo'],
        BRZ: ['xlm', 'eth'],
    },
    defaultSliderStep: 1,
    percentageButtons: {
      [PaymentTypes.Deposit]: [25, 50, 75, 100],
      [PaymentTypes.Withdraw]: [20, 40, 60, 80, 100],
      [PaymentTypes.Interest]: [25, 50, 75, 100],
    },
    percentageDots: {
      [PaymentTypes.Deposit]: [0, 25, 50, 75, 100],
      [PaymentTypes.Withdraw]: [0, 20, 40, 60, 80, 100],
      [PaymentTypes.Interest]: [0, 25, 50, 75, 100],
    },
  },
  getTabs: (_t: Function) => [
    {
      key: TabKeys.Vaults,
      label: _t('Available Vaults', 'VAULTS.AVAILABLE_VAULTS'),
      vaultAccountHeader: _t('Vault Account', 'VAULTS.VAULT_ACCOUNT_HEADER'),
      useSelectorVault: false,
      customIcon: vaultIcon,
      icon: 'fa-unlock',
    },
    {
      key: TabKeys.History,
      label: _t('Transaction History', 'VAULTS.HEADER_HISTORY'),
      vaultAccountHeader: _t('Vault Account', 'VAULTS.VAULT_ACCOUNT_HEADER'),
      useSelectorVault: false,
      icon: 'fa-history',
    },
  ],
};

export const onlyNumbers = (_value: string) => {
  const value = [',', '.', '/', 'ю', 'б'].includes(_value[_value.length - 1]) // Ignore if last , or .
    ? _value.substring(0, _value.length - 1)
    :  _value;

  const rgx = /^\d[\d,]*(\.\d+)?$/;
  // eslint-disable-next-line no-mixed-operators
  return value === '' || value.match(rgx) && !Number.isNaN(
    Number(
      value.replaceAll(',', '.')
    )
  );
}

import { sendRequest, sendFileRequest } from './request';
import {
  IUploadLogoRequest,
  ICreateChildUserRequest,
  IUpdateWidgetSettingsRequest,
  IUpdateCurrencySettingsRequest,
  IUpdatePreferredCurrencyRequest,
  IUpdateCryptoConfirmationsRequest,
} from './interface';

export const user = {
  getSettings: () => sendRequest(`v1/user/settings`, 'GET'),
  getChildUsers: () => sendRequest(`v1/user/child-users`, 'GET'),

  uploadLogo: (body: IUploadLogoRequest) => sendFileRequest(`v1/user/logo`, 'POST', body),
  checkUser: () => sendRequest(`v1/user/checkin`, 'PATCH'),

  createChildUser: (body: ICreateChildUserRequest) => sendRequest(`v1/user/child`, 'POST', body),
  updateChildUser: (id: string) => sendRequest(`v1/user/child/${id}`, 'PUT'),

  updateWidgetSettings: (body: IUpdateWidgetSettingsRequest) => sendRequest(`v1/user/widget-settings`, 'PATCH', body),
  updateCurrencySettings: (body: IUpdateCurrencySettingsRequest) =>
    sendRequest(`v1/user/update-currency-settings`, 'PATCH', body),
  updatePreferredCurrency: (body: IUpdatePreferredCurrencyRequest) =>
    sendRequest(`v1/user/update-preferred-currency`, 'PATCH', body),
  updateCryptoConfirmations: (body: IUpdateCryptoConfirmationsRequest) =>
    sendRequest(`v1/user/update-crypto-confirmations`, 'PATCH', body),
};

export enum AnaliticsIds {
  accVerification = "acc-verification",
  accSettings = "acc-settings",
  accLogout = "acc-logout",
  accLogin = "acc-login",
  btnPopupTrade = "btn-popup-trade",
  btnPopupVerify = "btn-popup-verify",
  btnIndividualKyc = "btn-individual-kyc",
  btnCorporateKyc = "btn-corporate-kyc",
  linkHome = "link-home",
  linkMarket = "link-market",
  linkTrade = "link-trade",
  linkQuickBuySell = "link-quick-buy-sell",
  linkWallet = "link-wallet",
  linkVaults = "link-vaults",
  linkVerification = "link-acc-verification",
  linkSettings = "link-acc-settings",
  linkCryptoPay = 'link-crypto-pay'
}
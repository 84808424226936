import { isNumber } from './isNumber';

export const formatExponentialNumberToLocale = (num: number) => {
  if (!isNumber(num)) {
    console.log('formatNumberToLocale - value parameter is not a number', num);
    num = 0;
  }

  return (''+ +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function(a,b,c,d,e) {
      return e < 0
        ? b + '0.' + Array(1-e-c.length).join('0') + c + d
        : b + c + d + Array(e-d.length+1).join('0');
    });
}
import translate from '#/util/translation/translate';

window._t = translate();
window._marketPrices = {}

declare global {
  interface Window {
    attachEvent: any,
    _marketPrices: object,
  }
}

export default {};

import { BigNumber } from 'bignumber.js';
import { BigNumberable } from '#/types';
import { zeroBigNumber } from '#/util';

export const toPositiveBigNumber = (value: BigNumberable) => {
  const bigNumberValue = new BigNumber(value);
  return bigNumberValue.isNaN() ? zeroBigNumber : bigNumberValue;
};
/**
 * Set values as BigNumber by exact keys
 * @param object
 * @param keys
 */
export const toBigNumberObjectValues = (object: any, keys: Array<string | number>) => {
  keys.forEach((key) => {
    const deepKey: Array<string> | null = String(key).indexOf('.') !== -1 ? String(key).split('.') : null;
    if (object && object[key] && ['string', 'number'].includes(typeof object[key])) {
      if (deepKey && object[deepKey[0]][deepKey[1]]) {
        object[deepKey[0]][deepKey[1]] = toPositiveBigNumber(object[deepKey[0]][deepKey[1]]);
      } else {
        object[key] = toPositiveBigNumber(object[key]);
      }
    }
  });
  return object;
};

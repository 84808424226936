import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types';
import { initialPaymentLimitsState } from './helpers';
import { IPaymentLimit } from '#/api/user/dto';
import { IPaymentLimitsState } from './types';

const paymentLimitsSlice = createSlice({
  name: 'paymentLimits',
  initialState: initialPaymentLimitsState,
  reducers: {
    updatePaymentLimitsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.paymentLimitsRequestStatus = action.payload;
    },
    updatePaymentLimits(state, action: PayloadAction<Array<IPaymentLimit>>) {
      state.paymentLimits = action.payload;
      state.paymentLimitsRequestStatus = RequestStatus.Success;
    },
    updateUserDisabledCurrencies(state, action: PayloadAction<Array<string>>) {
      state.disabledCurrencies = action.payload;
    },
    updateUserDisabledInstruments(state, action: PayloadAction<Array<string>>) {
      state.disabledInstruments = action.payload;
    },
  },
});

export const {
  updatePaymentLimitsRequestStatus,
  updatePaymentLimits,
  updateUserDisabledInstruments,
  updateUserDisabledCurrencies,
} = paymentLimitsSlice.actions;

export default paymentLimitsSlice.reducer;

export const selectPaymentLimitsState = (state: Store): IPaymentLimitsState => state.trade.paymentLimits;

export const selectPaymentLimits: (state: Store) => Array<IPaymentLimit> = createDraftSafeSelector(
  selectPaymentLimitsState,
  (paymentLimitsState: IPaymentLimitsState) => paymentLimitsState.paymentLimits,
);

export const selectUserDisabledCurrencies: (state: Store) => Array<string> = createDraftSafeSelector(
  selectPaymentLimitsState,
  (paymentLimitsState: IPaymentLimitsState) => paymentLimitsState.disabledCurrencies,
);

export const selectUserDisabledInstruments: (state: Store) => Array<string> = createDraftSafeSelector(
  selectPaymentLimitsState,
  (paymentLimitsState: IPaymentLimitsState) => paymentLimitsState.disabledInstruments,
);

export const selectPaymentLimitsRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPaymentLimitsState,
  (paymentLimitsState: IPaymentLimitsState) => paymentLimitsState.paymentLimitsRequestStatus,
);

export enum Environment {
  Production = 'Production',
  Staging = 'Staging',
}

export const isDevelopment = process.env.NODE_ENV === 'development';
export const env: Environment = Environment.Production;
export const useLending: boolean = false;
export const useCryptoPay: boolean = false;
export const useNotifications: boolean = false;

const siteName: string = 'Bitcoins Norway';

interface PermanentConfig {
  siteName: string,
  useLending: boolean,
  useCryptoPay: boolean,
  useNotifications: boolean,
}

export interface PermanentConfigEnv extends PermanentConfig {
  environment: Environment,
  exchangeName: string,
  vakoWs: string,
  vakoUrl: string,
  cryptoPayUrl: string,
  cryptoPayWidgetUrl: string,
  lendingUrl: string,
  lendingReports: string,
  vakoUploads: string,
  vakoReports: string,
}

export interface ConfigEnv extends PermanentConfigEnv {
  Production: PermanentConfigEnv,
  Staging: PermanentConfigEnv,
}

const permanentConfigData: PermanentConfig = {
  siteName,
  useLending,
  useCryptoPay,
  useNotifications,
};

// @ts-ignore
const envs: ConfigEnv = {
  Production: {
    environment: Environment.Production,
    exchangeName: 'DEMO',
    vakoWs: 'wss://vakotrade-bcnorway.cryptosrvc.com/graphql',
    vakoUrl: 'https://vakotrade-bcnorway.cryptosrvc.com/graphql',
    cryptoPayUrl: 'https://crypto-pay-service.cryptosrvc.com',
    cryptoPayWidgetUrl: 'https://crypto-pay-widget.cryptosrvc.com',
    lendingUrl: 'https://lending-service.cryptosrvc.com/graphql',
    lendingReports: 'https://lending-service.cryptosrvc.com/v1/back-office/lending',
    vakoUploads: 'https://vakotrade-bcnorway.cryptosrvc.com/uploads',
    vakoReports: 'https://vakotrade-bcnorway.cryptosrvc.com/reports',
    ...permanentConfigData,
  },
  Staging: {
    environment: Environment.Staging,
    exchangeName: 'VAKO-MAIN',
    vakoWs: 'wss://vakotrade.cryptosrvc-staging.com/graphql',
    vakoUrl: 'https://vakotrade.cryptosrvc-staging.com/graphql',
    cryptoPayUrl: 'https://crypto-pay-service.cryptosrvc-staging.com',
    cryptoPayWidgetUrl: 'https://crypto-pay-widget.cryptosrvc-staging.com',
    lendingUrl: 'https://lending-service.cryptosrvc-staging.com/graphql',
    lendingReports: 'https://lending-service.cryptosrvc-staging.com/v1/back-office/lending',
    vakoUploads: 'https://vakotrade.cryptosrvc-staging.com/uploads',
    vakoReports: 'https://vakotrade.cryptosrvc-staging.com/reports',
    ...permanentConfigData,
  },
};

export default envs[env] as PermanentConfigEnv;

import { DepositWithdraw } from '#reducers/trade/enums';
import { IDepositWithdrawModalState } from './types';

export const initialDepositWithdrawModalState: IDepositWithdrawModalState = {
  activeSide: DepositWithdraw.None,
  isOpen: false,
  currency: '',
  paymentRoute: undefined,
  isWithdrawConfirmShown: false,
};

import { novaToast } from '#/nova/components/other/toast/novaToast';

export const copyToClipboard = (textToCopy: string) => {
  function copyText(text: string) {
    try {
      const textarea = document.createElement('textarea');
      textarea.id = 'copy-this-address';
      textarea.innerText = text;
      document.body.appendChild(textarea);
      textarea.select();
      if (document.execCommand("copy")) {
        textarea.remove();
        return true;
      }
    } catch(error) {
      throw error;
    }

    return false;
  }

  if (copyText(textToCopy)) {
    novaToast.success(_t('Copied to clipboard', 'TOASTS.COPIED_TO_CLIPBOARD'));
  } else {
    novaToast.error(_t('Copy failed', 'TOASTS.COPY_FAILED'));
  }
};

export const createUrlParamsFromObject = (obj: { [key: string]: string | number | Array<string> | Date | undefined | null } = {}): string => Object.entries(obj).reduce((acc, [filter, value]) => {
  if (Array.isArray(value)) {
    acc += `&${filter}=`;

    value.forEach((i, index) => {
      acc += `${i}${value.length - 1 !== index ? ',': ''}`;
    })
  } else if (value) {
    acc += `&${filter}=${value}`;
  }

  return acc;
}, '');
import { VaultProduct } from '#/types';

export * from './formatTransactionType';
export * from './filters';
export * from './countAllBalances';
export * from './isLoading';
export * from './toUpperCaseObjectProperties';
export * from './calculateEarnings';
export * from './calculateTrade';
export * from './validateTrade';
export * from './prepareAccountActivity';
export * from './formatAmount';

// @ts-ignore
export const getAnnualRate = (product: VaultProduct) => product?.baseAnnualRate || 0;

import { Auth, Amplify } from 'aws-amplify';
import awsconfig from './config/aws/awsconfig.json';
import awsauth from './config/aws/awsauth.json';
import { removeAllCookies } from './util/cookies';

const removeKeys = [
  'cognito',
  'amplify',
  'bank_id_timer',
  'bank_id_session',
  'bank_id_tab_withdraw',
  'bank_id_product',
  'bank_id_method',
  'verification_type',
];

export const removeStorage = () => {
  try {
    removeAllCookies();
    sessionStorage.clear();
  } catch {}
  Object.keys(window.localStorage).forEach((key) => {
    if (key && removeKeys.some((_) => key.toLowerCase().indexOf(_) !== -1)) {
      console.log(key)
      window.localStorage.removeItem(key);
    }
  });
}

export default function initializeAmplify(domain, clientPoolId, poolId) {
  console.log('init amplify', poolId, clientPoolId, domain)
  const host = `${window.location.protocol}//${window.location.host}`;
  awsconfig.Auth.cookieStorage.domain = window.location.hostname;
  awsconfig.Auth.userPoolId = poolId;
  awsconfig.Auth.userPoolWebClientId = clientPoolId;
  awsauth.redirectSignIn = awsauth.redirectSignIn.replace('%host%', host);
  awsauth.redirectSignOut = awsauth.redirectSignOut.replace('%host%', host);
  awsconfig.Auth.redirectSignIn = awsauth.redirectSignIn;
  awsconfig.Auth.redirectSignOut = awsauth.redirectSignOut;
  awsauth.domain = domain;

  Amplify.configure({ ...awsconfig });
  Auth.configure({ awsconfig, storage: window.localStorage, oauth: { ...awsauth } });
}

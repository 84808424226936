import { batch } from 'react-redux';
import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import TradeService from '#/api/trade/TradeService';
import { debounce, handleErrorThunk } from '#/util'
import { GetConversionsPayload } from '#/api/trade/dto';
import { RequestStatus } from '#/types/enums';
import { setConversionsNextPageExists, setConversions, setConversionsRequest } from './conversions';
import { ConversionHistory } from '../interfaces';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';

export const getConversions = createAsyncThunk(
  'trade/getConversions',
  debounce(1000, async (payload: GetConversionsPayload, { dispatch, extra }: any) => {
    try {
      const { alias_get_conversions: conversions }: { alias_get_conversions: Array<ConversionHistory> } = await (extra.tradeService as TradeService).getConversions(preparePagerDifferencePlus1(payload));
      batch(() => {
        const pagerConversions = prepareResultPagerDifference(conversions, payload.pager?.limit);
        dispatch(setConversions(pagerConversions));
        dispatch(setConversionsNextPageExists(conversions.length > pagerConversions.length));
      });
    } catch (error) {
      handleErrorThunk(error, 'Get conversions history failed', dispatch);
      dispatch(setConversionsRequest(RequestStatus.Failed));
    }
  }),
) as unknown as (payload: GetConversionsPayload) => AnyAction;

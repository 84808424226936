import { FavoriteAddressCrypto, FavoriteFiatDestination } from '#reducers/trade/interfaces';
import { IAddressBookState } from './types';

export const defaultCryptoAddress: FavoriteAddressCrypto = {
  address: '',
  currency_id: '',
  address_tag_type: undefined,
  address_tag_value: undefined,
  name: '',
  network: undefined,
};

export const defaultFiatAddress: FavoriteFiatDestination = {
  beneficiary_account_number: '',
  beneficiary_name: '',
  name: '',
  notes: null,
}

export const initialAddressBookState: IAddressBookState = {
  currentAddressCrypto: defaultCryptoAddress,
  currentAddressFiat: defaultFiatAddress,
  walletAddressBookModalOpen: false,
  walletAddressAddModalOpen: false,
  editableFiatAddress: {} as FavoriteFiatDestination,
};

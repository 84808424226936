import { VaultDepositWithdrawPayload, VaultHistoryQuery } from '#/types';
import BigNumber from 'bignumber.js';
import * as gql from 'gql-query-builder';
import {
  vaultBalancesFields,
  vaultHistoryFields,
  vaultPendingTransactionsFields,
  vaultProductFields,
} from './fields';

export enum VaultOperations {
  GetProducts = 'productList',
  GetHistory = 'lendingHistory',
  GetPendingTransactions = 'pendingTransactions',
  GetBalances = 'lendingBalances',
  MakeWithdraw = 'redeem',
  MakeDeposit = 'allocate',
}

export default {
  getProducts: () => gql.query({
    operation: VaultOperations.GetProducts,
    fields: vaultProductFields,
    variables: {},
  }),

  getHistory: (params: VaultHistoryQuery) => gql.query({
    operation: VaultOperations.GetHistory,
    fields: vaultHistoryFields,
    variables: {
      companyId: { value: params.companyId || undefined, type: 'String', required: false },
      userId: { value: params.userId || undefined, type: '[String!]', required: false },
      productId: { value: params.productId || undefined, type: 'String', required: false },
      assetId: { value: params.assetId || undefined, type: 'String', required: false },
      transactionType: { value: params.transactionType || undefined, type: 'TransactionType', required: false },
      startingCreatedAt: { value: params.startingCreatedAt || undefined, type: 'String', required: false },
      endingCreatedAt: { value: params.endingCreatedAt || undefined, type: 'String', required: false },
      limit: { value: params.pager?.limit || undefined, type: 'Int', required: false },
      offset: { value: params.pager?.offset || undefined, type: 'Int', required: false },
      lendingId: { value: params.lendingId || undefined, type: 'String', required: false },
      accountType: { value: params.accountType || undefined, type: 'AccountType', required: false },
    },
  }),

  getPendingTransactions: () => gql.query({
    operation: VaultOperations.GetPendingTransactions,
    fields: vaultPendingTransactionsFields,
    variables: {},
  }),

  getBalances: () => gql.query({
    operation: VaultOperations.GetBalances,
    fields: vaultBalancesFields,
    variables: {},
  }),

  makeDeposit: (params: VaultDepositWithdrawPayload) => gql.mutation({
    operation: VaultOperations.MakeDeposit,
    fields: ['__typename'],
    variables: {
      exchange: { value: 'UAT', type: 'String', required: true },
      productId: { value: params.productId, type: 'String', required: true },
      assetId: { value: params.assetId, type: 'String', required: true },
      amount: { value: new BigNumber(params.amount), type: 'BN', required: true },
      // userId: { value: params.userId, type: 'String', required: true },
    },
  }),

  makeWithdraw: (params: VaultDepositWithdrawPayload) => gql.mutation({
    operation: VaultOperations.MakeWithdraw,
    // fields: vaultMakeWithdrawFields, // TODO
    fields: ['__typename'],
    variables: {
      exchange: { value: 'UAT', type: 'String', required: true },
      productId: { value: params.productId, type: 'String', required: true },
      assetId: { value: params.assetId, type: 'String', required: true },
      amount: { value: params.amount, type: 'BN', required: true },
      // userId: { value: params.userId, type: 'String', required: true },
    },
  }),
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import UserService from '#/api/user/UserService';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk } from '#/util';
import { updatePaymentLimits, updatePaymentLimitsRequestStatus, updateUserDisabledCurrencies, updateUserDisabledInstruments } from './paymentLimits';

export const getPaymentLimits = createAsyncThunk(
  'trade/getPaymentLimits',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    dispatch(updatePaymentLimitsRequestStatus(RequestStatus.Pending));
    try {
      const { payment_limits } = await (extra.userService as UserService).getUserPaymentLimits();
      dispatch(updatePaymentLimits(payment_limits));
    } catch (error) {
      dispatch(updatePaymentLimitsRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get payment limits failed', dispatch);
    }
  }, 1000)
) as unknown as () => AnyAction;

export const getLimitsDisabledCurrenciesInstruments = createAsyncThunk(
  'trade/getLimitsDisabledCurrenciesInstruments',
  throttle(async (_: undefined, { dispatch, extra }: any) => {
    try {
      const { disabled_currencies, disabled_instruments } = await (extra.userService as UserService).getUserDisabledCurrenciesInstruments();
      batch(() => {
        dispatch(updateUserDisabledCurrencies(disabled_currencies));
        dispatch(updateUserDisabledInstruments(disabled_instruments));
      });
    } catch (error) {
      setTimeout(() => handleErrorThunk(error, 'Get currencies & instruments limits failed', dispatch), 1000);
    }
  }, 1000)
) as unknown as () => AnyAction;

import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';

import { initialCurrencyState } from './initialState';
import { getCurrencies, getCurrenciesById } from '#/store/reducers/cryptoPay/thunks/currency';

const currencySlice = createSlice({
  name: 'currency',
  initialState: initialCurrencyState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.pending, (state) => {
        state.currencies.status = RequestStatus.Pending;
        state.currencies.error.code = null;
        state.currencies.error.message = null;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencies.status = RequestStatus.Success;
        state.currencies.items = action.payload.currencies;
      })
      .addCase(getCurrencies.rejected, (state, action: any) => {
        state.currencies.status = RequestStatus.Failed;
        state.currencies.error.code = action.payload.code;
        state.currencies.error.message = action.payload.message;
      });

    builder
      .addCase(getCurrenciesById.pending, (state) => {
        state.currenciesById.status = RequestStatus.Pending;
        state.currenciesById.error.code = null;
        state.currenciesById.error.message = null;
      })
      .addCase(getCurrenciesById.fulfilled, (state, action) => {
        state.currenciesById.status = RequestStatus.Success;
        state.currenciesById.items = action.payload.currencies;
      })
      .addCase(getCurrenciesById.rejected, (state, action: any) => {
        state.currenciesById.status = RequestStatus.Failed;
        state.currenciesById.error.code = action.payload.code;
        state.currenciesById.error.message = action.payload.message;
      });
  },
});

export default currencySlice.reducer;

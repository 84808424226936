//for example, this function receives 9/5/2004 and returns 09/05/2004
export default (date: string | Date) => {
  if (!date) {
    return ''
  }

  const dateString = String(date);
  if (dateString.length === 10) {
    return dateString
  }//Checking if the date is already formatted

  let [month, day, year] = String(date).split('/');//if the client uses a different type of date display, change the order of [month, day, year] to [day, month, year]

  let dateN = new Date(Number(year), Number(month) - 1, Number(day));
  return [
    (dateN.getMonth() + 1).toString().padStart(2, '0'),
    dateN.getDate().toString().padStart(2, '0'),
    dateN.getFullYear().toString()
  ].join('/');//and change this order too
}
import { initialCurrencyState } from './store/slice/currency/initialState';
import { initialFeeState } from './store/slice/fee/initialState';
import { initialPaymentState } from './store/slice/payment/initialState';
import { initialUserState } from './store/slice/user/initialState';
import { initialWebhookState } from './store/slice/webhooks/initialState';

import { ICurrencyState } from './store/slice/currency/interface';
import { IFeeState } from './store/slice/fee/interface';
import { IPaymentState } from './store/slice/payment/interface';
import { IUserState } from './store/slice/user/interface';
import { IWebhookState } from './store/slice/webhooks/interface';

export interface ICryptoPayState {
  currency: ICurrencyState;
  fee: IFeeState;
  payment: IPaymentState;
  user: IUserState;
  webhook: IWebhookState;
}

export const initialCryptoPayState: ICryptoPayState = {
  currency: initialCurrencyState,
  fee: initialFeeState,
  payment: initialPaymentState,
  user: initialUserState,
  webhook: initialWebhookState,
};

import { isNumber } from './isNumber';

export enum Rounds {
  Round = 'round',
  Down = 'down',
  Ceil = 'ceil',
  Up = 'up',
  Floor = 'floor',
}

export const round = (value: number, decimalPlaces = 0, round = Rounds.Round, returnNaN?: boolean) => {
  if (!isNumber(value)) {
    if (returnNaN) return NaN;
    throw new TypeError('value parameter is not a number');
  }
  const multi = 10 ** decimalPlaces;
  let roundedValue;
  if (round === Rounds.Up || round === Rounds.Ceil) {
    roundedValue = (Math.ceil(value * multi) / multi);
  } else if (round === Rounds.Down || round === Rounds.Floor) {
    roundedValue = (Math.floor(value * multi) / multi);
  } else {
    roundedValue = (Math.round(value * multi) / multi);
  }
  return roundedValue;
};

import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { initialSettingsState } from './helpers';
import { SettingsState } from './types';
import { NotificationsOptions, RequestStatus, UrlSearchParamsEnum } from '#/types/enums';
import config from '#/config/config-env';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    updateMaintenanceMode(state, action: PayloadAction<boolean>) {
      state.maintenanceMode = action.payload;
    },
    updateMaintenanceMessage(state, action: PayloadAction<string>) {
      state.maintenanceMessage = action.payload;
    },
    updateIsUsing2fa(state, action: PayloadAction<boolean>) {
      state.is_using_2fa = action.payload;
    },
    updateSecretCode(state, action: PayloadAction<string>) {
      state.secretCode = action.payload;
    },
    updateSecretCodeUri(state, action: PayloadAction<string>) {
      state.secretCodeUri = action.payload;
    },
    updateMfaStatus(state, action: PayloadAction<RequestStatus>) {
      state.updateStatusRequest = action.payload;
    },
    getMfaStatusRequest(state, action: PayloadAction<RequestStatus>) {
      state.mfaStatusRequest = action.payload;
    },
    updateIsDevelopment(state, action: PayloadAction<boolean>) {
      state.isDevelopment = action.payload
    },
    setEnabledNotifications(state, action: PayloadAction<NotificationsOptions | {}>) {
      state.enabledNotifications = action.payload;
    },
    updateRestrictionsStatus(state, action: PayloadAction<boolean>) {
      state.geo_restrictions = action.payload
    },
    updateIpBlacklistingMode(state, action: PayloadAction<boolean>) {
      state.isIpBlacklisting = action.payload;
    },
    updateGeoBlockMode(state, action: PayloadAction<boolean>) {
      state.isGeoBlock= action.payload;
    },
    updateRestrictionCheckStatus(state, action: PayloadAction<RequestStatus>) {
      state.restrictionCheckStatusRequest = action.payload;
    },

  },
});

export const {
  updateMaintenanceMode,
  updateMaintenanceMessage,
  updateIsUsing2fa,
  updateMfaStatus,
  getMfaStatusRequest,
  updateSecretCode,
  updateSecretCodeUri,
  updateIsDevelopment,
  setEnabledNotifications,
  updateRestrictionsStatus,
  updateIpBlacklistingMode,
  updateGeoBlockMode,
  updateRestrictionCheckStatus
} = settingsSlice.actions;

export default settingsSlice.reducer;

const selectSettingsState = (state: Store): SettingsState => state.settings;

export const selectMaintenanceMode: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.maintenanceMode,
);

export const selectMaintenanceMessage: (state: Store) => string = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.maintenanceMessage,
);

export const selectIsUsing2fa: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.is_using_2fa,
);

export const selectUpdate2FaStatusRequest: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.updateStatusRequest,
);

export const selectMfaStatusRequest: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.mfaStatusRequest,
);

export const selectSecretCode: (state: Store) => string = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.secretCode,
);

export const selectSecretCodeUri: (state: Store) => string = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.secretCodeUri,
);

export const selectIsDevelopment: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.isDevelopment,
);
export const selectNotificationsDisable: (state: Store) => NotificationsOptions | {} = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.enabledNotifications,
);

export const selectGeoRestrictions: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.geo_restrictions,
);

export const selectIsIpBlacklisting: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.isIpBlacklisting,
);

export const selectIsGeoBlock: (state: Store) => boolean = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.isGeoBlock,
);

export const selectRestrictionCheckStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectSettingsState,
  (settingsState: SettingsState) => settingsState.restrictionCheckStatusRequest,
);


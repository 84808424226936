import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types';
import { initialPaymentFeesState } from './helpers';
import { IUserPaymentFees } from '#/api/user/dto';
import { IPaymentFeesState } from './types';

const paymentFeesSlice = createSlice({
  name: 'paymentFees',
  initialState: initialPaymentFeesState,
  reducers: {
    updatePaymentFeesRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.paymentFeesRequestStatus = action.payload;
    },
    updatePaymentFees(state, action: PayloadAction<Array<IUserPaymentFees>>) {
      state.paymentFees = action.payload;
      state.paymentFeesRequestStatus = RequestStatus.Success;
    },
  },
});

export const {
  updatePaymentFeesRequestStatus,
  updatePaymentFees,
} = paymentFeesSlice.actions;

export default paymentFeesSlice.reducer;

export const selectPaymentFeesState = (state: Store): IPaymentFeesState => state.trade.paymentFees;

export const selectPaymentFees: (state: Store) => Array<IUserPaymentFees> = createDraftSafeSelector(
  selectPaymentFeesState,
  (paymentFeesState: IPaymentFeesState) => paymentFeesState.paymentFees,
);

export const selectPaymentFeesRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPaymentFeesState,
  (paymentFeesState: IPaymentFeesState) => paymentFeesState.paymentFeesRequestStatus,
);

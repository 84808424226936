import configEnv from '#/config/config-env';
import { AuthStorageItems } from '#/store/reducers/user/user/auth-helper';

export const sendRequest = (endpoint: string, method: string, body?: any) => {
  const token = localStorage.getItem(AuthStorageItems.Token)?.split(' ')[1];

  return fetch(`${configEnv.cryptoPayUrl}/${endpoint}`, {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    body: body ? JSON.stringify(body) : null,
  });
};

export const sendFileRequest = (endpoint: string, method: string, body?: any) => {
  const token = localStorage.getItem(AuthStorageItems.Token)?.split(' ')[1];

  return fetch(`${configEnv.cryptoPayUrl}/${endpoint}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
    },

    body: body,
  });
};

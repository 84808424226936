import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';

import { RequestStatus } from '#/types/enums';
import { AccountActivityItem, AccountActivityState } from './types';
import { initialAccountActivityState } from './helper';

const accountActivitySlice = createSlice({
  name: 'accountActivity',
  initialState: initialAccountActivityState,
  reducers: {
    updateAccountActivity(state, action: PayloadAction<Array<AccountActivityItem>>) {
      state.accountActivity = action.payload;
      state.accountActivityRequestStatus = RequestStatus.Success;
    },
    updateAccountActivityRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.accountActivityRequestStatus = action.payload;
    },
    updateAccountActivityNextPageExists(state, action: PayloadAction<boolean>) {
      state.accaountActivityNextPageExists = action.payload;
    }
  },
});

export const {
  updateAccountActivity,
  updateAccountActivityRequestStatus,
  updateAccountActivityNextPageExists,
} = accountActivitySlice.actions;

export default accountActivitySlice.reducer;

const selectAccountActivityState = (state: Store): AccountActivityState => state.home.accountActivity;

export const selectAccountActivity: (state: Store) => Array<AccountActivityItem> = createDraftSafeSelector(
  selectAccountActivityState,
  (accountActivityState: AccountActivityState) => accountActivityState.accountActivity || [],
);

export const selectAccountActivityStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectAccountActivityState,
  (accountActivityState: AccountActivityState) => accountActivityState.accountActivityRequestStatus,
);

export const selectAccountActivityNextPageExists: (store: Store) => boolean = createDraftSafeSelector(
  selectAccountActivityState,
  (accountActivityState: AccountActivityState) => accountActivityState.accaountActivityNextPageExists,
);


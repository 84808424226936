import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux';
import { ISelectedInstrument } from '#/types/interfaces';
import { useIsFavorite } from './useIsFavorite';
import { Instruments, getInstruments, selectSelectedInstrument, selectInstruments, selectInstrumentsStatus, updateSelectedInstrument as updateSelectedInstrumentAction, selectInstrumentsArray, getInstrumentBaseQuotePriceBars } from '#reducers/trade/instruments';
import { showModalTrade as showModalTradeAction } from '#reducers/trade/order';
import { replaceOrderbook } from '#reducers/trade/orderbook';
import { RequestStatus } from '#/types/enums';

interface UseInstruments {
  isLoading: boolean,
  selectedInstrument: ISelectedInstrument,
  instruments: Instruments,
  instrumentsStatus: RequestStatus,
  arrayInstruments: Array<ISelectedInstrument>,
  updateSelectedInstrument: (instrument: ISelectedInstrument) => void,
  updateSelectedInstrumentByCurrency: (currency: string, showModalTrade: boolean) => void,
}
const useInstruments = (): UseInstruments => {
  const dispatch = useDispatch();
  const selectedInstrument = useSelector(selectSelectedInstrument);
  const instruments = useSelector(selectInstruments);
  const instrumentsStatus = useSelector(selectInstrumentsStatus);
  const arrayInstruments = useSelector(selectInstrumentsArray);

  const updateSelectedInstrument = (instrument: ISelectedInstrument, showModalTrade: boolean = false) => {
    batch(() => {
      dispatch(updateSelectedInstrumentAction(instrument));
      dispatch(replaceOrderbook({ buy: [], sell: [] }));
      if (showModalTrade) {
        dispatch(showModalTradeAction(true));
      }
    })
  }

  const updateSelectedInstrumentByCurrency = (currency: string, showModalTrade: boolean = false) => {
    const firstFoundInstrument = arrayInstruments.find((_) => _.base_currency_id === currency || _.quote_currency_id === currency);
    if (firstFoundInstrument) {
      updateSelectedInstrument(firstFoundInstrument, showModalTrade);
    }
  }

  useEffect(() => {
    if (!arrayInstruments.find((_) => _.instrument_id === selectedInstrument.instrument_id) && arrayInstruments.length) {
      updateSelectedInstrument(arrayInstruments[0]);
    }
  }, [arrayInstruments.length]);

  useEffect(() => {
    dispatch(getInstrumentBaseQuotePriceBars(selectedInstrument));
  }, [JSON.stringify(selectedInstrument)]);

  return {
    isLoading: [RequestStatus.None, RequestStatus.Pending].includes(instrumentsStatus),
    selectedInstrument: selectedInstrument,
    instruments,
    instrumentsStatus,
    arrayInstruments,
    updateSelectedInstrument: useCallback(updateSelectedInstrument, []),
    updateSelectedInstrumentByCurrency: useCallback(updateSelectedInstrumentByCurrency, [arrayInstruments.length]),
  }
}

export default useInstruments;

interface UseSortedInstrumentsResult extends UseInstruments {
  sortedInstruments: Array<ISelectedInstrument>,
  sortedFavorites: Array<ISelectedInstrument>,
}
export const useSortedInstruments = (withSelected: boolean = true): UseSortedInstrumentsResult => {
  const instrumentsData = useInstruments();
  const { arrayInstruments, selectedInstrument } = instrumentsData;
  const selectedInstrumentWithBars = !selectedInstrument.price_bars 
    ? {...selectedInstrument, price_bars: instrumentsData.instruments[selectedInstrument.instrument_id].price_bars} 
    : selectedInstrument;
  
  const { favorites: _favorites } = useIsFavorite({});

  const withoutSelected = arrayInstruments.filter((_) => selectedInstrumentWithBars.instrument_id !== _.instrument_id)
  const favorites = withoutSelected.filter((_) => _favorites.includes(_.instrument_id)).sort((a, b) => a.instrument_id.localeCompare(b.instrument_id));
  const other = withoutSelected.filter((_) => !_favorites.includes(_.instrument_id)).sort((a, b) => a.instrument_id.localeCompare(b.instrument_id));

  // @ts-ignore
  const sortedInstruments = withSelected ? [selectedInstrumentWithBars].concat(favorites, other) : [].concat(favorites, selectedInstrumentWithBars, other);

  return {
    ...instrumentsData,
    sortedInstruments,
    sortedFavorites: sortedInstruments.filter((_) => _favorites.includes(_.instrument_id)),
  }
}

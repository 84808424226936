import config from '#/config/config';
import { format as dateFNSFormat, utcToZonedTime } from 'date-fns-tz';
import { DateType } from './types';
import { getTimeZoneSettings } from './getTimeZoneSettings';

/**
 * @param timestamp
 * @param showOnlyTime
 * @param showOnlyDate
 * @param dateFormat
 * @param timeFormat
 * @param firstDate
 * @returns {string|null}
 */

export default (
  timestamp: DateType | undefined,
  showOnlyTime = false,
  showOnlyDate = false,
  dateFormat = config.dateFormat || 'MM-dd-yy',
  timeFormat = config.timeFormat === '12hr' ? 'h:mm a' : 'HH:mm',
  firstDate = false,
) => {
  if (!['object'].includes(typeof timestamp)) {
    timestamp = String(timestamp);
  }
  if (timestamp === undefined) {
    return null;
  }
  const { isAutoTimezone, timeZone } = getTimeZoneSettings();

  if (typeof timestamp === 'string') {
    timestamp = timestamp.replace(/ /g,"T")
  }
  let parsedString;
  try {
    parsedString = !!Number(timestamp) ? Number(timestamp) : timestamp;
  } catch {
    parsedString = timestamp;
  }

  let format = firstDate ? `${dateFormat} ${timeFormat}` : `${timeFormat} ${dateFormat}`;

  if (showOnlyTime) {
    format = timeFormat;
  } else if (showOnlyDate) {
    format = dateFormat;
  }


  if (!isAutoTimezone && timeZone) {
    const zonedDate = utcToZonedTime(parsedString, timeZone);  //creating a date in the user's timezone
    return  dateFNSFormat(zonedDate, format, { timeZone }); // parse date to string
  }

  return dateFNSFormat(new Date(parsedString), format);
};

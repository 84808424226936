
import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import TradeService from '#/api/trade/TradeService';
import { RequestStatus } from '#/types/enums';
import { IBalance, IBalanceExtended } from '../interfaces';
import { debounce, arrayToObject, handleErrorThunk } from '#/util';
import { balancesRequestStatus, updateBalances } from './balances';
import { prepareBalances } from './helpers';
import { UserMarketCurrencies } from '#reducers/user/user';

export const getBalances = createAsyncThunk(
  'trade/getBalances',
  debounce(1500, async ({ marketCurrency, isShowLoader }: { marketCurrency: UserMarketCurrencies, isShowLoader: boolean }, { dispatch, extra }: any) => {
    if (isShowLoader) {
      dispatch(balancesRequestStatus(RequestStatus.Pending));
    }
    try {
      const { alias_get_accounts_balances }: { alias_get_accounts_balances: Array<IBalance> } = await (extra.tradeService as TradeService).getBalances();
      const extendedBalances: Array<IBalanceExtended> = prepareBalances(alias_get_accounts_balances, marketCurrency);
      dispatch(updateBalances(arrayToObject(extendedBalances, 'currency_id')));
    } catch (error) {
      dispatch(balancesRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get balances failed', dispatch);
    }
  })
) as unknown as ({ marketCurrency, isShowLoader }: { marketCurrency: UserMarketCurrencies, isShowLoader: boolean }) => AnyAction;

import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '#/types/enums';
import { initialPaymentState } from './initialState';

import { getPayments, getPaymentAddresses, createPayment } from '#/store/reducers/cryptoPay/thunks/payment';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialPaymentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayments.pending, (state) => {
        state.payments.status = RequestStatus.Pending;
        state.createPayment.error.code = null;
        state.createPayment.error.message = null;
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.payments.status = RequestStatus.Success;
        state.payments.items = action.payload.results;
        state.payments.totalCount = action.payload.total_count;
        state.payments.page = action.payload.page;
        state.payments.size = action.payload.size;
      })
      .addCase(getPayments.rejected, (state, action: any) => {
        state.payments.status = RequestStatus.Failed;
        state.createPayment.error.code = action.payload.code;
        state.createPayment.error.message = action.payload.message;
      });

    builder
      .addCase(getPaymentAddresses.pending, (state) => {
        state.paymentAddresses.status = RequestStatus.Pending;
        state.createPayment.error.code = null;
        state.createPayment.error.message = null;
      })
      .addCase(getPaymentAddresses.fulfilled, (state, action) => {
        state.paymentAddresses.status = RequestStatus.Success;
        state.paymentAddresses.items = action.payload.addresses;
      })
      .addCase(getPaymentAddresses.rejected, (state, action: any) => {
        state.paymentAddresses.status = RequestStatus.Failed;
        state.createPayment.error.code = action.payload.code;
        state.createPayment.error.message = action.payload.message;
      });

    builder
      .addCase(createPayment.pending, (state) => {
        state.createPayment.status = RequestStatus.Pending;
        state.createPayment.error.code = null;
        state.createPayment.error.message = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.createPayment.status = RequestStatus.Success;
        state.createPayment.item = action.payload.payment;
      })
      .addCase(createPayment.rejected, (state, action: any) => {
        state.createPayment.status = RequestStatus.Failed;
        state.createPayment.error.code = action.payload.code;
        state.createPayment.error.message = action.payload.message;
      });
  },
});

export default paymentSlice.reducer;

import { OrderbookState } from './types';

export const initialStateOrderbook: OrderbookState = {
  orderbook: {
    buy: [],
    sell: [],
  },
  orderPrices: {
    buy: '' ,
    sell: '',
  }
};

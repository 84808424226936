import { TradePager } from '#/types';

export const preparePagerDifferencePlus1 = (params: { pager?: TradePager, [option: string]: any }): { pager?: TradePager, [option: string]: any } => {
  let pager = { ...params.pager };
  if (pager) {
    pager = pager.limit ? { ...pager, limit: pager.limit + 1 } : pager;
  }
  return {
    ...params,
    // @ts-ignore
    pager: pager,
  }
}

export const prepareResultPagerDifference = <T>(values: Array<T>, pagerLimit?: number): Array<T> => {
  const copyOfValues = [...values];
  if (pagerLimit) {
    return copyOfValues.splice(0, pagerLimit)
  }
  return values;
}

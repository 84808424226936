import { createAsyncThunk } from '@reduxjs/toolkit';

import { getChildUsers } from '#/store/reducers/cryptoPay/thunks/user';
import CryptoPayApi from '#/api/cryptoPay';
import Config from '#/config/config';

import { isStatusInclude } from './util';

import { novaToast } from '#/nova/components/other/toast/novaToast';

const {
  cryptoPayConfig: { failRequestMessage, successRequestMessage },
} = Config;

export const getPaymentFeesForAllPayments = createAsyncThunk(
  'fee/getPaymentFeesForAllPayments',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getPaymentFeesForAllPayments();

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Payment Fees For All Payments - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const getPaymentFeesForPayment = createAsyncThunk(
  'fee/getPaymentFeesForPayment',
  async (paymentId: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getPaymentFeesForPayment(paymentId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Payment Fees For Payment - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const getFeeGroupsForUser = createAsyncThunk(
  'fee/getFeeGroupsForUser',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getFeeGroupsForUser();

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Fee Groups For User - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const getFeeGroupByName = createAsyncThunk(
  'fee/getFeeGroupByName',
  async (groupName: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getFeeGroupByName(groupName);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Fee Group By Name - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const createFeeGroup = createAsyncThunk(
  'fee/createFeeGroup',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).createFeeGroup(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Create Fee Group - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getFeeGroupsForUser());

      novaToast.success(`Create Fee Group - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateFeeGroup = createAsyncThunk(
  'fee/updateFeeGroup',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateFeeGroup(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Fee Group - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getFeeGroupsForUser());

      novaToast.success(`Updated Fee Group - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const deleteFeeGroupByName = createAsyncThunk(
  'fee/deleteFeeGroupByName',
  async (groupId: string, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).deleteFeeGroupByName(groupId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Delete Fee Group - Fail: ${isCurrentCode ? json.message : failRequestMessage}`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getFeeGroupsForUser());

      novaToast.success(`Delete Fee Group - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const addSubUserToFeeGroup = createAsyncThunk(
  'fee/addSubUserToFeeGroup',
  async (data: any, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).addSubUserToFeeGroup(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Add SubUser To FeeGroup - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      novaToast.success(`Add SubUser To FeeGroup - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const updateSubUserFeeGroup = createAsyncThunk(
  'fee/updateSubUserFeeGroup',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateSubUserFeeGroup(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated SubUser Fee Group - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getChildUsers());

      novaToast.success(`Updated SubUser Fee Group - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);

export const deleteSubUserFromFeeGroup = createAsyncThunk(
  'fee/deleteSubUserFromFeeGroup',
  async (data: any, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).deleteSubUserFromFeeGroup(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Delete SubUser From Fee Group - Fail: ${
            isCurrentCode ? json.message : failRequestMessage
          }`
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      novaToast.success(`Delete SubUser From Fee Group - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  }
);
